import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  useMediaQuery
} from "@chakra-ui/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import errorIcon from "../../../assets/images/Ellipse3.svg";
import { ThemeProvider } from "@mui/material/styles";
import { GridToolbar } from "@mui/x-data-grid";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import "../../../assets/redactor/email/email";
import Redactor from "../../../assets/redactor/redactor";
import "../../../assets/redactor/redactor.min.css";
import {
  AppContext,
  PosAlert,
  PosBreadCrumb,
  PosDataGridTable,
  PosDrawer,
  PosLable,
  PosNoDataFound,
  PosOutlineButton,
  PosSearch,
  PosTab,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { commonDateFormate } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { Mtheme } from "../../../theme/index";
import {
  getCampaignTimeUsedApi,
  getCampaignUserDataApi,
  getCampaignViewDataApi,
} from "./CampaignService";

const CampaignView = (props) => {
  const myContext = useContext(AppContext);
  const { error } = PosErrorHook();

  const [isMobile] = useMediaQuery(
    Constants.MEDIA_QUERY_DATA_GRID_TABLE_MAX_WIDTH
  );
  const tabNames = [
    "Campaign Details",
    "Campaign Email Details",
    "Campaign User Details",
  ];
  const [inStore, setInStore] = useState(true);

  // campaign Constt
  const stateData = useLocation();
  const { addToast } = PosTostMessage();
  const [campaign, setCampaign] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [marketingType, setMarketingType] = useState("");
  const [campaignType, setCampaignType] = useState("");
  const [scheduledStartTime, setscheduledStartTime] = useState("");
  const [selectedAudience, setSelectedAudienceName] = useState("");
  const [couponDetails, setCouponDetails] = useState([]);
  const [selectedCouponProdViewDetails, setSelectedCouponProdViewDetails] =
    useState([]);
  const [emailTemplate, setEmailTemplate] = useState("");
  const [emailDesign, setEmailDesign] = useState(false);
  const [userDetails, setUserDetails] = useState(false);
  const [onlySmsSelected, setOnlySmsSelected] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [loading, setLoading] = useState(true);
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const [campaignId, setCampaignId] = useState(0);
  const sortRef = useRef(sortModel);
  const [totalcount, setTotalCount] = useState(0);
  const [showTable, setShowTable] = useState(true);
  const [campaignUserData, setCampaignUserData] = useState([]);
  const [campaignUserTimeUsed, setCampaignUserTimeUsed] = useState([]);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const searchInputHideRef = useRef(false);
  const effectRun = useRef(true);
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.8 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const [smsDetails, setSmsDetails] = useState("");
  const [viewFlag, setViewFlag] = useState(false);

  const initialVisibilityModel =
    campaignType === "Generic"
      ? // setActiveListColumnNames(
        [
          {
            field: "id",
            headerName: "Id",
            sortable: true,
            ...actionColumnXS,
          },
          {
            field: "first_name",
            headerName: "First Name",
            sortable: true,
            ...actionColumnMD,
          },
          {
            field: "last_name",
            headerName: "Last Name",
            sortable: true,
            ...actionColumnMD,
          },
          {
            field: "email",
            headerName: "Email",
            sortable: true,
            ...actionColumnMD,
          },
          {
            field: "phone",
            headerName: "phone",
            sortable: false,
            ...actionColumnMD,
          },
        ]
      : // )
        // setActiveListColumnNames
        [
          {
            field: "id",
            headerName: "Id",
            sortable: true,
            width: 120,
          },
          {
            field: "first_name",
            headerName: "First Name",
            sortable: true,
            ...actionColumnMD,
          },
          {
            field: "last_name",
            headerName: "Last Name",
            sortable: true,
            ...actionColumnMD,
          },
          {
            field: "email",
            headerName: "Email",
            sortable: true,
            ...actionColumnMD,
          },
          {
            field: "phone",
            headerName: "phone",
            sortable: false,
            ...actionColumnMD,
          },
          {
            field: "time_used_details",
            headerName: "Time Used Details",
            sortable: false,
            ...actionColumnLG,
            renderCell: (params) => {
              return (
                <>
                  {campaign.save_as_draft == 0 ? (
                    <IconButton
                      sx={{ color: Colors.posNavbarLink }}
                      onClick={() => viewTimeUsedDetails(params.row)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  ) : (
                    "-"
                  )}
                </>
              );
            },
          },
          {
            field: "campaign_public_link",
            headerName: "Campaign Public Link",
            sortable: false,
            disableExport: true,
            resizable: false,
            filterable: false,
            ...actionColumnFlexDouble,
            renderCell: (params) => {
              return (
                <>
                  {campaign.save_as_draft == 0 ? (
                    <Text
                      color={Colors.posNavbarLink}
                      fontSize={"0.94rem"}
                      mr={2}
                      mt="2rem"
                      as="u"
                    >
                      <a
                        target="_blank"
                        href={params.row.campaign_public_link}
                        style={{
                          cursor: "pointer",
                          flexShrink: "0",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "normal",
                          letterSpacing: "-0.18px",
                          color: Colors.posNavbarLink,
                        }}
                      >
                        {params.row.campaign_public_link}
                      </a>
                    </Text>
                  ) : (
                    "-"
                  )}
                </>
              );
            },
          },
          {
            field: "actions",
            headerName: "Copy",
            sortable: false,
            disableExport: true,
            resizable: false,
            filterable: false,
            width: 150,
            renderCell: (params) => {
              let rowData = params.row;
              const handleCopyClick = () => {
                const textArea = document.createElement("textarea");
                textArea.value = rowData.campaign_public_link;
                textArea.style.position = "fixed";
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                  document.execCommand("copy");
                  addToast({
                    alertStatus: Constants.TOAST_TYPE_SUCESS,
                    alertTitle: Constants.CAMPAIGN_MODULE,
                    alertDescription:
                      "Campaign public link copied successfully.",
                  });
                } catch (err) {
                  addToast({
                    alertStatus: Constants.TOAST_TYPE_WARNING,
                    alertTitle: Constants.CAMPAIGN_MODULE,
                    alertDescription: "Failed to copy link to clipboard.",
                  });
                }
              };
              return (
                <>
                  {campaign.save_as_draft == 0 ? (
                    <PosOutlineButton
                      onClick={handleCopyClick}
                      name={<ContentCopyIcon />}
                    />
                  ) : (
                    "-"
                  )}
                </>
              );
            },
          },
        ];

  const [activeColumnVisibilityModel, setActiveColumnVisibilityModel] =
    React.useState({ initialVisibilityModel });
  const {
    isOpen: isCoupProdViewModelOpen,
    onOpen: onCoupProdViewModelOpen,
    onClose: onCoupProdViewModelClose,
  } = useDisclosure();

  const {
    isOpen: isTimeUsedViewModelOpen,
    onOpen: onTimeUsedViewModelOpen,
    onClose: onTimeUsedViewModelClose,
  } = useDisclosure();

  useEffect(() => {
    if (props.isViewOpen) {
      let ProdId = Number(props.id);
      setCampaignId(ProdId);
      // Call fetchData only when isOpen becomes true and effectRun is already true
      fetchData(ProdId);
      setInStore(true);
    }
    return () => {};
  }, [props.isViewOpen]);

  const EmailTemplate = ({ templateHtml }) => {
    const viewEmailRef = useRef(null);

    useEffect(() => {
      if (viewEmailRef.current) {
        viewEmailRef.current.innerHTML = templateHtml;
        let designEmailInstance = Redactor("#designEmail", {
          theme: "light",
          plugins: ["email"],
        });
        if (undefined != designEmailInstance) {
          var selectedTemplate = templateHtml;
          designEmailInstance.editor.setContent({
            html: selectedTemplate,
          });
          designEmailInstance.readonly();
        }
      }
    }, [templateHtml]);

    return (
      <Box flex={1} flexShrink={"0"} borderRadius={"0.31rem"}>
        <Flex
          justifyContent={{ base: "center", md: "center" }}
          alignItems={{ base: "flex-start" }}
          flexDirection={{ base: "column", md: "row" }}
        >
          {/* <div ref={viewEmailRef}></div> */}
          <div ref={viewEmailRef} id="designEmail"></div>
        </Flex>
      </Box>
    );
  };

  const fetchData = async (id) => {
    try {
      getCampaignViewDataApi(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            myContext.handleLoading(false);
            setCampaign(response?.data?.data?.campaign[0]);

            var campaign_type =
              null == response?.data?.data?.campaign[0].targeted ||
              "" == response?.data?.data?.campaign[0].targeted
                ? response?.data?.data?.campaign[0].coupons.length > 0
                  ? "Coupon"
                  : "Generic"
                : response?.data?.data?.campaign[0].targeted == 1
                ? "Coupon"
                : "Generic";
            setCampaignType(campaign_type);
            if (campaign_type == "Coupon") {
              setStartDate(
                moment(
                  new Date(response?.data?.data?.campaign[0].start_date + "Z")
                ).format("MM/DD/YYYY HH:mm:ss")
              );
              setEndDate(
                moment(
                  new Date(response?.data?.data?.campaign[0].end_date + "Z")
                ).format("MM/DD/YYYY HH:mm:ss")
              );
            }
            if (
              response?.data?.data?.campaign[0].is_send_email == 1 &&
              response?.data?.data?.campaign[0].is_send_sms == 1
            ) {
              setMarketingType("Email & SMS");
            } else if (
              response?.data?.data?.campaign[0].is_send_email == 1 &&
              response?.data?.data?.campaign[0].is_send_sms == 0
            ) {
              setMarketingType("Email");
            } else if (
              response?.data?.data?.campaign[0].is_send_email == 0 &&
              response?.data?.data?.campaign[0].is_send_sms == 1
            ) {
              setOnlySmsSelected(1);
              setMarketingType("SMS");
            } else {
              setMarketingType("Email");
            }

            if (response?.data?.data?.campaign[0].is_scheduled_now == 0) {
              setscheduledStartTime(
                moment(
                  new Date(
                    response?.data?.data?.campaign[0].scheduled_start_time + "Z"
                  )
                )
              );
            }
            if (response?.data?.data?.campaign[0].is_select_all == 0) {
              setSelectedAudienceName("Select Segment");
            } else {
              setSelectedAudienceName("Select All User");
            }
            if (response?.data?.data?.campaign[0].coupons.length > 0) {
              setCouponDetails(response?.data?.data?.campaign[0].coupons);
            }
            setEmailTemplate(
              response?.data?.data?.campaign[0]?.template_re_html
            );
            if (
              undefined != response?.data?.data?.campaign[0]?.sms_dtls &&
              null != response?.data?.data?.campaign[0]?.sms_dtls &&
              "" != response?.data?.data?.campaign[0]?.sms_dtls
            ) {
              setSmsDetails(response?.data?.data?.campaign[0]?.sms_dtls);
            }
            myContext.handleLoading(false);
            setViewFlag(true);
          } else {
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
            setViewFlag(false);
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
          setViewFlag(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
      setViewFlag(false);
    }
  };
  const handleTabChange = (index) => {
    if (index == 0) {
      setInStore(true);
      setEmailDesign(false);
      setUserDetails(false);
    } else if (index == 1) {
      setInStore(false);
      setEmailDesign(true);
      setUserDetails(false);
    } else {
      setInStore(false);
      setEmailDesign(false);
      setUserDetails(true);
      setSearchQuery("");
      // fetchUserData();
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      setSortModel([
        {
          field: "id",
          sort: "desc",
        },
      ]);
      effectRun.current = true;
      searchInputHideRef.current = false;
    }
  };

  const handleClose = () => {
    setCampaign([]);
    setStartDate("");
    setEndDate("");
    setMarketingType("");
    setCampaignType("");
    setscheduledStartTime("");
    setSelectedAudienceName("");
    setCouponDetails([]);
    setSelectedCouponProdViewDetails([]);
    setEmailTemplate("");
    setEmailDesign(false);
    setUserDetails(false);
    setOnlySmsSelected(0);
    setViewFlag(false);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setSmsDetails("");
    if (props.onViewClose) {
      props.onViewClose();
    }
  };

  const viewProductDetails = (productsDetails) => {
    var uniqueProducts = productsDetails.reduce((uniqueProdArray, element) => {
      // if (!uniqueProdArray.find(v => v['barcode'] === element['barcode'])) {
      const productPayload = {
        id: element.id,
        name: element.name,
        barcode: element.barcode,
      };
      uniqueProdArray.push(productPayload);
      // }
      return uniqueProdArray;
    }, []);
    setSelectedCouponProdViewDetails(uniqueProducts);
    onCoupProdViewModelOpen();
  };
  const viewTimeUsedDetails = (userData) => {
    myContext.handleLoading(true);
    let data = {
      reward_card_id: userData.id,
      campaign_id: campaignId,
    };
    try {
      getCampaignTimeUsedApi(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            var timeUsed = {
              first_name:
                undefined != userData["first_name"] &&
                "" != userData["first_name"] &&
                "N.A." != userData["first_name"] &&
                null != userData["first_name"]
                  ? userData["first_name"]
                  : "",
              last_name:
                undefined != userData["last_name"] &&
                "" != userData["last_name"] &&
                "N.A." != userData["last_name"] &&
                null != userData["last_name"]
                  ? userData["last_name"]
                  : "",
              coupon_usage_details: response?.data?.data,
            };
            setCampaignUserTimeUsed(timeUsed);
            onTimeUsedViewModelOpen();
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setCampaignUserTimeUsed([]);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setCampaignUserTimeUsed([]);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCampaignUserTimeUsed([]);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (userDetails === true && effectRun.current == true) {
      fetchUserData();
      effectRun.current = false;
    }
  }, [paginationModel, sortModel, loading, searchQuery]);

  const fetchUserData = async () => {
    myContext.handleLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      id: campaignId,
    };
    setShowTable(false);
    setLoading(true);
    try {
      getCampaignUserDataApi(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            var userDt = [];
            response?.data?.data?.data.map((dt) => {
              if (
                null != response?.data?.data?.data &&
                "" != response?.data?.data?.data
              ) {
                userDt.push(dt);
              }
            });
            setCampaignUserData(userDt);
            setTotalCount(response?.data?.data?.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setCampaignUserData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setCampaignUserData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setCampaignUserData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const modifiedCouponData = campaignUserData.map((data) => ({
    id: data?.id,
    first_name:
      undefined != data.first_name &&
      null != data.first_name &&
      "" != data.first_name
        ? data.first_name
        : "N.A.",
    last_name:
      undefined != data.last_name &&
      null != data.last_name &&
      "" != data.last_name
        ? data.last_name
        : "N.A.",
    email:
      undefined != data.email && null != data.email && "" != data.email
        ? data.email
        : "N.A.",
    phone:
      undefined != data.phone && null != data.phone && "" != data.phone
        ? data.phone
        : "N.A.",
    time_used_details: "N.A.",
    campaign_public_link:
      undefined != data.server_url &&
      null != data.server_url &&
      "" != data.server_url
        ? data.server_url
        : "N.A.",
  }));
  const handleSearchList = () => {
    paginationModel.page = 0;
    if (userDetails == true) {
      fetchUserData();
    }
  };
  const handleClear = () => {
    // clear input value and get all data
    setUserDetails(true);
    effectRun.current = true;
    setSearchQuery("");
  };

  return (
    <PosDrawer
      visible={viewFlag}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      <Box
        bg={Colors.loginAuthBackground}
        position="sticky"
        top="0"
        zIndex="sticky"
        pb={"0.62rem"}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            handleClose();
          }}
          breadCrumNames={["Campaigns", "View Campaign"]}
          breadCrumTitle={"View Campaign"}
        />
      </Box>
      {campaign.save_as_draft == 1 && (
        <Box mt={2}>
          {/* <PosAlert
            alertStatus={"error"}
            alertTitle={""}
            width={"100%"}
            alertDescription={<Text>This campaign is currently a draft.</Text>}
          /> */}
          <PosAlert
            alertStatus={"error"}
            alertTitle={""}
            image={errorIcon}
            bg={Colors.errorsign}
            color={Colors.errorColor}
            width={"100%"}
            alertDescription={
              <Text>
                This campaign is currently saved as a draft.
              </Text>
            }
          />
        </Box>
      )}
      <Tabs
        onChange={(index) => handleTabChange(index)}
        mt={{ base: "2.94rem", md: "2.94rem" }}
      >
        <PosTab tabNames={tabNames} />
        <TabPanels>
          <TabPanel
            pl={{ base: "1rem", md: "2.94rem" }}
            pt={{ base: "1rem", md: "2rem" }}
            pr={{ base: "1rem", md: "4.86rem" }}
            pb={{ base: "1rem", md: "3.13rem" }}
          >
            <Box flex={1} flexShrink={"0"} borderRadius={"0.31rem"}>
              <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box
                  bg={Colors.posPageDataBackground}
                  mb={3.5}
                  boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                  w={{ base: "100%", md: "50%" }}
                >
                  <VStack
                    align={"stretch"}
                    pl={{ base: "1.69rem", md: "1.69rem" }}
                    pt={{ base: "2.31rem", md: "2.31rem" }}
                    pr={{ base: "1.69rem", md: "1.69rem" }}
                    pb={{ base: "2.31rem", md: "2.31rem" }}
                    gap={"0rem"}
                  >
                    <Box>
                      <Heading
                        as={"h3"}
                        color={Colors.moduleHeading}
                        fontSize={"1.13rem"}
                        fontStyle={"normal"}
                        fontWeight={"500"}
                        lineHeight={"normal"}
                        letterSpacing={"-0.02rem"}
                      >
                        Basic Details
                      </Heading>
                    </Box>
                    <Box>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        color={Colors.taxInfo}
                        name={"Id"}
                      />
                      <Text>{campaign.id}</Text>
                    </Box>
                    <Box>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        color={Colors.taxInfo}
                        name={"Name"}
                      />
                      <Text>{campaign.title}</Text>
                    </Box>
                    <Box>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        color={Colors.taxInfo}
                        name={"Start Date"}
                      />
                      <Text>
                        {null == startDate || "" == startDate
                          ? "N.A."
                          : commonDateFormate(startDate)}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        color={Colors.taxInfo}
                        name={"End Date"}
                      />
                      <Text>
                        {null == endDate || "" == endDate
                          ? "N.A."
                          : commonDateFormate(endDate)}
                      </Text>
                    </Box>
                    <Box>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        color={Colors.taxInfo}
                        name={"Scheduled Type"}
                      />
                      <Text>
                        {campaign.is_scheduled_now == 1
                          ? "Scheduled Now"
                          : "Scheduled Later"}
                      </Text>
                    </Box>
                    {campaign.is_scheduled_now == 0 && (
                      <Box>
                        <PosLable
                          fontWeight={500}
                          label={true}
                          color={Colors.taxInfo}
                          name={"Scheduled Date & Time"}
                        />
                        <Text
                          display="inline-flex"
                          alignItems="center"
                          color={Colors.posTextInfo}
                          fontWeight="300"
                        >
                          {commonDateFormate(scheduledStartTime)}
                        </Text>
                      </Box>
                    )}
                    <Box>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        color={Colors.taxInfo}
                        name={"Marketing Type(s)"}
                      />
                      <Text>{marketingType}</Text>
                    </Box>
                    <Box>
                      <PosLable
                        fontWeight={500}
                        label={true}
                        color={Colors.taxInfo}
                        name={"Campaign Type"}
                      />
                      <Text>{campaignType}</Text>
                    </Box>
                  </VStack>
                </Box>
                <Box
                  w={{ base: "100%", md: "50%" }}
                  flex={1}
                  flexShrink={"0"}
                  pl={{ base: 0, md: "1rem" }}
                  borderRadius={"0.31rem"}
                >
                  <Box
                    bg={Colors.posPageDataBackground}
                    mb={3.5}
                    boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                  >
                    <VStack
                      align={"stretch"}
                      pl={{ base: "1.69rem", md: "1.69rem" }}
                      pt={{ base: "2.31rem", md: "2.31rem" }}
                      pr={{ base: "1.69rem", md: "1.69rem" }}
                      pb={{ base: "2.31rem", md: "2.31rem" }}
                      gap={"0rem"}
                    >
                      <Box>
                        <Heading
                          as={"h3"}
                          color={Colors.moduleHeading}
                          fontSize={"1.13rem"}
                          fontStyle={"normal"}
                          fontWeight={"500"}
                          lineHeight={"normal"}
                          letterSpacing={"-0.02rem"}
                        >
                          Audience Details
                        </Heading>
                      </Box>
                      <Box>
                        <PosLable
                          fontWeight={500}
                          label={true}
                          color={Colors.taxInfo}
                          name={"Audience Type"}
                        />
                        <Text display="inline-flex" alignItems="center">
                          {selectedAudience}
                        </Text>
                      </Box>
                      {selectedAudience == "Select Segment" && (
                        <Box>
                          <PosLable
                            fontWeight={500}
                            label={true}
                            color={Colors.taxInfo}
                            name={"Selected Segment(s)"}
                          />
                          <Text display="inline-flex" alignItems="center">
                            {campaign.segments.length > 0 ? (
                              campaign.segments.map((seg, index) => (
                                <React.Fragment key={seg.id}>
                                  {seg.name}
                                  {index < campaign.segments.length - 1
                                    ? ", "
                                    : ""}
                                </React.Fragment>
                              ))
                            ) : (
                              <Text display="inline-flex" alignItems="center">
                                You have not selected any segment(s) yet.
                              </Text>
                            )}
                          </Text>
                        </Box>
                      )}
                    </VStack>
                  </Box>
                  {smsDetails && (
                    <Box
                      bg={Colors.posPageDataBackground}
                      mb={3.5}
                      boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
                    >
                      <VStack
                        align={"stretch"}
                        pl={{ base: "1.69rem", md: "1.69rem" }}
                        pt={{ base: "2.31rem", md: "2.31rem" }}
                        pr={{ base: "1.69rem", md: "1.69rem" }}
                        pb={{ base: "2.31rem", md: "2.31rem" }}
                        gap={"0rem"}
                      >
                        <Box>
                          <Heading
                            as={"h3"}
                            color={Colors.moduleHeading}
                            fontSize={"1.13rem"}
                            fontStyle={"normal"}
                            fontWeight={"500"}
                            lineHeight={"normal"}
                            letterSpacing={"-0.02rem"}
                          >
                            SMS Text Message Details
                          </Heading>
                        </Box>
                        <Box mt={"2rem"} flex={1}>
                          <div className="mobile-screen">
                            {smsDetails && (
                              <div className="sms-preview">{smsDetails}</div>
                            )}
                          </div>
                        </Box>
                      </VStack>
                    </Box>
                  )}
                </Box>
              </Flex>
              <Box
                bg={Colors.posPageDataBackground}
                mb={3.5}
                boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
              >
                <VStack
                  align={"stretch"}
                  pl={{ base: "1.69rem", md: "1.69rem" }}
                  pt={{ base: "2.31rem", md: "2.31rem" }}
                  pr={{ base: "1.69rem", md: "1.69rem" }}
                  pb={{ base: "2.31rem", md: "2.31rem" }}
                  gap={"0rem"}
                >
                  <Box>
                    <Heading
                      as={"h3"}
                      fontWeight={500}
                      color={Colors.taxInfo}
                      fontSize={"1.13rem"}
                      fontStyle={"normal"}
                      lineHeight={"normal"}
                      letterSpacing={"-0.02rem"}
                    >
                      Coupon Details
                    </Heading>
                  </Box>
                  <Box
                    style={{
                      maxHeight: "18.75rem",
                      overflow: "auto",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <>
                      {couponDetails.length > 0 ? (
                        <DataTable
                          style={{
                            marginTop: "2rem",
                            width: "100%",
                            maxHeight: "30rem",
                          }}
                          value={couponDetails}
                        >
                          <Column
                            field="id"
                            header="Id"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "11%",
                              textTransform: "capitalize",
                            }}
                          />
                          <Column
                            field="title"
                            header="Name"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "11%",
                              textTransform: "capitalize",
                            }}
                          />
                          <Column
                            field="coupon_type"
                            header="Coupon Type"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "11%",
                              textTransform: "capitalize",
                            }}
                            body={(rowData) =>
                              rowData.coupon_type == null ||
                              "" == rowData.coupon_type ||
                              false == rowData.coupon_type
                                ? "N.A."
                                : rowData.coupon_type == "ord"
                                ? "Order"
                                : "Product"
                            }
                          />
                          <Column
                            field="products"
                            header="Product Details"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "11%",
                              textTransform: "capitalize",
                            }}
                            body={(rowData) =>
                              (rowData.coupon_type == "ord" ||
                                rowData.products.length <= 0) &&
                              rowData.apply_on_all_prods == 0 ? (
                                "N.A."
                              ) : rowData.apply_on_all_prods == 1 ? (
                                "Apply To All Products"
                              ) : (
                                <IconButton
                                  sx={{ color: Colors.posNavbarLink }}
                                  onClick={() =>
                                    viewProductDetails(rowData.products)
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              )
                            }
                          />
                          <Column
                            field="discount_type"
                            header="Discount Type"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "11%",
                              textTransform: "capitalize",
                            }}
                            body={(rowData) =>
                              rowData.discount_type == null ||
                              "" == rowData.discount_type ||
                              false == rowData.discount_type
                                ? "N.A."
                                : rowData.discount_type == "amou"
                                ? "Amount"
                                : "Percentage"
                            }
                          />
                          <Column
                            field="discount_value"
                            header="Discount Value"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "11%",
                              textTransform: "capitalize",
                            }}
                            body={(rowData) =>
                              rowData.discount_value == null ||
                              "" == rowData.discount_value ||
                              false == rowData.discount_value
                                ? "N.A."
                                : rowData.discount_value
                            }
                          />
                          <Column
                            field="min_amount"
                            header="Minimum Amount"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "11%",
                              textTransform: "capitalize",
                            }}
                            body={(rowData) =>
                              rowData.min_amount == null ||
                              "" == rowData.min_amount ||
                              false == rowData.min_amount
                                ? "N.A."
                                : rowData.min_amount
                            }
                          />
                          <Column
                            field="max_qty_per_coupon"
                            header="Max Quantity of Product Per Coupon"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "11%",
                              textTransform: "capitalize",
                            }}
                            body={(rowData) =>
                              rowData.max_qty_per_coupon == null ||
                              "" == rowData.max_qty_per_coupon ||
                              false == rowData.max_qty_per_coupon
                                ? "N.A."
                                : rowData.max_qty_per_coupon
                            }
                          />
                          <Column
                            field="coupon_time_used"
                            header="Max Usage Per Reward Customer"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "11%",
                              textTransform: "capitalize",
                            }}
                            body={(rowData) =>
                              rowData.pivot.max_usage == null ||
                              "" == rowData.pivot.max_usage ||
                              false == rowData.pivot.max_usage
                                ? "0"
                                : rowData.pivot.max_usage
                            }
                          />
                        </DataTable>
                      ) : (
                        <Text
                          display="inline-flex"
                          alignItems="center"
                          color={Colors.posTextInfo}
                          fontWeight="300"
                        >
                          {Constants.NO_COUPONS_ATTACHED}
                        </Text>
                      )}
                    </>
                  </Box>
                </VStack>
              </Box>
            </Box>
          </TabPanel>
          <TabPanel
            pl={{ base: "1rem", md: "2.94rem" }}
            pt={{ base: "1rem", md: "2rem" }}
            pr={{ base: "1rem", md: "4.86rem" }}
            pb={{ base: "1rem", md: "3.13rem" }}
          >
            {onlySmsSelected == 0 ? (
              undefined != emailTemplate && null != emailTemplate ? (
                <EmailTemplate templateHtml={emailTemplate} />
              ) : (
                <Heading
                  as={"h3"}
                  color={Colors.moduleHeading}
                  fontSize={"1.13rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.02rem"}
                >
                  You have not selected any email template yet.
                </Heading>
              )
            ) : (
              <Heading
                as={"h3"}
                color={Colors.moduleHeading}
                fontSize={"1.13rem"}
                fontStyle={"normal"}
                fontWeight={"500"}
                lineHeight={"normal"}
                letterSpacing={"-0.02rem"}
              >
                You have selected SMS as a marketing type.
              </Heading>
            )}
          </TabPanel>
          <TabPanel
            bg={"#FAFAFA"}
            pl={{ base: "1rem", md: "2.94rem" }}
            pt={{ base: "1rem", md: "2rem" }}
            pr={{ base: "1rem", md: "4.86rem" }}
            pb={{ base: "1rem", md: "3.13rem" }}
          >
            {!searchInputHideRef.current ? (
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
            ) : null}
            {showTable ? null : loading ? (
              <Box
                w={{ base: "100%", md: "100%" }}
                pt={{ base: "1rem", md: "2rem" }}
                flexShrink={"0"}
                pl={{ base: 0, md: "1rem" }}
                borderRadius={"0.31rem"}
              >
                <Heading
                  as={"h3"}
                  color={Colors.moduleHeading}
                  fontSize={"1.13rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.02rem"}
                >
                  Please wait while we fetch user(s) for you...
                </Heading>
              </Box>
            ) : totalcount > 0 ? (
              <ThemeProvider theme={Mtheme}>
                <Flex
                  direction={"column"}
                  gap={4}
                  pt={{ base: "1rem", md: "1.2rem" }}
                >
                  <PosDataGridTable
                    columns={initialVisibilityModel}
                    rows={modifiedCouponData}
                    totalcount={totalcount}
                    columnVisibilityModel={activeColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setActiveColumnVisibilityModel(newModel)
                    }
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      effectRun.current = true;
                      setSortModel(newSortModel);
                    }}
                    fileName={Constants.CAMPAIGN_USER__CSV}
                  />
                </Flex>
              </ThemeProvider>
            ) : (
              <Flex
                direction={"column"}
                gap={4}
                pt={{ base: "1rem", md: "1.2rem" }}
              >
                <PosNoDataFound title={Constants.CAMPAIGNS_USER_NOT_FOUND} />
              </Flex>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal
        isOpen={isCoupProdViewModelOpen}
        onClose={onCoupProdViewModelClose}
        size={"2xl"}
        isCentered
        closeOnOverlayClick={false}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Product Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedCouponProdViewDetails.length > 0 && (
              <DataTable
                style={{ marginTop: "1rem", width: "100%", maxHeight: "30rem" }}
                value={selectedCouponProdViewDetails}
              >
                <Column
                  field="id"
                  header="Id"
                  headerStyle={{
                    backgroundColor: "#5881fe1a",
                    fontWeight: "500",
                    color: "#38128A",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                  style={{
                    color: "#010048",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                    width: "11%",
                    textTransform: "capitalize",
                  }}
                />
                <Column
                  field="name"
                  header="Name"
                  headerStyle={{
                    backgroundColor: "#5881fe1a",
                    fontWeight: "500",
                    color: "#38128A",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                  style={{
                    color: "#010048",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                    width: "11%",
                    textTransform: "capitalize",
                  }}
                />
                <Column
                  field="coupon_type"
                  header="Barcode"
                  headerStyle={{
                    backgroundColor: "#5881fe1a",
                    fontWeight: "500",
                    color: "#38128A",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                  style={{
                    color: "#010048",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                    width: "11%",
                    textTransform: "capitalize",
                  }}
                  body={(rowData) =>
                    rowData.barcode == null ||
                    "" == rowData.barcode ||
                    false == rowData.barcode
                      ? "N.A."
                      : rowData.barcode
                  }
                />
              </DataTable>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isTimeUsedViewModelOpen}
        onClose={onTimeUsedViewModelClose}
        size={"2xl"}
        isCentered
        closeOnOverlayClick={false}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Time Used Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Text
                display="inline-flex"
                alignItems="center"
                color={Colors.posTextInfo}
                fontWeight="500"
              >
                Name
              </Text>
              <Box>
                <Text
                  display="inline-flex"
                  alignItems="center"
                  color={Colors.posTextInfo}
                  fontWeight="300"
                >
                  {campaignUserTimeUsed.first_name ||
                  campaignUserTimeUsed.last_name
                    ? `${campaignUserTimeUsed.first_name || ""} ${
                        campaignUserTimeUsed.last_name || ""
                      }`
                    : "N.A"}
                </Text>
              </Box>
            </Box>
            {campaignUserTimeUsed?.coupon_usage_details?.length > 0 && (
              <DataTable
                style={{ marginTop: "1rem", width: "100%", maxHeight: "30rem" }}
                value={campaignUserTimeUsed.coupon_usage_details}
              >
                <Column
                  field="coupon_name"
                  header="Coupon Name"
                  headerStyle={{
                    backgroundColor: "#5881fe1a",
                    fontWeight: "500",
                    color: "#38128A",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                  style={{
                    color: "#010048",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                    width: "11%",
                    textTransform: "capitalize",
                  }}
                />
                <Column
                  field="coupon_type"
                  header="Type"
                  headerStyle={{
                    backgroundColor: "#5881fe1a",
                    fontWeight: "500",
                    color: "#38128A",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                  style={{
                    color: "#010048",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                    width: "11%",
                    textTransform: "capitalize",
                  }}
                  body={(rowData) =>
                    rowData.coupon_type == null ||
                    "" == rowData.coupon_type ||
                    false == rowData.coupon_type
                      ? "N.A."
                      : rowData.coupon_type == "ord"
                      ? "Order"
                      : "Product"
                  }
                />
                <Column
                  field="max_usage"
                  header="Maximum Usage"
                  headerStyle={{
                    backgroundColor: "#5881fe1a",
                    fontWeight: "500",
                    color: "#38128A",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                  style={{
                    color: "#010048",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                    width: "11%",
                    textTransform: "capitalize",
                  }}
                  body={(rowData) =>
                    rowData.max_usage == null ||
                    "" == rowData.max_usage ||
                    false == rowData.max_usage
                      ? "N.A."
                      : rowData.max_usage
                  }
                />
                <Column
                  field="time_used"
                  header="Time Used"
                  headerStyle={{
                    backgroundColor: "#5881fe1a",
                    fontWeight: "500",
                    color: "#38128A",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                  style={{
                    color: "#010048",
                    fontSize: "0.93rem",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                    width: "11%",
                    textTransform: "capitalize",
                  }}
                  body={(rowData) =>
                    rowData.time_used == null ||
                    "" == rowData.time_used ||
                    false == rowData.time_used
                      ? 0
                      : rowData.time_used
                  }
                />
              </DataTable>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </PosDrawer>
  );
};

export default WithRouter(CampaignView);
