import {
  Box,
  Flex,
  Image,
  Spacer,
  Text,
  useMediaQuery
} from "@chakra-ui/react";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import React from "react";
import Colors from "../../assets/colors/Colors";
import Selected from "../../assets/images/charm_tick.svg";
const PosDropDown = (props) => {
  // const [isMobile] = useMediaQuery("(max-width: 1499px)");
  // const customItemTemplate = (option, values) => {
  //   if (values.findIndex((x) => x.id === option.id) > -1) {
  //     return (
  //       <Flex w="16.5rem">
  //         <Text fontSize={"0.94rem"} fontWeight={300} color="#01004899">
  //           {option.name}
  //         </Text>
  //         <Spacer />
  //         <Image h={4} w={4} src={Selected} alt="select" />
  //       </Flex>
  //     );
  //   } else {
  //     return (
  //       <Flex>
  //         <Text fontSize={"0.94rem"} fontWeight={300} color="#01004899">
  //           {option.name}
  //         </Text>
  //         <Spacer />
  //       </Flex>
  //     );
  //   }
  // };
  if (props.multiSelect) {
    return (
      <Box className="card justify-content-center">
        {/* <style>
          {`
          .p-checkbox {
            display: none;
          },
        `}
        </style> */}
        <MultiSelect
          value={props.value}
          onChange={props.onChange}
          options={props.options}
          optionLabel={props.optionLabel}
          onBlur={props.onBlur}
          onHide={props.onHide}
          filter
          placeholder={props.placeholder}
          // placeholder={
          //   <span
          //     style={{
          //       color: props.lableAvailable ? "#01004833" : Colors.posTextInfo,
          //       fontSize: "0.94rem !important",
          //       fontWeight: 300,
          //       letterSpacing: "-0.225px !important",
          //     }}
          //   >
          //     {props.placeholder}
          //   </span>
          // }
          style={{
            width: props.width,
            backgroundColor: "rgba(88, 129, 254, 0.05)",
            borderRadius: "0.625rem",
            fontSize: "0.94rem",
            fontWeight: 300,
            color: "#010048",
            height: props.height,
            borderRadius: "10px",
            border: "1px solid rgba(183, 183, 183, 0.12)",
          }}
          panelStyle={{
            backgroundColor: "#F4F4F4",
            width: "20.625rem",
            borderRadius: "0.625rem",
            border: "1px solid rgba(183, 183, 183, 0.12)",
          }}
          filterInputAutoFocus={true}
          filterPlaceholder={"Search"}
          // itemTemplate={(option) => customItemTemplate(option, props.value)}
          className={props.className}
          maxSelectedLabels={props.maxSelectedLabels}
          // display={props.display}
          resetFilterOnHide={true}
          disabled={props.disabled}
        />
      </Box>
    );
  } else {
    return (
      <Box className="card justify-content-center">
        <Dropdown
          value={props.value}
          onChange={(e) => props.onChange(e)}
          options={props.options}
          optionLabel={props.optionLabel}
          placeholder={props.placeholder}
          // placeholder={
          //   <span
          //     style={{
          //       color: props.lableAvailable ? "#01004833" : Colors.posTextInfo,
          //       fontSize: "0.94rem !important",
          //       fontWeight: 300,
          //       letterSpacing: "-0.225px !important",
          //     }}
          //   >
          //     {props.placeholder}
          //   </span>
          // }
          style={{
            width: props.width,
            maxWidth: props.maxWidth,
            backgroundColor: "rgba(88, 129, 254, 0.05)",
            borderRadius: "0.625rem",
            fontSize: "0.94rem",
            fontWeight: 300,
            color: "#010048",
            height: props.height,
            borderRadius: "10px",
            border: "1px solid rgba(183, 183, 183, 0.12)",
          }}
          panelStyle={{
            backgroundColor: "#F4F4F4",
            width: "20.625rem",
            borderRadius: "0.625rem",
            border: "1px solid rgba(183, 183, 183, 0.12)",
          }}
          filterInputAutoFocus={true}
          filterPlaceholder={"Search"}
          onBlur={props.onBlur}
          className={props.className}
          disabled={props.disabled}
          resetFilterOnHide={true}
          // maxSelectedLabels={props.maxSelectedLabels}
          filter
        />
      </Box>
    );
  }
};
export default PosDropDown;