import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Heading,
  Text,
} from "@chakra-ui/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Chips } from "primereact/chips";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTostMessage,
  PosListHeader,
  PosSpliteButton,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import SendIcon from "@mui/icons-material/Send";
import {
  endOfTodaysDay,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import {
  getCustomerAgedReceivableAndExportReportPdf,
  sendEmailStatementToCustomer,
} from "./CustomerAgedReceivableService";

const CustomerAgedReceiVable = (props) => {
  const myContext = useContext(AppContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const [searchQuery, setSearchQuery] = useState("");
  const [totalcount, setTotalCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [loading, setLoading] = useState(true);
  const effectRun = useRef(true);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isMeduim] = useMediaQuery("(max-width: 1180px)");
  const [isExtraLarges] = useMediaQuery("(min-width: 1365px)");
  const [isLarge] = useMediaQuery("(min-width: 1315px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1260px)");
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [isVisible, setIsVisible] = useState(false);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const { error } = PosErrorHook();
  const [locations, setLocations] = useState([]);
  const [customer, setCustomer] = useState([]);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const searchInputHideRef = useRef(false);
  const { addToast } = PosTostMessage();
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedCustomerForEmail, setSelectedCustomerForEmail] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "customer_name",
      sort: "desc",
    },
  ]);
  const sortRef = useRef(sortModel);
  const [listOlColumnVisibilityModel, setOlColumnVisibilityModel] =
    React.useState({
      // id: false,
    });
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 1.2 };
  const actionColumnXS = isMobile ? { width: 120 } : { flex: 0.9 };
  const [timeoutId, setTimeoutId] = useState(null);
  const [emailCustomer, setEmailCustomer] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
          effectRun.current = false;
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setCustomer(loaderResponse?.data?.data?.customer);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        if (!searchInputHideRef.current && actionResponse.error.data === 0) {
          searchInputHideRef.current = true;
        }
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Customer aged receivable Report Exported successfully."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.CUSTOMER_AGED_RECEIVABLE_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          searchInputHideRef.current = false;
          setTableData(actionResponse.data.data.customerAgingData);
          setTotalCount(actionResponse.data.data.totalItems);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  const formik = useFormik({
    initialValues: {
      selectedCustomers: [],
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      fetchData();
    }
  }, [paginationModel, sortModel, formik.values.selectedCustomers]);
  const fetchData = async () => {
    setLoading(true);
    let data = {
      getreport: true,
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      query: JSON.stringify({
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        filter: searchQuery,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      }),
    };
    if (selectedCustomers.length > 0) {
      data.customers = JSON.stringify(selectedCustomers);
    } else {
      data.customers = JSON.stringify([]);
    }
    submit(data, {
      method: Constants.POST,
      path: Constants.CUSTOMER_AGED_RECEIVABLE_ROUTE,
    });
  };

  const exportData = async () => {
    myContext.handleLoading(true);
    let data = {
      getreport: false,
      type: "xls",
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      query: JSON.stringify({
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        filter: searchQuery,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      }),
    };
    if (selectedCustomers.length > 0) {
      data.customers = JSON.stringify(selectedCustomers);
    } else {
      data.customers = JSON.stringify([]);
    }
    submit(data, {
      method: Constants.POST,
      path: Constants.CUSTOMER_AGED_RECEIVABLE_ROUTE,
    });
  };

  const handleOptionSelect = (e) => {
    effectRun.current = true;
    if (undefined !== e.value) {
      setSelectedCustomers(e.value);
      formik.setFieldValue("selectedCustomers", e.value);
    }
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };

  const clearFilterDAta = () => {
    effectRun.current = true;
    if (searchQuery.length > 0) {
      setSearchQuery("");
    }
    formik.setFieldValue("selectedCustomers", []);
    setSelectedCustomers([]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setSelectedCustomers([]);
    effectRun.current = true;
    if (formik.values.selectedCustomers.length > 0) {
      formik.setFieldValue("selectedCustomers", "");
      formik.setFieldTouched("selectedCustomers", false);
    }
  };
  const columnNames = [
    {
      field: "customer_name",
      headerName: "Customer",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "customer_phone",
      headerName: "Phone",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "total",
      headerName: "Total($)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "current",
      headerName: "Current($)",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "over_30_days",
      headerName: "Over 30 Days($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "over_60_days",
      headerName: "Over 60 Days($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "over_90_days",
      headerName: "Over 90 Days($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      ...actionColumnFlexDouble,
      renderCell: (params) => {
        const rowData = params.row;
        const isDisabled = parseFloat(rowData.total) <= 0;
        const isZeroTotal = parseFloat(rowData.total) <= 0;

        if (isZeroTotal) {
          return <>N.A.</>;
        }
        const options = {
          buttonName: "Print Statement",
          buttonIcon: "",
          disabled: isDisabled,
          handleClick: (item) => handlePdfStatement(item),
          subButtons: [
            {
              name: "Email Statement",
              icon: <SendIcon style={{ color: Colors.posViewTextColor }} />,
              onClick: (item) => handleEmailStatement(item),
              disabledButton: isDisabled,
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];
  const modifiedData = tableData.map((data, i) => ({
    id: data.customer_id,
    customer_name: data.customer_name,
    customer_phone: data.customer_phone,
    customer_email: data.customer_email,
    total: data.total.toFixed(2),
    current: data.current.toFixed(2),
    over_30_days: data.over_30_days.toFixed(2),
    over_60_days: data.over_60_days.toFixed(2),
    over_90_days: data.over_90_days.toFixed(2),
  }));
  const handleSearchList = () => {
    effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };
  const handleClear = () => {
    effectRun.current = true;
    setSearchQuery("");
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };

  const apply = () => {
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };
  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      effectRun.current = true;
      apply();
    }, 250);
    setTimeoutId(newTimeoutId);
  };

  const handlePdfStatement = (item) => {
    myContext.handleLoading(true);
    let data = {
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      query: {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        filter: searchQuery,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      },
      customers: [
        {
          id: item.id,
          name: item.customer_name,
        },
      ],
    };
    try {
      getCustomerAgedReceivableAndExportReportPdf(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            var url = Constants.REACT_APP_API_URL + response.data.data;
            window.open(url, "_blank");
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const handleEmailStatement = (item) => {
    let customer = {
      id: item.id,
      name: item.customer_name,
    };
    setSelectedCustomerForEmail([customer]);
    if (item.customer_email) {
      setEmailCustomer([item.customer_email]);
    } else {
      setEmailCustomer([]);
    }
    onOpen();
  };

  const emailValue = (email, key) => {
    const re = new RegExp(Constants.EMAIL_REGEX);
    const testResult = re.test(String(email).trim().toLowerCase());
    if (!testResult) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [key]: email + ` is not a valid email address.`,
      }));
    } else {
      if (key == "dsr") {
        emailCustomer.push(email);
        setEmailCustomer([...emailCustomer]);
        setValidationErrors({});
      }
    }
  };

  const handleRemove = (chipToRemove, key) => {
    if (key === "dsr") {
      setEmailCustomer(emailCustomer.filter((chip) => chip != chipToRemove));
    }
  };

  const validateEmails = (emails) => {
    const errors = [];
    const emailRegex = new RegExp(Constants.EMAIL_REGEX);
    
    if (!emails || emails.length === 0) {
      errors.push("At least one email address is required");
    } else {
      emails.forEach(email => {
        if (!emailRegex.test(String(email).trim().toLowerCase())) {
          errors.push(`${email} is not a valid email address`);
        }
      });
    }
    
    return errors;
  };

  const sendEmailStatement = () => {
    const emailErrors = validateEmails(emailCustomer);
  
    if (emailErrors.length > 0) {
      setValidationErrors({
        dsr: emailErrors.join(", ")
      });
      return;
    }
    myContext.handleLoading(true);
    let data = {
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      query: {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        filter: searchQuery,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      },
      email: emailCustomer,
    };
    if (selectedCustomerForEmail.length > 0) {
      data.customers = selectedCustomerForEmail;
    } else {
      data.customers = [];
    }
    try {
      sendEmailStatementToCustomer(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.TOAST_HEADING_SUCCESS,
              alertDescription: "Email Statement Sent Successfully",
            });
            onClose();
            setEmailCustomer([]);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const onCloseEmailModal = () => {
    onClose();
    setValidationErrors("");
    setEmailCustomer([]);
  };

  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <Modal
        isOpen={isOpen}
        onClose={onCloseEmailModal}
        isCentered
        closeOnOverlayClick={false}
        size="2xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <PosListHeader header={"Email Statement"} />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody></ModalBody>
          <Heading
            ml={8}
            as="h3"
            size="xs"
            fontWeight="500"
            fontSize="1.125rem"
            lineHeight="1.40625rem"
            letterSpacing="-0.016875rem"
            color="#010048"
          >
            Send Email Statement To
          </Heading>
          <Box ml={8} mr={8} mt={2}>
            <Chips
              style={{
                backgroundColor: Colors.posviewbgcolor,
                display: "flex",
                padding: "0.1rem",
                paddingLeft: "0.1rem",
                gap: "0.1rem",
                borderRadius: "0.1rem",
                border: "1px solid #E3E3E3",
              }}
              addOnTab={true}
              onAdd={(e) => {
                emailValue(e.value, "dsr");
              }}
              onRemove={(e) => {
                handleRemove(e.value, "dsr");
                setValidationErrors({});
              }}
              addOnBlur={true}
              allowDuplicate={false}
              placeholder="Add Email Addresses"
              value={emailCustomer}
            />
            
            <Text color={Colors.errorColor}>{validationErrors["dsr"]}</Text>
            
          </Box>
          <ModalFooter>
            <PosFormButton
              buttonsubmit={"Submit"}
              SubmitButton={true}
              // isDisabled={emailCustomer.length === 0}
              onClick={sendEmailStatement}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Customer Aged Receivable"]}
          breadCrumTitle={"Customer Aged Receivable"}
        />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <>
              <PosIconButton
                name={Constants.EXPORT_BUTTON}
                onClick={exportData}
                exportIcon={true}
                width={"7.5rem"}
              />
              {/* <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  Export
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={exportData}>Export Excel</MenuItem>
                  <MenuItem onClick={handlePdfStatement}>
                    Print Statement
                  </MenuItem>
                  <MenuItem onClick={handleEmailStatement}>
                    Email Statement
                  </MenuItem>
                </MenuList>
              </Menu> */}
            </>
          )}
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Flex
          flexDirection={isMobile ? "column" : isMeduim ? "column" : "row"}
          mt={6}
          mr={4}
        >
          {!searchInputHideRef.current ? (
            <Flex flexDirection={isMobile ? "column" : "row"} ml={4}>
              <PosSearch
                value={searchQuery}
                onChange={onChange}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
              <Box
                display={"flex"}
                justifyContent={"center"}
                w={"3rem"}
                ml={{ base: 0, md: 4 }}
                mt={{ base: 4, md: 0 }}
                mb={{ base: 4 }}
              >
                <IconButton
                  onClick={() => {
                    toggleVisibility();
                  }}
                  style={{
                    border: "1px solid #ccc",
                    boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                    variant: "posoutline",
                    color: Colors.posTextInfo,
                    borderColor: Colors.dividerColor,
                    borderRadius: "5px",
                    padding: 5,
                  }}
                >
                  <FilterListIcon style={{ color: Colors.posTextInfo }} />
                </IconButton>
              </Box>
            </Flex>
          ) : null}
          {isVisible && (
            <Flex
              flexDirection={isMeduim ? "column" : "row"}
              ml={{ base: 4, md: 4 }}
              gap={4}
            >
              <Box>
                <PosDropDown
                  id="selectedCustomers"
                  options={customer}
                  value={selectedCustomers}
                  resetFilterOnHide={true}
                  onChange={handleOptionSelect}
                  onBlur={(e) => {
                    formik.setFieldTouched("selectedCustomers");
                    formik.handleBlur(e);
                  }}
                  optionLabel="name"
                  placeholder="Select Customers"
                  width={
                    isExtraLarges
                      ? "20rem"
                      : isLarge
                      ? "17rem"
                      : isMeduim1
                      ? "13.32rem"
                      : "100%"
                  }
                  height={"2.5rem"}
                  className="w-full md:w-20rem"
                  multiSelect={true}
                />
              </Box>
              <Box>
                <PosFormButton
                  isDisabled={
                    formik.values.selectedCustomers.length > 0 ? false : true
                  }
                  onClick={() => {
                    clearFilterDAta();
                  }}
                  buttonText={"Clear"}
                  CancelButton={true}
                />
              </Box>
            </Flex>
          )}
        </Flex>
        {loading ? (
          <PosProgress />
        ) : totalcount > 0 ? (
          <Box padding={"1rem"}>
            <PosDataGridTable
              columns={columnNames}
              rows={modifiedData}
              totalcount={totalcount}
              columnVisibilityModel={listOlColumnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setOlColumnVisibilityModel(newModel)
              }
              paginationModel={paginationModel}
              paginationMode="server"
              sortingMode="server"
              onPaginationModelChange={(newPageModel) => {
                effectRun.current = true;
                setPaginationModel(newPageModel);
              }}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                effectRun.current = true;
                sortRef.current = sortModel;
                setSortModel(newSortModel);
              }}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          </Box>
        ) : (
          <Box padding={"1rem"}>
            <PosNoDataFound
              title={Constants.CUSTOMER_AGED_RECEIVABLE_NOT_FOUND}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default WithRouter(CustomerAgedReceiVable);
