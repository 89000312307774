import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Wrap,
  WrapItem,
  useMediaQuery,
} from "@chakra-ui/react";
import { SimpleReportLayout } from "../../../../../layouts/index";
import CloseIcon from "@mui/icons-material/Close";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  startOfTodaysDay,
  commonDateFormate,
  reportDateFormate
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import {
  exportPayoutReportData,
  getDectivePayOutList,
  getPayOutList,
} from "./PayoutService";

const PayoutList = (props) => {
  const stateData = useLocation(); //get backraking data as param
  const loaderResponse = useLoaderData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );

  const myContext = useContext(AppContext);
  const tabNames = ["Active", "Inactive"];
  const [selectedLoactions, setSelectedLoactions] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? JSON.parse(stateData.state.location)
      : {}
  );
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [showTable, setShowTable] = useState(true);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [toDateFocus, setToDateFocus] = useState(false);
  const [totalcount, setTotalCount] = useState(0);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [errors, setErrors] = useState("");
  const [locations, setLocations] = useState([]);
  const [rewardDeactiveData, setRewardDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [applyClicked, setApplyClicked] = useState(false);
  const [buttonDisables, setButtonDisables] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isDeactiveMobile] = useMediaQuery("(max-width: 1334px)");
  const [sortType, setSortType] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order_type
      : "desc"
  );

  const [page, setPage] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.page
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.limit
      : 25
  );

  const [sortColumn, setSortColumn] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.order
      : "id"
  );
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const [rewardData, setRewardSetData] = useState([]);
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const [loading, setLoading] = useState(true);
  const [curruntTab, setCurruntTab] = useState(0);
  const [buttonDisable, setButtonDisable] = useState(false);
  // const [dataLoaded, setDataLoaded] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    id: false,
  });

  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });

  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "created_at",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });
  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "id",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  // backtracking
  const sortRefDeactive = useRef(sortModelDeactive);
  const paginationRef = useRef(paginationModel);

  const clearDropDown = () => {
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    effectRun.current = true;
    // formik.resetForm();
    formik.resetForm({
      values: {
        selectedLoactions: "",
        fromDate: "",
        toDate: "",
      },
    });
    window.history.replaceState({}, '');
    setLoading(true);
    // fetchData();
    setSelectedLoactions({});
    setApplyClicked(false);
  };

  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.PAY_OUT_REPORT_ROUTE);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    effectRun.current = true;
    setLoading(true);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    effectRun.current = true;
    setLoading(true);
  };

  const sortRef = useRef(sortModel);
  const handleExcelReport = (item) => {
    const selectedLocations = formik.values.selectedLoactions;
    const fromDate = formik.values.fromDate;
    const toDate = formik.values.toDate;
  
    const clearTableDataWithMessage = (message) => {
      clearTableData(message);
      myContext.handleLoading(false);
    };
  
    if ((!selectedLocations || selectedLocations.length === 0) && !fromDate && !toDate) {
      myContext.handleLoading(true);
      let data = {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order: sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type: sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dateFrom: "",
        dateTo: "",
        location: "",
        type: "xls",
      };
      exportPayoutReportData(data)
        .then((response) => {
          if (response.data.flag) {
            const url = Constants.REACT_APP_API_URL + response.data.data;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(Constants.DOWNLOAD, "Payout.xlsx");
            document.body.appendChild(link);
            window.stop();
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.EXPORT_TITLE,
              alertDescription: response.data.message,
            });
          } else {
            error({ actionData: response });
          }
        })
        .catch((err) => {
          error({ actionData: err });
        })
        .finally(() => {
          myContext.handleLoading(false);
        });
      return;
    }
    if (!selectedLocations || selectedLocations.length === 0) {
      clearTableDataWithMessage(Constants.LOCATION_IS_REQUIRED);
      return;
    }
    if (!fromDate || fromDate === "") {
      clearTableDataWithMessage(Constants.FORM_DATE_IS_REQUIRED);
      return;
    }
    if (!toDate || toDate === "") {
      clearTableDataWithMessage(Constants.TO_DATE_IS_REQUIRED);
      return;
    }
    myContext.handleLoading(true);
    let dateFromFormatted = reportDateFormate(fromDate) || "";
    let dateToFormatted = reportDateFormate(toDate) || "";
  
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order: sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type: sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      dateFrom: dateFromFormatted,
      dateTo: dateToFormatted,
      location: selectedLoactions.id
      ? {
          label: selectedLoactions.name,
          value: {
            id: selectedLoactions.id,
            name: selectedLoactions.name,
          },
        }
      : "",
      type: "xls",
    };
    try {
      exportPayoutReportData(data)
        .then((response) => {
          if (response.data.flag) {
            const url = Constants.REACT_APP_API_URL + response.data.data;
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(Constants.DOWNLOAD, "Payout.xlsx");
            document.body.appendChild(link);
            window.stop();
            link.click();
            window.URL.revokeObjectURL(url);
            link.remove();
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.EXPORT_TITLE,
              alertDescription: response.data.message,
            });
          } else {
            error({ actionData: response });
          }
        })
        .catch((err) => {
          error({ actionData: err });
        })
        .finally(() => {
          myContext.handleLoading(false);
        });
    } catch (error) {
      error({ actionData: error });
      myContext.handleLoading(false);
    }
  };
  
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 1.5 };
  const columnNames = [
    { field: "id", headerName: "Id", sortable: false, width: 90 },
    {
      field: "pos_station_id",
      headerName: "POS Station",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "user_id",
      headerName: "Employee",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "amount",
      headerName: "Amount($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "created_at",
      headerName: "Pay Out Date",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "location_id",
      headerName: "Location",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "reason",
      headerName: "Reason",
      sortable: true,
      ...actionColumnFlexDouble,
      renderCell: (params) => (
        <Box style={{ wordWrap: "break-word" }}>{params.value}</Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 110,
      renderCell: (params) => {
        const rowData = params.row;

        const handleEditPage = (item) => {
          // let selectedLocationName = formik.values.selectedLoactions?.name || "";
          let data = {
            location: JSON.stringify(selectedLoactions),
            dateFrom: formik.values.fromDate,
            dateTo: formik.values.toDate,
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            filter: searchQuery,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
          };
          myContext.handleLoading(true);
          // pass backtraking relate all data
          // props.navigate(`${Constants.PAYOUT_EDIT_ROUTE  + item.id}`,{
          //   state: data,
          // });
          if (
            (data.location && data.dateFrom && data.dateTo) ||
            data.filter ||
            data.limit
          ) {
            props.navigate(`${Constants.PAYOUT_EDIT_ROUTE + item.id}`, {
              state: data,
            });
          } else {
            props.navigate(`${Constants.PAYOUT_EDIT_ROUTE + item.id}`);
          }
        };

        const handleDeactive = (item) => {
          setButtonDisables(true);
          if (modifiedData.length == 1 && paginationModel.page != 0) {
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.PAY_OUT_REPORT_ROUTE,
          });
        };

        const options = {
          buttonName: "Edit",
          buttonIcon: "",
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            {
              name: "Deactive",
              disabledButton: buttonDisables,
              icon: <CloseIcon style={{ color: "red" }} />,
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];

  const columnNamesDeactive = [
    { field: "id", headerName: "Id", sortable: false, width: 90 },
    {
      field: "pos_station_id",
      headerName: "Pos Station",
      sortable: true,
      ...actionColumn,
    },
    { field: "user_id", headerName: "User", sortable: true, ...actionColumn },
    {
      field: "amount",
      headerName: "Amount($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "reason",
      headerName: "Reason",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleReactive = () => {
          setButtonDisables(true);
          if (
            deactiveRewardData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.PAY_OUT_REPORT_ROUTE,
          });
        };
        return (
          <Text
            onClick={handleReactive}
            cursor={buttonDisables ? "not-allowed" : "pointer"}
            color={buttonDisables ? Colors.posNavbarLink : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={buttonDisables ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisables ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];

  const handleReactive = (id) => {
    myContext.handleLoading(true);
    let data = {
      id: id,
      type: Constants.REACTIVE,
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.PAY_OUT_REPORT_ROUTE,
    });
  };

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
      setSearchQuery("");
      // clearDropDown();
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      setSortModel([
        {
          field: "created_at",
          sort: "desc",
        },
      ]);
      // setPage(0);
      // setRowsPerPage(25);
      effectRun.current = true;
      setLoading(true);
      if(undefined !== loaderResponse?.data?.data?.location &&
        null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length > 1){
          setSelectedLoactions([]);
          formik.resetForm({
            values: {
              selectedLoactions: "",
              fromDate: "", 
              toDate: "", 
            },
          });
      }
      formik.resetForm({
        values: {
          selectedLoactions: "",
          fromDate: "", 
          toDate: "", 
        },
      });
      window.history.replaceState({}, '');
    } else {
      searchInputHideRef.current = false;
      setSearchQuery("");
      // clearDropDown();
      setPaginationModelDeactive({
        page: 0,
        pageSize: 25,
      });
      setSortModelDeactive([
        {
          field: "amount",
          sort: "desc",
        },
      ]);
      // setPage(0);
      // setRowsPerPage(25);
      effectRun.current = true;
      setLoading(true);
      window.history.replaceState({}, '');
    }
  };

  const handleSearchList = () => {
    // search time get active and deactive list
    paginationModel.page = 0;
    props.navigate(Constants.PAY_OUT_REPORT_ROUTE);
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchPayoutDeActiveData();
    }
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLoactions(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
    }
  };

  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
        // &&
        // undefined !== loaderResponse.data &&
        // null !== loaderResponse.data &&
        // undefined !== loaderResponse.data.data &&
        // null !== loaderResponse.data.data &&
        // Object.keys(loaderResponse.data.data).length > 0
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (undefined !== stateData.state &&
            null !== stateData.state &&
            "" !== stateData.state) {
            const locationString = stateData.state.location;
            const locationObject = JSON.parse(locationString);
            formik.setFieldValue("selectedLoactions", locationObject.name);
          }
          if(undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1){
              setSelectedLoactions(loaderResponse?.data?.data?.location[0])
              formik.setFieldValue("selectedLoactions", loaderResponse?.data?.data?.location[0].name);
              setOlColumnVisibilityModel(prev => ({...prev, location_id: false }));
            }
          setLocations(loaderResponse?.data?.data?.location);
          // setDataLoaded(true);
          myContext.handleLoading(false);
        }
        // else {
        //   setDataLoaded(false); // Set dataLoaded to false when data is empty, undefined, or null
        // }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  const apply = () => {
    formik.setTouched(
      {
        selectedLoactions: true,
        fromDate: true,
        toDate: true,
      },
      true
    );
    if(selectedLoactions?.length === 0 ||
      formik.values.fromDate === "" ||
      formik.values.toDate === "" ||
      formik.errors.selectedLoactions ||
      formik.errors.fromDate ||
      formik.errors.toDate){
      return;
    }
    effectRun.current = true;
    setLoading(true);
    setApplyClicked(true);

    // fetchData();
  };

  useEffect(() => {
    // page change, rowperPage change,tab change ,serchdata call
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchPayoutDeActiveData();
      }
      // return () => {
      effectRun.current = false;
      // };
    }
  }, [
    paginationModel,
    sortModel,
    loading,
    searchQuery,
    paginationModelDeactive,
    sortModelDeactive,
  ]);

  // useEffect(() => {
  //   if (curruntTab === 0) {
  //     fetchData();
  //   } else {
  //     fetchPayoutDeActiveData();
  //   }
  // } ,[paginationModel,sortModel]);

  const fetchData = async () => {
    setLoading(true);

    let data = {
      limit: paginationModel.pageSize,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      page: paginationModel.page + 1,
      filter: searchQuery,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    if (Object.keys(selectedLoactions).length > 0 && formik.values.fromDate && formik.values.toDate) {
      data.location = {
        label: selectedLoactions.name,
        value: {
          id: selectedLoactions.id,
          name: selectedLoactions.name,
        },
      };
      data.dateFrom = moment(formik.values.fromDate)
        .format("MM/DD/YYYY")
        .toString();
      data.dateTo = moment(formik.values.toDate)
        .format("MM/DD/YYYY")
        .toString();
      setApplyClicked(true);
    } else {
      setApplyClicked(false);
      data.location = "";
      data.dateFrom = ""; 
      data.dateTo = ""; 
    }

    setShowTable(false);
    try {
      getPayOutList(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setRewardSetData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setRewardSetData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
            // let actionData = response;
            // error({ actionData });
            // setTotalCount(0);
            // myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if ( applyClicked === true){
            searchInputHideRef.current = false;
          }else if(!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setRewardSetData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
          // let actionData = err;
          // error({ actionData });
          // setTotalCount(0);
          // myContext.handleLoading(false);
        });
    } catch (error) {
      setRewardSetData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
      // setTotalCount(0);
      // myContext.handleLoading(false);
    }
  };

  const fetchPayoutDeActiveData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    try {
      getDectivePayOutList(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setRewardDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setRewardDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !deactiveSearchHideRef.current &&
            err?.response?.data?.data === 0
          ) {
            deactiveSearchHideRef.current = true;
          }
          setRewardDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setRewardDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    // if (actionResponse?.data?.data?.tillCheck === 1) {
    //   addToast({
    //     alertStatus: Constants.TOAST_TYPE_WARNING,
    //     alertTitle: Constants.PAYIN_TITLE,
    //     alertDescription: Constants.PAY_IN_TILL_IS_OPENED,
    //   });
    //   myContext.handleLoading(true);
    //   props.navigate(`${Constants.PAY_IN_REPORT_ROUTE}`);
    // }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisables(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          if (modifiedData.length === 1 && paginationModel?.page !== 0) {
            effectRun.current = true;
            setPaginationModel((prevPaginationMod) => {
              return {
                page: prevPaginationMod?.page - 1,
                pageSize: prevPaginationMod?.pageSize,
              };
            });
          } else {
            fetchData();
          }
          setTableData(actionResponse.data.data.data);
          setTotalCount(actionResponse.data.data.totalItems);
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.PAYOUT_TITLE,
            alertDescription: Constants.PAYOUT_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisables(false);
        } else {
          setLoading(true);
          if (
            deactiveRewardData.length === 1 &&
            deactiveRewardData?.page !== 0
          ) {
            effectRun.current = true;
            setPaginationModel((prevPaginationMod) => {
              return {
                page: prevPaginationMod?.page - 1,
                pageSize: prevPaginationMod?.pageSize,
              };
            });
          } else {
            fetchPayoutDeActiveData();
          }
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.PAYOUT_TITLE,
            alertDescription: Constants.PAYOUT_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisables(false);
        }
      }
    }
  }, [actionResponse]);

  const formik = useFormik({
    initialValues: {
      // selectedLoactions: "",
      selectCategory:
        undefined !== loaderResponse?.data?.data?.category &&
        null !== loaderResponse?.data?.data?.category
          ? loaderResponse?.data?.data?.category
          : [],
      // fromDate: "",
      // toDate: "",
      selectedLoactions:
        undefined !== stateData?.state?.location
          ? stateData?.state?.location?.name
          : "",
      fromDate:
        undefined !== stateData?.state?.dateFrom
          ? stateData?.state?.dateFrom
          : "",
      toDate:
        undefined !== stateData?.state?.dateTo ? stateData?.state?.dateTo : "",
      // location: "",
    },
    validationSchema: Yup.object({
      selectCategory: Yup.array().min(1, Constants.PRODUCT_CATEGORY_REQUIRED),
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {},
  });

  const modifiedData = rewardData.map((data) => ({
    id: data.id,
    user_id: data.users.name,
    pos_station_id: data.station.name,
    amount: `$${data.amount}`,
    created_at: commonDateFormate(data.created_at),
    location_id: data.station.location.name,
    reason: data.reason,
  }));
  const deactiveRewardData = rewardDeactiveData.map((data) => ({
    id: data.id,
    user_id: data.users.name,
    pos_station_id: data.station.name,
    amount: `$${data.amount}`,
    reason: data.reason,
  }));
  const renderReportLayout = () => {
    return (
      <>
        <Box className="card justify-content-center">
          <PosDropDown
          id="selectedLoactions"
            options={locations}
            value={selectedLoactions}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            multiSelect={false}
            optionLabel="name"
            placeholder="Select Location*"
            width={
              isExtraLarge
                ? "20rem"
                : isLarge
                ? "17rem"
                : isMeduim1
                ? "13.32rem"
                : "100%"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
            formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box>
        <Stack
          className="card justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          mt={isMeduim ? 4 : 0}
        >
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              value={formik.values.fromDate}
              label={"From Date*"}
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              handleSelectData={hadleFromDateSelect}
              readOnlyInput={true}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              value={formik.values.toDate}
              label={"To Date*"}
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : dateTimeValue
              }
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              maxDate={dateTimeValue}
              readOnlyInput={true}
              viewDate={toDateView}
              showButtonBar={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Pay Out"]}
          breadCrumTitle={"Pay Out"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            display={"flex"}
            width={"8.5rem"}
            height={"2.81rem"}
            padding={"0.63rem"}
            justify-content={"center"}
            align-items={"center"}
            gap={"0.31rem"}
            flex-shrink={"0"}
            onClick={() => {
              myContext.handleLoading(true);
              let data = {
                location: JSON.stringify(selectedLoactions),
                dateFrom: formik.values.fromDate,
                dateTo: formik.values.toDate,
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                filter: curruntTab == 1 ? "" : searchQuery,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
              };
              if (
                (data.location && data.dateFrom && data.dateTo) ||
                data.filter ||
                data.limit
              ) {
                props.navigate(Constants.PAYOUT_CREATE, { state: data });
              } else {
                props.navigate(Constants.PAYOUT_CREATE);
              }
            }}
          />
          {modifiedData.length > 0 && curruntTab === 0 && (
            <PosIconButton
              // buttonsubmit="Export"
              name={Constants.EXPORT_BUTTON}
              display={"flex"}
              exportIcon={true}
              width={"8.5rem"}
              height={"2.81rem"}
              padding={"0.63rem"}
              justify-content={"center"}
              align-items={"center"}
              gap={"0.31rem"}
              flex-shrink={"0"}
              onClick={handleExcelReport}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
              <Spacer />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
              <Spacer />
            </Flex>
          ) : null}
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <>
              <Flex
                direction={isMeduim ? "column" : "row"}
                justifyContent={"space-between"}
              >
                <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
                <Stack
                  direction={"row"}
                  justifyContent={isMeduim ? "flex-start" : "flex-end"}
                  alignItems={"flex-start"}
                  pl={isMeduim ? 4 : 0}
                  pt={isMeduim ? 0 : 4}
                >
                  <PosFormButton
                    isDisabled={applyClicked ? false : true}
                    // disabled={applyClicked}
                    onClick={() => {
                      clearDropDown();
                    }}
                    buttonText={"Reset"}
                    CancelButton={true}
                  />
                  <PosFormButton
                    isDisabled={buttonDisable}
                    // isDisabled={
                      // selectedLoactions?.length === 0 ||
                      // formik.values.fromDate === "" ||
                      // formik.values.toDate === "" ||
                      // formik.errors.selectedLoactions ||
                      // // formik.errors.selectCategory ||
                      // formik.errors.fromDate ||
                      // formik.errors.toDate ||
                    //   buttonDisable
                    // }
                    buttonsubmit={"Apply"}
                    SubmitButton={true}
                    onClick={() => {
                      apply();
                    }}
                  />
                </Stack>
              </Flex>
            </>
          ) : null}
          <TabPanels>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : modifiedData.length > 0 && curruntTab === 0 ? (
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNames}
                    rows={modifiedData}
                    totalcount={totalcount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    sortingMode="server"
                    // onPaginationModelChange={setPaginationModel}
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      effectRun.current = true;
                      sortRef.current = sortModel;
                      setSortModel(newSortModel);
                    }}
                    // onSortModelChange={(newSortModel) => {
                    //   sortRef.current = sortModel;
                    //   setSortModel(newSortModel);
                    // }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.ACTIVE_PAYOUT_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.PAYOUT_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : deactiveRewardData.length > 0 && curruntTab === 1 ? (
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNamesDeactive}
                    rows={deactiveRewardData}
                    totalcount={deactiveTotalCount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModelDeactive}
                    paginationMode="server"
                    sortingMode="server"
                    // onPaginationModelChange={setPaginationModel}
                    onPaginationModelChange={(newPaginationModelDeactive) => {
                      effectRun.current = true;
                      setPaginationModelDeactive(newPaginationModelDeactive);
                    }}
                    sortModel={sortModelDeactive}
                    onSortModelChange={(newSortModelDeactive) => {
                      effectRun.current = true;
                      sortRef.current = sortModelDeactive;
                      setSortModelDeactive(newSortModelDeactive);
                    }}
                    // onSortModelChange={(newSortModel) => {
                    //   sortRef.current = sortModel;
                    //   setSortModel(newSortModel);
                    // }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.DEACTIVE_PAYOUT_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_PAYOUT_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(PayoutList);
