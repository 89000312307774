import {
  Box,
  Button,
  ButtonGroup,
  ChakraProvider,
  Divider,
  Flex,
  IconButton,
  Image,
  Spacer,
  Stack,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useDisclosure,
  useMediaQuery,
  useRadioGroup,
} from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox } from "@mui/material";
import { Dialog } from "primereact/dialog";
import { ThemeProvider } from "@mui/material/styles";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTable,
  PosTostMessage,
  PosSwitchButton
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import {
  endOfTodaysDay,
  startOfTodaysDay,
  twofix,
} from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { Mtheme, theme } from "../../../theme/index";
import {
  getProductsForSelectedLocation,
  getValidUntilCreate,
} from "./PromotionService";
import { GridToolbar } from "@mui/x-data-grid";

const PromotionCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  const [locations, setLocations] = useState([]);
  const [type, setSelectedType] = useState("");
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [allProduct, setAllProduct] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [viewSelectedProducts, setViewSelectedProducts] = React.useState([]);
  const [page, setPage] = useState(0);
  const [assignedBarcodes, setAssignedBarcodes] = useState([]);

  const [inputs, setInputs] = useState([]);
  const [Tiers, setTiers] = useState([]);

  const [promoTier, setPromoTier] = useState([]);
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [visible, setVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isMobileDialog] = useMediaQuery("(max-width: 992px)");
  const [timeoutId, setTimeoutId] = useState(null);

  const [checkedItemsProducts, setCheckedItemsProducts] = React.useState([]);
  // const [promotionSalePrice,setPromotionSalePrice] = useState([]);

  const [columnProductViewNames, setColumnProductViewNames] = useState([
    {
      columnNames: "Sr No.",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Product name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Primary Barcode",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Sale Price ($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Action",
      sortName: "",
      width: "",
    },
  ]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const [selectedProductError, setSelectedProductError] = useState(null);
  const [selectProductFromDialogError, setSelectProductFromDialogError] = useState(null);
  const sortRef = useRef(sortModel);

  const formik = useFormik({
    initialValues: {
      name: "",
      qty: "",
      type: "",
      valid_from: "",
      valid_until: "",
      selectedLocations: [],
      promotion_products: [],
      promo_tier: [],
      promotion_sale_price: [],
      exclude_discounts: false,
    },
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    // validateOnChange: true,
    validationSchema: Yup.object({
      selectedLocations: Yup.array().min(
        1,
        Constants.PROMO_LOCATIONS_ARE_REQUIRED
      ),
      name: Yup.string()
        .trim()
        .required(Constants.PROMOTION_NAME_REQUIRED)
        .max(191, Constants.TAX_NAME_LENGTH_GREATE_THEN_191),
      type: Yup.string().required(Constants.PROMOTION_TYPE_REQUIRED),
      qty: Yup.number().when("type", {
        is: "BX1",
        then: (schema) =>
          schema
            .integer(Constants.PROMOTION_BX1_QTY_NOT_VALID)
            .required(Constants.PROMOTION_BX1_QTY_REQUIRED)
            .typeError(Constants.PROMOTION_BX1_QTY_NOT_VALID)
            .min(1, Constants.PROMOTION_BX1_QTY_MIN_VALUE)
            .max(999999, Constants.PROMOTION_BX1_QTY_MAX_VALUE),
        otherwise: (schema) => schema.optional(),
      }),
    }),
    onSubmit: async (values) => {
      setVisible(false);
      var validUntilPassed = 0;
      if (
        undefined != formik.values.valid_until &&
        null != formik.values.valid_until &&
        "" != formik.values.valid_until
      ) {
        if (new Date(formik.values.valid_until) < new Date()) {
          validUntilPassed = 1;
        }
      }
      if (validUntilPassed == 0) {
        let payload = {
          ...values,
          promotionTypes: values.type,
          qty: values.type == Constants.BX1 ? values.qty : "",
          valid_from:
            undefined != values.valid_from &&
            null != values.valid_from &&
            "" != values.valid_from
              ? new Date(formik.values.valid_from).toISOString()
              : "",
          valid_until:
            undefined != values.valid_until &&
            null != values.valid_until &&
            "" != values.valid_until
              ? new Date(formik.values.valid_until).toISOString()
              : "",
          locations:
            values.selectedLocations.length > 0
              ? JSON.stringify(values.selectedLocations)
              : [],
          promotion_products:
            viewSelectedProducts.length > 0
              ? JSON.stringify(viewSelectedProducts)
              : [],
          promo_tier: promoTier.length > 0 ? JSON.stringify(promoTier) : [],
          promotion_sale_price:
            viewSelectedProducts.length > 0
              ? JSON.stringify(viewSelectedProducts)
              : [],
          exclude_discounts: values.exclude_discounts ? 1 : 0,
        };
        myContext.handleLoading(true);
        setButtonDisable(true);
        submit(payload, {
          method: Constants.POST,
          path: Constants.PROMOTION_CREATE_API_ROUTE,
        });
      } else {
        // addToast({
        //   alertStatus: Constants.TOAST_TYPE_WARNING,
        //   alertTitle: Constants.TOAST_HEADING_WARNING,
        //   alertDescription:
        //     "Valid until you provided has passed. Please select the new date and time or clear it.",
        // });
        formik.setFieldError(
          "valid_until",
          "Valid until you provided has passed. Please select the new date and time or clear it."
        );
      }
    },
  });

  const handleProductDetails = async (viewSelectedProducts) => {
    setLoading(true);
    let data = {
      locations: formik.values.selectedLocations,
      promotionTypes: formik.values.type,
      categories: selectedCategories,
      query: {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
      },
    };
    try {
      getProductsForSelectedLocation(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            var assignedBarcodes = response.data.data.attached_product_barcodes;
            setAssignedBarcodes(response.data.data.attached_product_barcodes);
            var selectProductFiltersName = [];
            selectProductFiltersName = response.data.data.data.data;
            if (response.data.data.data.data.length > 0) {
              response.data.data.data.data.map((custFilter) => {
                var assigned = assignedBarcodes.find(
                  (o) => o.products.barcode == custFilter.barcode
                );
                if (
                  null != assigned &&
                  "" != assigned &&
                  undefined != assigned &&
                  assigned.promotion_location.promotions_for_backend != null &&
                  assigned.promotion_location.promotions_for_backend != "" &&
                  assigned.promotion_location.promotions_for_backend !=
                    undefined
                ) {
                  custFilter.checkBoxDisabled = true;
                }
                if (
                  custFilter.sold_by_weight == 1 &&
                  Constants.SXPY != formik.values.type
                ) {
                  custFilter.checkBoxDisabled = true;
                }
                var proAdd = {
                  id: custFilter.id,
                  name: custFilter.name,
                  location_id: custFilter.location_id,
                  barcode: custFilter.barcode,
                  productbarcode: custFilter.productbarcode,
                  sku: custFilter.sku,
                  sale_price: custFilter.sale_price,
                  checkBoxDisabled: custFilter.checkBoxDisabled,
                  soldByWeight: custFilter.sold_by_weight,
                };
                function nameExists(pBarcode, array) {
                  return array.some(function (e) {
                    return e.barcode == pBarcode;
                  });
                }
                nameExists(custFilter.barcode, allProduct);
                if (
                  false ==
                  nameExists(custFilter.barcode, selectProductFiltersName)
                ) {
                  allProduct.push(proAdd);
                }
              });
            }
            setAllProduct(response.data.data.data.data);
            setTotalCount(response.data.data.totalCount);
            const cat = response.data.data?.category;
            const categoriesDropDownData = cat?.map((cat) => ({
              label: cat.name,
              value: { name: cat.name, id: cat.id },
            }));
            setCategories(categoriesDropDownData);
            var CheckedBarcodes = [];
            var CheckedProducts = [];
            if (viewSelectedProducts.length > 0) {
              viewSelectedProducts.map((dt) => {
                CheckedBarcodes.push(dt.barcode);
                CheckedProducts.push(dt);
              });
            }
            setCheckedItems(CheckedBarcodes);
            setCheckedItemsProducts(CheckedProducts);
            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setTotalCount(0);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setTotalCount(0);
      myContext.handleLoading(false);
    }
  };

  // Type BXPY Tiers

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        buy_qty_at: "",
        get_price_at: "",
        buyError: Constants.BUY_QTY_ERROR,
        priceError: Constants.PRICE_ERROR,
      },
    ]);
  };
  const handleBlur = (event, index) => {
    // if(Tiers.length > 0) {
    let arrayTime = [];
    let obj = inputs[index];
    let promoTierData = [];
    inputs.map((tiers, i) => {
      if (
        null != obj.buy_qty_at &&
        "" != obj.buy_qty_at &&
        null != obj.get_price_at &&
        "" != obj.get_price_at
      ) {
        if (
          isNaN(parseFloat(obj.buy_qty_at)) ||
          isNaN(parseFloat(obj.get_price_at))
        ) {
          obj.get_price_at = "";
          obj.buy_qty_at = "";
          obj.buyError = Constants.BUY_QTY_ERROR;
          obj.priceError = Constants.PRICE_ERROR;
        } else if (index !== i) {
          if (
            obj.buy_qty_at == tiers.buy_qty_at ||
            obj.get_price_at == tiers.get_price_at
          ) {
            obj.get_price_at = "";
            obj.buy_qty_at = "";
            obj.buyError = Constants.BUY_QTY_ERROR;
            obj.priceError = Constants.PRICE_ERROR;
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.PROMOTION_MODULE,
              alertDescription: Constants.BXPY_ERROR,
            });
          } else {
            obj.buy_qty_at = parseFloat(obj.buy_qty_at);
            if (obj.buy_qty_at > 999999) {
              obj.buyError = "Your quantity can not be greater than 999999.";
            } else if (obj.buy_qty_at <= 0) {
              obj.buyError =
                "Your quantity must be greater than or equal to 1.";
            } else if (!Number.isInteger(obj.buy_qty_at)) {
              obj.buyError = "please enter valid quantity.";
            } else {
              obj.buyError = "";
            }
            if (twofix(obj.get_price_at) > 999999.99) {
              obj.priceError =
                "Your price could not be greater than 999999.99.";
            } else if (twofix(obj.get_price_at) <= 0) {
              obj.priceError =
                "Your price must be greater than or equal to 0.01.";
            } else {
              obj.get_price_at = twofix(obj.get_price_at);
              obj.priceError = "";
            }
            arrayTime.push(tiers);
            // settiers([...onChangeValue]);
          }
        } else {
          // obj.buyError = "";
          // obj.priceError = "";
          obj.buy_qty_at = parseFloat(obj.buy_qty_at);
          if (obj.buy_qty_at > 999999) {
            obj.buyError = "Your quantity can not be greater than 999999.";
          } else if (obj.buy_qty_at <= 0) {
            obj.buyError = "Your quantity must be greater than or equal to 1.";
          } else if (!Number.isInteger(obj.buy_qty_at)) {
            obj.buyError = "please enter valid quantity.";
          } else {
            obj.buyError = "";
          }
          if (twofix(obj.get_price_at) > 999999.99) {
            obj.priceError = "Your price could not be greater than 999999.99.";
          } else if (twofix(obj.get_price_at) <= 0) {
            obj.priceError =
              "Your price must be greater than or equal to 0.01.";
          } else {
            obj.get_price_at = twofix(obj.get_price_at);
            obj.priceError = "";
          }
          arrayTime.push(tiers);
        }
      }
    });
    setTiers([...arrayTime]);
    arrayTime.map((dt) => {
      viewSelectedProducts.map((pro) => {
        var tierObj = {
          name: pro["name"],
          barcode: pro["barcode"],
          sku: pro["sku"],
          sale_price: pro["sale_price"],
          buy_qty_at: dt.buy_qty_at,
          get_price_at: parseFloat(dt.get_price_at).toFixed(2),
        };
        promoTierData.push(tierObj);
      });
    });
    setPromoTier([...promoTierData]);
  };
  const handleChange = (event, index) => {
    let { id, value } = event.target;
    let onChangeValue = [...inputs];
    onChangeValue[index][id] = value;

    if (
      (event.target.value == null || "" == event.target.value) &&
      id == "buy_qty_at"
    ) {
      onChangeValue[index]["buyError"] = Constants.BUY_QTY_ERROR;
    }
    if (
      id == "get_price_at" &&
      (event.target.value == null || "" == event.target.value)
    ) {
      onChangeValue[index]["priceError"] = Constants.PRICE_ERROR;
    }
    setInputs(onChangeValue);
  };
  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
    Tiers.splice(index, 1);
    setTiers(Tiers);

    setPromoTier([]);
    let promoTierData = [];
    newArray.map((dt) => {
      viewSelectedProducts.map((pro) => {
        var tierObj = {
          name: pro["name"],
          barcode: pro["barcode"],
          sku: pro["sku"],
          sale_price: pro["sale_price"],
          buy_qty_at: dt.buy_qty_at,
          get_price_at: parseFloat(dt.get_price_at).toFixed(2),
        };
        promoTierData.push(tierObj);
      });
    });
    setPromoTier([...promoTierData]);
  };

  let checkvalid = Tiers.findIndex((x) => {
    return x.buyError.length > 0 || x.priceError.length > 0;
  });
  let inputvalid = inputs.findIndex((x) => {
    return x.buyError.length > 0 || x.priceError.length > 0;
  });
  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    // setPage(0);
    paginationModel.page = 0;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      apply();
    }, 250);
    setTimeoutId(newTimeoutId);
  };
  const onHideDialog = () => {
    formik.resetForm();
    setSelectedCategories([]);
    setVisible(false);
    setSelectProductFromDialogError(null);
  };

  const clickIfapplyCat = (cat) => {
    setSelectedCategories(cat);
    // if (
    //   cat.length == 0 &&
    //   selectedVendors.length == 0 &&
    //   selectedLocations?.length == 0
    // ) {
    // setPage(0);
    paginationModel.page = 0;
    apply();
    // }
  };

  const apply = () => {
    effectRun.current = true;
    setLoading(true);
  };

  const {
    isOpen: isProductModalOpen,
    onOpen: onProductModalOpen,
    onClose: onProductModalClose,
  } = useDisclosure();
  const actionColumn = isMobile ? { width: 158 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const openDialog = (index) => {
    setIsDialogOpen(true);
    onProductModalOpen();
    effectRun.current = false;
    handleProductDetails(viewSelectedProducts);
  };
  const productColumnNames = [
    {
      field: "selection",
      headerName: "",
      sortable: false,
      disableExport: true,
      filterable: false,
      resizable: false,
      width: 90,
      renderCell: (params) => (
        <IconButton
          aria-label="select"
          disabled={params.row.checkBoxDisabled}
          onClick={() => handleCheckboxChange(params)}
          sx={{ color: "#5881FE" }}
        >
          <Checkbox
            disabled={params.row.checkBoxDisabled}
            checked={checkedItems.includes(params.row.barcode)}
          />
        </IconButton>
      ),
    },
    // { field: "id", headerName: "Id", sortable: true, flex: 1 },
    { field: "name", headerName: "Product name", sortable: false,  ...actionColumnFlexDouble },
    {
      field: "barcode",
      headerName: "Primary Barcode",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "sale_price",
      headerName: "Sale Price ($)",
      sortable: false,
      resizable: false,
      ...actionColumn,
    },
    {
      field: "category_name",
      headerName: "Categories",
      sortable: false,
      resizable: false,
      ...actionColumn,
    },
  ];
  function handleCheckboxChange(params) {
    let assigned = assignedBarcodes.find(
      (o) => o.products.barcode == params.row.barcode
    );
    if (
      null != assigned &&
      "" != assigned &&
      undefined != assigned &&
      assigned.promotion_location.promotions_for_backend != null &&
      assigned.promotion_location.promotions_for_backend != "" &&
      assigned.promotion_location.promotions_for_backend != undefined
    ) {
      if (
        null !=
          assigned.promotion_location.promotions_for_backend.valid_until &&
        assigned.promotion_location.promotions_for_backend.valid_until != "" &&
        (null ==
          assigned.promotion_location.promotions_for_backend.valid_from ||
          assigned.promotion_location.promotions_for_backend.valid_from == "")
      ) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.TOAST_HEADING_WARNING,
          alertDescription:
            "Product with barcode " +
            assigned.products.barcode +
            " cannot be utilised in this promotion before " +
            moment(
              new Date(
                assigned.promotion_location.promotions_for_backend.valid_until +
                  "Z"
              )
            ).format("MM/DD/YYYY HH:mm") +
            " as it is allocated to promotion id: " +
            assigned.promotion_location.promotion_id,
        });
        return false;
      } else if (
        null != assigned.promotion_location.promotions_for_backend.valid_from &&
        assigned.promotion_location.promotions_for_backend.valid_from != "" &&
        (null ==
          assigned.promotion_location.promotions_for_backend.valid_until ||
          assigned.promotion_location.promotions_for_backend.valid_until == "")
      ) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.TOAST_HEADING_WARNING,
          alertDescription:
            "Product with barcode " +
            assigned.products.barcode +
            " can only be utilised in this promotion until " +
            moment(
              new Date(
                assigned.promotion_location.promotions_for_backend.valid_from +
                  "Z"
              )
            ).format("MM/DD/YYYY HH:mm") +
            " as it is allocated to promotion id: " +
            assigned.promotion_location.promotion_id,
        });
        return false;
      } else if (
        null != assigned.promotion_location.promotions_for_backend.valid_from &&
        assigned.promotion_location.promotions_for_backend.valid_from != "" &&
        null !=
          assigned.promotion_location.promotions_for_backend.valid_until &&
        assigned.promotion_location.promotions_for_backend.valid_until != ""
      ) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.TOAST_HEADING_WARNING,
          alertDescription:
            "Product with barcode " +
            assigned.products.barcode +
            " cannot be utilised from " +
            moment(
              new Date(
                assigned.promotion_location.promotions_for_backend.valid_from +
                  "Z"
              )
            ).format("MM/DD/YYYY HH:mm") +
            " to " +
            moment(
              new Date(
                assigned.promotion_location.promotions_for_backend.valid_until +
                  "Z"
              )
            ).format("MM/DD/YYYY HH:mm") +
            " in this promotion as it is allocated to promotion id: " +
            assigned.promotion_location.promotion_id,
        });
        return false;
      } else {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.TOAST_HEADING_WARNING,
          alertDescription:
            Constants.PROMOTION_PRODUCT_ATTACHED_ERROR +
            assigned.promotion_location.promotion_id,
        });
        return false;
      }
    }
    if (params.row.soldByWeight && Constants.SXPY != formik.values.type) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.TOAST_HEADING_WARNING,
        alertDescription: Constants.PROMOTION_PRODUCT_SOLD_BY_WEIGHT_ERROR,
      });
      return false;
    }

    /* Old Code for checkbox change */
    // const rowBarcode = params.row.barcode;

    // let data = [];
    // let proData = [];
    // if (checkedItems.includes(rowBarcode)) {
    //   let index = checkedItems.findIndex((x) => x == rowBarcode);
    //   if (index > -1) {
    //     data = [
    //       ...checkedItems.slice(0, index),
    //       ...checkedItems.slice(index + 1),
    //     ];
    //     setCheckedItems(data);
    //   }
    //   let productIndex = checkedItemsProducts.findIndex(
    //     (x) => x.barcode == rowBarcode
    //   );
    //   if (productIndex > -1) {
    //     proData = [
    //       ...checkedItemsProducts.slice(0, productIndex),
    //       ...checkedItemsProducts.slice(productIndex + 1),
    //     ];
    //     setCheckedItemsProducts(proData);
    //   }
    // } else {
    //   data = [...checkedItems, rowBarcode];
    //   setCheckedItems(data);
    //   proData = [...checkedItemsProducts, params.row];
    //   setCheckedItemsProducts(proData);
    // }

    const rowBarcode = params.row.barcode;

    let data = [];
    let proData = [];
    if (checkedItems.includes(rowBarcode)) {
      let index = checkedItems.findIndex((x) => x == rowBarcode);
      if (index > -1) {
        data = [
          ...checkedItems.slice(0, index),
          ...checkedItems.slice(index + 1),
        ];
        setCheckedItems(data);
      }
      let productIndex = checkedItemsProducts.findIndex((x) => x.barcode == rowBarcode);
      if (productIndex > -1) {
        proData = [
          ...checkedItemsProducts.slice(0, productIndex),
          ...checkedItemsProducts.slice(productIndex + 1),
        ];
        setCheckedItemsProducts(proData);
      }
    } else {
      // Adding item logic - add to beginning of arrays
      data = [rowBarcode, ...checkedItems];
      setCheckedItems(data);
      proData = [params.row, ...checkedItemsProducts];
      setCheckedItemsProducts(proData);
    }
  }

  const CardRadio = ({ children, isChecked, ...props }) => {
    return (
      <Box as="label">
        <input type="radio" {...props} style={{ display: "none" }} />
        <Box
          cursor="pointer"
          borderWidth="2px"
          borderRadius="0.625rem"
          // h={"8.25rem"}
          // w={"24.125rem"}
          boxShadow="md"
          // bg={isChecked ? "teal.600" : "white"}
          color={isChecked ? "white" : "black"}
          borderColor={isChecked ? Colors.primeTaxInfo : "rgba(109, 145, 254, 0.08)"}
          _hover={{
            bg: isChecked ? "messenger" : "gray.100",
          }}
          _checked={{
            bg: "teal.600",
            color: "white",
            borderColor: "#5881FE",
          }}
          px={5}
          py={3}
        >
          {children}
        </Box>
      </Box>
    );
  };
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );
  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedLocations", e.value);

    setViewSelectedProducts([]);
    setCheckedItems([]);
    setCheckedItemsProducts([]);
    formik.setFieldValue("qty", "");

    setPromoTier([]);
    setTiers([]);
    setInputs([]);
  };
  const modifiedData = allProduct.map((data) => ({
    id: data.id,
    name: data.name,
    barcode: data.barcode,
    sale_price: data.sale_price,
    checkBoxDisabled: data.checkBoxDisabled,
    soldByWeight: data.sold_by_weight,
    category_name: data?.category_name?.replace(/,/g, ", ") || "",
  }));
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        // let s moment(e.value).set({ hour: 23, minute: 59 }).toDate();
        // formik.setFieldValue("fromDate", start_date);
        // formik.setFieldValue("toDate", end_date);
        // setFromDateView(start_date);
        // setToDateView(end_date);
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLocations(loaderResponse?.data?.data?.location);
          if(undefined != loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length == 1){
            formik.setFieldValue("selectedLocations",[loaderResponse?.data?.data?.location[0]]);
          }
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      // return () => {
      effectRun.current = false;
      // };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.PRODUCT_PROMOTION_PATH, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.PROMOTION_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
      /* else {
          let actionData = actionResponse;
          error({ actionData });
        } */
    }
  }, [actionResponse]);

  useEffect(() => {
    if (effectRun.current === true) {
      handleProductDetails(checkedItemsProducts);
      effectRun.current = false;
    }
  }, [paginationModel, sortModel, loading, searchQuery]);

  const promoTypeOptions = [
    {
      title: "Buy X Get 1 Free",
      body: "If selected, allows customers get one product free on 'X' number of items",
      value: Constants.BX1,
      // icon: <ShoppingCartIcon style={{ fill: Colors.primeTaxInfo }} />,
    },
    {
      title: "Buy X at Y Price",
      body: "If selected, allows customers get products cheaper as the quantity increases",
      value: Constants.BXPY,
      // icon: <ShoppingCartIcon style={{ fill: Colors.primeTaxInfo }} />,
    },
    {
      title: "Sale Price X on Product Y",
      body: "If selected, allows customers get products cheaper for a specific time period",
      value: Constants.SXPY,
      // icon: <ShoppingCartIcon style={{ fill: Colors.primeTaxInfo }} />,
    },
  ];

  const handleTypeChange = (value) => {
    formik.values.type = value;
    formik.setFieldTouched("type", true);
    setSelectedType(value);

    setViewSelectedProducts([]);
    setCheckedItems([]);
    setCheckedItemsProducts([]);

    formik.setFieldValue("qty", "");

    setPromoTier([]);
    setTiers([]);
    setInputs([]);
  };
  const modalClose = () => {
    setVisible(false);
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setSearchQuery("");
    onProductModalClose();
    setSelectedCategories([]);
    setCategories([]);
    setSelectProductFromDialogError(null);
  };

  const handleSelectedViewProduct = () => {
    if(checkedItemsProducts.length <= 0)
    {
      setSelectProductFromDialogError({
        message: Constants.PRODUCT_REQUIRED,
      });
      return;
    }else{
      setSelectProductFromDialogError(null);
    }
    setVisible(false);
    // onProductModalClose();
    const selectedProducts = checkedItemsProducts.filter((product) =>
      checkedItems.includes(product.barcode)
    );

    var dup_pro_array = JSON.parse(JSON.stringify(selectedProducts));
    if (dup_pro_array != undefined) {
      var slBarcodes = [];
      if (selectedProducts.length > 0) {
        selectedProducts.map((dt) => {
          slBarcodes.push(dt.barcode);
        });
      }
    }
    let proData = {
      product: dup_pro_array,
      productId: slBarcodes,
      locationsIds: formik.values.selectedLocations,
      valid_from: formik.values.valid_from,
      valid_until: formik.values.valid_until,
    };
    setButtonDisable(true);
    myContext.handleLoading(true);
    getValidUntilCreate(proData)
      .then((response) => {
        if (
          undefined !== response?.data?.flag &&
          null !== response?.data?.flag &&
          response?.data?.flag == true
        ) {
          var assignedValidUntil = [];
          if (response?.data?.data?.length > 0) {
            response.data.data.map((element) => {
              function nameExists(valid, array) {
                return array.some(function (e) {
                  return e.validUntil == valid;
                });
              }
              if (null != element.valid_until && "" != element.valid_until) {
                var vUn = moment(new Date(element.valid_until + "Z")).format(
                  "MM/DD/YYYY HH:mm"
                );
              }
              if (null != element.valid_from && "" != element.valid_from) {
                var vFr = moment(new Date(element.valid_from + "Z")).format(
                  "MM/DD/YYYY HH:mm"
                );
              }
              if (false == nameExists(vUn, assignedValidUntil)) {
                var proAll = {
                  validUntil: vUn,
                  validFrom: vFr,
                  productBarcode: element["barcode"],
                  promo_id: element.promo_id,
                  current_promo_valid_until: element.current_promo_valid_until,
                };
                assignedValidUntil.push(proAll);
              }
            });
            if (
              null != assignedValidUntil[0].validUntil &&
              assignedValidUntil[0].validUntil != "" &&
              (null == assignedValidUntil[0].validFrom ||
                assignedValidUntil[0].validFrom == "")
            ) {
              addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.TOAST_HEADING_WARNING,
                alertDescription:
                  "Product with barcode " +
                  assignedValidUntil[0].productBarcode +
                  " cannot be utilised in this promotion before " +
                  assignedValidUntil[0].validUntil +
                  " as it is allocated to promotion id: " +
                  assignedValidUntil[0].promo_id,
              });
            } else if (
              null != assignedValidUntil[0].validFrom &&
              assignedValidUntil[0].validFrom != "" &&
              (null == assignedValidUntil[0].validUntil ||
                assignedValidUntil[0].validUntil == "")
            ) {
              addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.TOAST_HEADING_WARNING,
                alertDescription:
                  "Product with barcode " +
                  assignedValidUntil[0].productBarcode +
                  " can only be utilised in this promotion until " +
                  assignedValidUntil[0].validFrom +
                  " as it is allocated to promotion id: " +
                  assignedValidUntil[0].promo_id,
              });
            } else if (
              null != assignedValidUntil[0].validFrom &&
              assignedValidUntil[0].validFrom != "" &&
              null != assignedValidUntil[0].validUntil &&
              assignedValidUntil[0].validUntil != ""
            ) {
              addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.TOAST_HEADING_WARNING,
                alertDescription:
                  "Product with barcode " +
                  assignedValidUntil[0].productBarcode +
                  " cannot be utilised from " +
                  assignedValidUntil[0].validFrom +
                  " to " +
                  assignedValidUntil[0].validUntil +
                  " in this promotion as it is allocated to promotion id: " +
                  assignedValidUntil[0].promo_id,
              });
            } else {
              addToast({
                alertStatus: Constants.TOAST_TYPE_WARNING,
                alertTitle: Constants.TOKEN_EXPIRTED,
                alertDescription:
                  "Product with barcode " +
                  assignedValidUntil[0].productBarcode +
                  " cannot be utilised in this promotion as it is allocated to promotion id: " +
                  assignedValidUntil[0].promo_id,
              });
            }
            setButtonDisable(false);
            myContext.handleLoading(false);
          } else {
            if (
              formik.values.type == Constants.BX1 &&
              checkedItemsProducts.length > 0
            ) {
              const filteredColumnsArray = columnProductViewNames.filter(
                (dt) => {
                  return dt.columnNames != "Promotion Sale Price ($)";
                }
              );
              setColumnProductViewNames(filteredColumnsArray);
              // setViewSelectedProducts(checkedItemsProducts);
              const existingBarcodes = new Set(viewSelectedProducts.map(p => p.barcode));
              const newProducts = checkedItemsProducts.filter(product => !existingBarcodes.has(product.barcode));
              const existingProducts = checkedItemsProducts.filter(product => existingBarcodes.has(product.barcode));
              setViewSelectedProducts([...newProducts, ...existingProducts]);
            } else if (
              formik.values.type == Constants.BXPY &&
              checkedItemsProducts.length > 0
            ) {
              const filteredColumnsArray = columnProductViewNames.filter(
                (dt) => {
                  return dt.columnNames != "Promotion Sale Price ($)";
                }
              );
              setColumnProductViewNames(filteredColumnsArray);
              if (undefined == inputs || inputs.length <= 0) {
                setInputs([
                  {
                    buy_qty_at: "",
                    get_price_at: "",
                    buyError: Constants.BUY_QTY_ERROR,
                    priceError: Constants.PRICE_ERROR,
                  },
                ]);
              } else {
                setPromoTier([]);
                let promoTierData = [];
                inputs.map((dt) => {
                  checkedItemsProducts.map((pro) => {
                    var tierObj = {
                      name: pro["name"],
                      barcode: pro["barcode"],
                      sku: pro["sku"],
                      sale_price: pro["sale_price"],
                      buy_qty_at: dt.buy_qty_at,
                      get_price_at: parseFloat(dt.get_price_at).toFixed(2),
                    };
                    promoTierData.push(tierObj);
                  });
                });
                setPromoTier([...promoTierData]);
              }
              // setViewSelectedProducts(checkedItemsProducts);
              const existingBarcodes = new Set(viewSelectedProducts.map(p => p.barcode));
              const newProducts = checkedItemsProducts.filter(product => !existingBarcodes.has(product.barcode));
              const existingProducts = checkedItemsProducts.filter(product => existingBarcodes.has(product.barcode));
              setViewSelectedProducts([...newProducts, ...existingProducts]);
            } else if (
              formik.values.type == Constants.SXPY &&
              checkedItemsProducts.length > 0
            ) {
              if (columnProductViewNames.length < 6) {
                var newPromoSalePriceColumn = {
                  columnNames: "Promotion Sale Price ($)",
                  sortName: "",
                  width: "20%",
                };
                columnProductViewNames.splice(4, 0, newPromoSalePriceColumn);
              }
              setColumnProductViewNames(columnProductViewNames);
              // checkedItemsProducts.map((dt, i) => {
              //   if (
              //     undefined != viewSelectedProducts &&
              //     viewSelectedProducts.length > 0
              //   ) {
              //     let matchFound = false; // Flag to track if a match is found
              //     checkedItemsProducts.some((pro, index) => {
              //       // Use Array.some for early termination
              //       if (
              //         pro.barcode == dt.barcode &&
              //         undefined != pro.promo_sale_price &&
              //         null != pro.promo_sale_price &&
              //         ("" != undefined) != pro.promo_sale_price
              //       ) {
              //         dt.promo_sale_price = pro.promo_sale_price;
              //         dt.promoPriceError = pro.promoPriceError;
              //         matchFound = true; // Set the flag to true if match is found
              //         return true; // Stop the inner loop
              //       } else {
              //         return false; // Continue the inner loop
              //       }
              //     });
              //     if (!matchFound) {
              //       // Check if a match was not found
              //       dt.promo_sale_price = "";
              //       dt.promoPriceError = Constants.PRICE_ERROR;
              //     }
              //   } else {
              //     dt.promo_sale_price = "";
              //     dt.promoPriceError = Constants.PRICE_ERROR;
              //   }
              // });
              // setViewSelectedProducts(checkedItemsProducts);
              const existingProductsMap = viewSelectedProducts.reduce((acc, product) => {
                acc[product.barcode] = {
                  promo_sale_price: product.promo_sale_price,
                  promoPriceError: product.promoPriceError
                };
                return acc;
              }, {});
              
              // Map through checked items and preserve existing data
              // const updatedProducts = checkedItemsProducts.map(product => {
              //   // If product exists in viewSelectedProducts, keep its existing promo price data
              //   if (existingProductsMap[product.barcode]) {
              //     return {
              //       ...product,
              //       promo_sale_price: existingProductsMap[product.barcode].promo_sale_price,
              //       promoPriceError: existingProductsMap[product.barcode].promoPriceError
              //     };
              //   }
                
              //   // For new products, initialize with empty price and error
              //   return {
              //     ...product,
              //     promo_sale_price: "",
              //     promoPriceError: Constants.PRICE_ERROR
              //   };
              // });
              // setViewSelectedProducts(updatedProducts);

              const existingBarcodes = new Set(viewSelectedProducts.map(p => p.barcode));
              const newProducts = checkedItemsProducts.filter(product => !existingBarcodes.has(product.barcode))
                .map(product => ({
                  ...product,
                  promo_sale_price: "",
                  promoPriceError: Constants.PRICE_ERROR
                }));

              const existingProducts = checkedItemsProducts.filter(product => existingBarcodes.has(product.barcode))
                .map(product => ({
                  ...product,
                  promo_sale_price: existingProductsMap[product.barcode].promo_sale_price,
                  promoPriceError: existingProductsMap[product.barcode].promoPriceError
                }));

              setViewSelectedProducts([...newProducts, ...existingProducts]);
              // setPromotionSalePrice(selectedProducts);
            }
            myContext.handleLoading(false);
            setButtonDisable(false);
            // onProductModalClose();
            modalClose();
          }
        } else {
          myContext.handleLoading(false);
          setButtonDisable(false);
          let actionData = response;
          error({ actionData });
        }
      })
      .catch((err) => {
        setButtonDisable(false);
        myContext.handleLoading(false);
        let actionData = err;
        error({ actionData });
      });
  };
  const handleSearchList = () => {
    paginationModel.page = 0;
    handleProductDetails(checkedItemsProducts);
  };
  const handleClear = () => {
    // clear input value and get all data
    setSearchQuery("");
    effectRun.current = true;
  };
  const { getRootProps: getTypeRootProps, getRadioProps: getTypeRadioProps } =
    useRadioGroup({
      name: "value",
      defaultValue: "",
      onChange: handleTypeChange,
    });
  const promoTypeGroup = getTypeRootProps();
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.name.length <= 0 ||
      formik.errors.name ||
      formik.errors.type ||
      formik.values.selectedLocations?.length <= 0 ||
      !formik.valid ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };

  // type 3 promotion price change

  const handlePromotionPriceChange = (event, index) => {
    let { id, value } = event.target;
    let onChangeValue = [...viewSelectedProducts];
    onChangeValue[index][id] = value;
    let promoSalePrice = [];
    if (id == "promo_sale_price") {
      viewSelectedProducts.map((tiers, i) => {
        if (
          null != tiers.promo_sale_price &&
          "" != tiers.promo_sale_price &&
          undefined != tiers.promo_sale_price
        ) {
          if (tiers.promo_sale_price <= 0) {
            tiers.promoPriceError =
              "Your sale price must be greater than 0.01.";
          } else if (tiers.promo_sale_price > 999999.99) {
            tiers.promoPriceError =
              "Your sale price not be greater than 999999.99.";
          } else {
            tiers.promoPriceError = "";
          }
        } else {
          tiers.promoPriceError = Constants.PRICE_ERROR;
        }
        promoSalePrice.push(tiers);
      });
    }
    setViewSelectedProducts(promoSalePrice);
    // setPromotionSalePrice(promoSalePrice);
  };
  const handlePromotionPriceBlur = (event, index) => {
    let dt = [];
    viewSelectedProducts.map((tiers, i) => {
      const promoSalePrice = parseFloat(tiers.promo_sale_price);
      if (!isNaN(promoSalePrice)) {
        tiers.promo_sale_price = twofix(tiers.promo_sale_price);
        if(tiers.promo_sale_price <= 0){
          dt.push({ ...tiers, promo_sale_price: '', promoPriceError: Constants.PRICE_ERROR});
        }else{
          dt.push(tiers);
        }
      } else {
        dt.push({
          ...tiers,
          promo_sale_price: "",
          promoPriceError: Constants.PRICE_ERROR,
        });
      }
    });
    setViewSelectedProducts(dt);
  };
  let inputvalidPromotionPrice = viewSelectedProducts.findIndex((x) => {
    if (undefined != x.promoPriceError) {
      return x.promoPriceError.length > 0 || x.promoPriceError.length > 0;
    }
  });

  let counter = 1;
  const modifiedViewData = viewSelectedProducts.map((products, index) =>
    formik.values.type == Constants.SXPY
      ? {
          sr_no: counter++,
          name: products.name,
          id: products.barcode,
          sale_price: products.sale_price,
          promo_sale_price: (
            <>
              {/* <PosInput
                id="promo_sale_price"
                placeholder={"Enter Promotion Sale Price"}
                inputValue={products.promo_sale_price || ''}
                handleInputChange={(e) => {
                  handlePromotionPriceChange(e, index);
                }}
                handleBlur={(e) => {
                  handlePromotionPriceBlur(e, index);
                }}
                posNumberInput={true}
                inputType={"text"}
                inputError={products?.promoPriceError}
              /> */}
              <PosInput
                id="promo_sale_price"
                posDecimalNumberInput={true}  
                inputValue={products.promo_sale_price || ''}
                handleBlur={(e) => {
                  viewSelectedProducts[index].promo_sale_price = e.target.value;
                  handlePromotionPriceChange(e, index);
                }}
                handleInputChange={(e) => {
                  viewSelectedProducts[index].promo_sale_price = e.target.value;
                  handlePromotionPriceBlur(e, index);
                }}
                inputError={products?.promoPriceError}
                onKeyPress={(e) => {
                  if (e.key === "-") {
                    e.preventDefault();
                  }
                }}
              />
              <Text mt={1} color={Colors.errorColor}>
                {(undefined != products?.promoPriceError &&
                  null != products?.promoPriceError &&
                  "" != products?.promoPriceError) ||
                undefined == products.promo_sale_price ||
                products.promo_sale_price == null ||
                "" == products.promo_sale_price ? (
                  <span>{products?.promoPriceError}</span>
                ) : null}
              </Text>
            </>
          ),
        }
      : {
          sr_no: counter++,
          name: products.name,
          id: products.barcode,
          sale_price: products.sale_price,
        }
  );

  const handleSingleDelete = (barcode) => {
    const updatedCheckedItems = checkedItems.filter(
      (itemId) => itemId != barcode
    );
    setCheckedItems(updatedCheckedItems);
    const updatedCheckedItemsProducts = checkedItemsProducts.filter(
      (itemId) => itemId.barcode != barcode
    );
    setCheckedItemsProducts(updatedCheckedItemsProducts);
    if (viewSelectedProducts.length > 0) {
      viewSelectedProducts.map((dt, i) => {
        if (dt.barcode == barcode) {
          viewSelectedProducts.splice(i, 1);
        }
      });
    }
    setViewSelectedProducts(viewSelectedProducts); // handleSelectedViewProduct();

    if (promoTier.length > 0) {
      promoTier.map((dt, i) => {
        if (dt.barcode == barcode) {
          promoTier.splice(i, 1);
        }
      });
    }

    // if(promotionSalePrice.length > 0){
    //   promotionSalePrice.map((dt, i) => {
    //     if (dt.barcode == barcode) {
    //       promotionSalePrice.splice(i, 1);
    //     }
    //   });
    // }

    if (viewSelectedProducts.length == 0) {
      setInputs([]);
      setTiers([]);
      setPromoTier([]);

      // setPromotionSalePrice([]);
    }
    if (updatedCheckedItemsProducts.length == 0) {
      setSelectedProductError({
        message: Constants.PRODUCT_ADD_REQUIRED,
      });
    }
  };

  const smoothScrollToElement = (element) => {
    const offset = 200; // Adjust this value to control the final position
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });

    // Add a small delay before focusing to ensure scroll is complete
    setTimeout(() => {
      element.focus();
    }, 450);
  };

  const handleFormSubmission = () => {
    formik.setTouched({
      name: true,
      type: true,
      valid_from: true,
      valid_until: true,
      qty: formik.values.type == Constants.BX1 ? true : false,
      selectedLocations: true,
    }, true);
  
    // Check errors in order of appearance on the page
    if (formik.errors.name) {
      const nameInput = document.getElementById('name');
      if(nameInput){
        smoothScrollToElement(nameInput);
      }
      return;
    }
  
    if (formik.errors.type) {
      const typeContainer = document.querySelector('[role="radiogroup"]');
      if(typeContainer){
        smoothScrollToElement(typeContainer);
      }
      return;
    }
  
    if (formik.errors.valid_from) {
      const calendarRef = document.getElementById('valid_from');
      if(calendarRef){
        smoothScrollToElement(calendarRef);
      }
      return;
    }
  
    if (formik.errors.valid_until) {
      const toDateCalendarRef = document.getElementById('valid_until');
      if(toDateCalendarRef){
        if(formik.errors.valid_until){
          formik.setFieldTouched('valid_until', true);
          formik.setFieldError('valid_until', formik.errors.valid_until);
        }
        smoothScrollToElement(toDateCalendarRef);
      }
      return;
    }
  
    if (formik.values.type == Constants.BX1 && formik.errors.qty) {
      const qtyInput = document.getElementById('qty');
      if(qtyInput){
        smoothScrollToElement(qtyInput);
      }
      return;
    }
  
    // Check for product selection errors
    if (viewSelectedProducts == null || "" == viewSelectedProducts ||
      viewSelectedProducts.length <= 0 
      // ||
      // (formik.values.type == Constants.BXPY &&
      // (checkvalid > -1 || inputvalid > -1)) 
      // ||
      // (formik.values.type == Constants.SXPY &&
      // inputvalidPromotionPrice > -1)
      ){
      setSelectedProductError({
        message: Constants.PRODUCT_ADD_REQUIRED,
      });
      // Scroll to the Add Products button using the data-testid
      const addProductsButton = document.querySelector('[data-testid="add-products-button"]');
      if(addProductsButton){
        smoothScrollToElement(addProductsButton);
      }
      return;
    }

    if(formik.values.type == Constants.SXPY && viewSelectedProducts.length > 0) {
      // Find first product with invalid promotion price
      const firstInvalidProduct = viewSelectedProducts.findIndex(product => 
        !product.promo_sale_price || 
        product.promo_sale_price === "" || 
        product.promoPriceError
      );
      if (firstInvalidProduct !== -1) {
        // Get all promotion price inputs
        const allInputs = document.querySelectorAll('#promo_sale_price');
        
        // Get the input at the invalid index
        const inputElement = allInputs[firstInvalidProduct];

        if (inputElement) {
          smoothScrollToElement(inputElement);
        }
        return;
      }
    }
    if(formik.values.type == Constants.BXPY && viewSelectedProducts.length > 0) {      
      // Find first input with error
      const firstErrorIndex = inputs.findIndex(input => 
        input.buyError || 
        input.priceError || 
        !input.buy_qty_at || 
        !input.get_price_at
      );    
      if (firstErrorIndex !== -1) {
        // Get all buy_qty_at and get_price_at inputs
        const buyQtyInputs = document.querySelectorAll('#buy_qty_at');
        const priceInputs = document.querySelectorAll('#get_price_at');
        
        // Determine which input has the error and get the corresponding element
        const inputToFocus = inputs[firstErrorIndex].buyError ? 
          buyQtyInputs[firstErrorIndex] : 
          priceInputs[firstErrorIndex];
        
        if (inputToFocus) {
          smoothScrollToElement(inputToFocus);
        }
        return;
      }
    }
    formik.handleSubmit();
  };

  const handleCheckedItemsChange = (checkedItems) => {
    setCheckedItems(checkedItems);
  };
  const CustomTooltip = () => (
    <div>
      <p>
        Choose one of the Three different types of promotion, 1st lets you give
        1 product free on X number of them, 2nd Lets you set a tier to sell
        products cheaper as the quantity increases. 3rd one is a reduced price
        for a specific time period.
      </p>
    </div>
  );
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" w={isMobile ? "100vw" : "calc(100vw - 263.2px)"}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              // pass param data in tax list page
              props.navigate(Constants.PRODUCT_PROMOTION_PATH, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Promotions", "Create Promotion"]}
            breadCrumTitle={"Create Promotion"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                // pass param data in tax list page
                props.navigate(Constants.PRODUCT_PROMOTION_PATH, {
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              // isDisabled={
              //   formik.values.name.length <= 0 ||
              //   formik.errors.name ||
              //   formik.errors.type ||
              //   formik.errors.valid_until ||
              //   formik.errors.valid_from ||
              //   formik.values.selectedLocations?.length <= 0 ||
              //   (formik.values.type == Constants.BX1 &&
              //     (formik.values.qty == null ||
              //       "" == formik.values.qty ||
              //       formik.errors.qty)) ||
              //   viewSelectedProducts == null ||
              //   "" == viewSelectedProducts ||
              //   viewSelectedProducts.length <= 0 ||
              //   (formik.values.type == Constants.BXPY &&
              //     (checkvalid > -1 || inputvalid > -1)) ||
              //   (formik.values.type == Constants.SXPY &&
              //     inputvalidPromotionPrice > -1) ||
              //   buttonDisable
              //     ? true
              //     : false
              // }
              isDisabled={buttonDisable}
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={() => {
                handleFormSubmission();
              }}
              // onClick={formik.handleSubmit}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
          p="8"
        >
          <Text
            fontSize="1.125rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Basic Details
          </Text>
          <Stack spacing={0}>
            <PosLable
              fontWeight={"500"}
              requiredLabel={true}
              name={"Promotions Name"}
            />
            <PosInput
              id="name"
              placeholder={"Enter Promotion Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.name}
              handleBlur={(e) => {
                formik.setFieldValue("name", formik.values.name.trim());
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInput={true}
              inputType={"text"}
              inputError={formik.touched.name && formik.errors.name}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.name && formik.errors.name ? (
                <span>{formik.errors.name}</span>
              ) : null}
            </Text>
          </Stack>
          <Flex flexDirection={"row"}>
            <PosLable
              fontWeight={"500"}
              requiredLabel={true}
              name="Promotion Type"
            />
            <Tippy
              content={<CustomTooltip />}
              interactive={true}
              maxWidth={390}
              placement="top"
              animation="fade"
              inertia={true}
              moveTransition="transform 0.2s ease-out"
              theme="tomato"
            >
              {/* <toolTip style={{ fill: "#5881FE",marginTop:"2rem" }} /> */}
              <Image src={toolTip} alt="Tooltip" mt={"2rem"} ml={"0.3rem"} />
            </Tippy>
          </Flex>
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
            // mt={4}
            // spacing={"8.13rem"}
            mb={1}
            gap={4}
            {...promoTypeGroup}
          >
            {promoTypeOptions.map((option, index) => {
              const radio = getTypeRadioProps({
                value: option.value,
              });
              return (
                <CardRadio
                  key={index}
                  {...radio}
                  isChecked={type === option.value}
                >
                  <Flex flexDirection={"row"} alignItems="center" gap={2}>
                    {/* {option.icon} */}
                    <Text
                      fontWeight="bold"
                      color={Colors.posTextInfo}
                      textAlign="center"
                    >
                      {option.title}
                    </Text>
                  </Flex>
                  <Text
                    mt={"0.63rem"}
                    style={{
                      fontStyle: "italic",
                      fontWeight: "300",
                    }}
                    color={Colors.posViewTextColor}
                  >
                    {option.body}
                  </Text>
                </CardRadio>
              );
            })}
          </Flex>
          {formik.touched.type && formik.errors.type && (
            <Text color={Colors.posCancleButtonRed}>
              {formik.errors.type}
            </Text>
          )}
          <Divider borderWidth={"0.8px"} borderColor="#E6E6E6" mt={4} mb={5} />
          <PosLable fontWeight={"500"} label={true} name="Promotion Duration" />
          <Wrap
            alignItems={{ base: "stretch", md: "center" }}
            spacing={4}
            mt={2}
          >
            <WrapItem flexDirection={"column"}>
              <Flex flexDirection={"row"}>
                <Text fontSize={"0.94rem"}>Valid From</Text>
                <Tippy
                  content="The promotion will start in effect from this date."
                  interactive={true}
                  maxWidth={340}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  {/* <toolTip style={{ fill: "#5881FE"}} /> */}
                  <Image src={toolTip} alt="Tooltip" ml={"0.3rem"} />
                </Tippy>
              </Flex>
              <PosDatePicker
                id={"valid_from"}
                ref={calendarRef}
                value={formik.values.valid_from}
                label={"Select Valid From"}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("valid_from", true);
                }}
                handleSelectData={(e) => {
                  formik.setFieldValue("valid_from", e.value);
                  setFromDateView(e.value);
                  if (null != e.value && "" != e.value) {
                    if (
                      null != formik.values.valid_until &&
                      "" != formik.values.valid_until &&
                      new Date(formik.values.valid_until) < new Date(e.value)
                    ) {
                      formik.setFieldValue("valid_until", e.value);
                    }
                  }
                }}
                minDate={dateTimeValue}
                yearRange={"2000:2030"}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.valid_from && formik.errors.valid_from ? (
                  <span>{formik.errors.valid_from}</span>
                ) : null}
              </Text>
            </WrapItem>
            <WrapItem flexDirection={"column"}>
              <Flex flexDirection={"row"}>
                <Text fontSize={"0.94rem"}>Valid Until</Text>
                <Tippy
                  content="The promotion will be valid and in effect till this date."
                  interactive={true}
                  maxWidth={340}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  {/* <toolTip style={{ fill: "#5881FE"}} /> */}
                  <Image src={toolTip} alt="Tooltip" ml={"0.3rem"} />
                </Tippy>
              </Flex>
              <PosDatePicker
                id={"valid_until"}
                ref={toDateCalendarRef}
                value={formik.values.valid_until}
                label={"Select Valid Until"}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 100);
                  formik.setFieldTouched("valid_until", true);
                }}
                handleSelectData={(e) => {
                  formik.setFieldValue("valid_until", e.value);
                  setToDateView(e.value);
                  if (null != e.value && "" != e.value) {
                    if (
                      null != formik.values.valid_from &&
                      "" != formik.values.valid_from &&
                      new Date(formik.values.valid_from) > new Date(e.value)
                    ) {
                      formik.setFieldValue("valid_until", "");
                    }
                  }
                }}
                yearRange={"2000:2030"}
                minDate={
                  null !== formik.values.valid_from &&
                  "" !== formik.values.valid_from
                    ? formik.values.valid_from
                    : dateTimeValue
                }
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.valid_until && formik.errors.valid_until ? (
                  <span>{formik.errors.valid_until}</span>
                ) : null}
              </Text>
            </WrapItem>
          </Wrap>
          <PosLable
            fontWeight={"500"}
            requiredLabel={true}
            name="Select Locations"
          />
          <PosDropDown
            options={locations}
            value={formik.values.selectedLocations}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLocations");
              formik.handleBlur(e);
            }}
            onHide={() => {
              formik.setFieldTouched("selectedLocations");
            }}
            multiSelect={true}
            optionLabel="name"
            placeholder="Select Locations"
            width={{ base: "100%", md: "60%" }}
            height={"2.5rem"}
            className="w-full md:w-20rem"
            maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLocations &&
            formik.errors.selectedLocations ? (
              <span>{formik.errors.selectedLocations}</span>
            ) : null}
          </Text>
          <Stack spacing={2}>
            <Flex flexDirection={"row"} alignItems={"center"} gap={2}> {/* Added alignItems and gap */}
              <PosLable fontWeight={"500"} label={true} name={"Exclude from Additional Discount"} />
              <Stack
                direction={"row"}
                flexShrink={"0"}
                mt={"2rem"}
              >
                <PosSwitchButton
                  id="exclude_discounts"
                  switchValue={formik.values.exclude_discounts}
                  onChange={(e) => {
                    formik.setFieldValue('exclude_discounts', e.target.checked);
                  }}
                />
              </Stack>
            </Flex>
            <Text
              mt={"0.63rem"}
              style={{
                fontStyle: "italic",
                fontWeight: "300",
              }}
              color={Colors.posViewTextColor}
            >
              If yes, additional item discounts will cannot be applied on items that are eligible as part of this promotions. Also, percentage order discounts will exclude this items.
            </Text>
          </Stack>
        </Box>
        <Box
          mt={"0.87rem"}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
          p="8"
        >
          <Text
            fontSize="1.125rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Promotion Details
          </Text>
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
            // pt={{ base: "0.8rem", md: "0.8rem" }}
            pb={{ base: "1.5rem", md: "1.5rem" }}
            // pl={{ base: "1rem", lg: "1.5rem" }}
            // pr={{ base: "0.5rem", md: "1.56rem" }}
            // gap={1}
          >
            {formik.values.type == Constants.BX1 && (
              <Stack spacing={0} w={{ base: "100%", md: "25%" }}>
                <Flex flexDirection={"row"}>
                  <PosLable
                    fontWeight={500}
                    requiredLabel={true}
                    name={"Buy Quantity"}
                  />
                  <Tippy
                    content="Number of quantities to be purchased to get 1 free."
                    interactive={true}
                    maxWidth={300}
                    placement="top"
                    animation="fade"
                    inertia={true}
                    moveTransition="transform 0.2s ease-out"
                    theme="tomato"
                  >
                    <Image
                      src={toolTip}
                      alt="Tooltip"
                      mt={"2rem"}
                      ml={"0.3rem"}
                    />
                  </Tippy>
                </Flex>
                <PosInput
                  id="qty"
                  placeholder={"Enter Quantity"}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.qty}
                  handleBlur={(e) => {
                    formik.handleBlur(e);
                    formik.setFieldValue("qty", formik.values.qty);
                  }}
                  posNumberInput={true}
                  onKeyDown={handleKeyDown}
                  inputType={"text"}
                  inputError={formik.touched.qty && formik.errors.qty}
                />
                <Text color={Colors.errorColor}>
                  {formik.touched.qty && formik.errors.qty ? (
                    <span>{formik.errors.qty}</span>
                  ) : null}
                </Text>
              </Stack>
            )}
            <Stack mt={"2rem"}>
            <Flex flexDirection="column" alignItems={formik.values.type && formik.values.type == Constants.BX1 ? "flex-end" : "flex-start"}>
              <Button
                bg={Colors.posNavbarLink}
                size={{ base: "sm", md: "md" }}
                data-testid="add-products-button"  // Add this data attribute
                style={{
                  display: "flex",
                  padding: "0.63rem",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.63rem",
                  borderRadius: "0.31rem",
                  border: "1px solid #E3E3E3",
                  boxShadow: "0px 0.25rem 0.25rem 0px #e0e0e01a",
                  backgroundColor: Colors.posNavbarLink,
                }}
                onClick={() => {
                  if(formik.values.selectedLocations?.length <= 0 || (undefined == formik.values.type || formik.values.type == "" || null == formik.values.type)){
                      formik.setTouched({
                        type: true,
                        selectedLocations: true,
                      }, true);
                      return;
                  }else{
                    setVisible(true);
                    openDialog();
                  }
                }}
                isDisabled={buttonDisable}
              >
                Add Products
              </Button>
              {selectedProductError && viewSelectedProducts?.length <= 0 ? (
                  <Text color={Colors.errorColor} alignSelf={"flex-end"}>
                    {selectedProductError.message}
                  </Text>
                ) : null}
                </Flex>
            </Stack>
          </Flex>
          {viewSelectedProducts.length > 0 && (
            <>
              <Text
                fontSize="1.125rem"
                w="100%"
                mb={3}
                color={Colors.posImageIconColor}
                style={{
                  fontStyle: "normal",
                  fontWeight: 300,
                  lineHeight: "normal",
                  letterSpacing: "-0.27px",
                }}
              >
                Location(s):{" "}
                {formik.values.selectedLocations.map((loc, index) => (
                  <React.Fragment key={loc.id}>
                    {loc.name}
                    {index !== formik.values.selectedLocations.length - 1
                      ? ", "
                      : ""}
                  </React.Fragment>
                ))}
              </Text>
            </>
          )}
          <Divider borderWidth={"0.8px"} borderColor="#E6E6E6" mb={2} />
          {/* <ThemeProvider theme={Mtheme}>
            <Dialog
              open={isProductModalOpen}
              // onClose={onProductModalClose}
              fullWidth={true}
              maxWidth={"lg"}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                sx={{
                  marginTop: "2.37rem",
                  fontSize: "1.5rem",
                  fontWeight: 600,
                  color: Colors.posTextInfo,
                }}
              >
                <ChakraProvider theme={theme}>
                <Flex
                minWidth="max-content"
                gap="2"
                mt={2}
                mb={2}
                alignItems="center"
                flexDirection={{ base: "column", md: "row" }}
              >
                <Flex alignSelf={"flex-start"}>
                Add Products
                </Flex>
                <Spacer  />
                <ButtonGroup alignSelf={"flex-end"}>
                  <PosFormButton
                    onClick={modalClose}
                    buttonText={"Cancel"}
                    CancelButton={true}
                    isDisabled={buttonDisable}
                  />
                  <PosFormButton
                    isDisabled={checkedItems.length <= 0 ? true : false}
                    buttonsubmit={"Add Products"}
                    SubmitButton={true}
                    onClick={handleSelectedViewProduct}
                  />
                </ButtonGroup>
                </Flex>
              </ChakraProvider>
                <ChakraProvider theme={theme}>
                  <Box mt={3.5}>
                  <Flex
                  minWidth="max-content"
                  gap="2"
                  mt={2}
                  mb={2}
                  alignItems="center"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <PosSearch
                    value={searchQuery}
                    onChange={(e) => {
                      let value =searchQuery.length > 0 ? 
                      e.target.value: e.target.value.trim();
                      setSearchQuery(value);
                    }}
                    onSearch={handleSearchList}
                    handleClear={handleClear} 
                  />
                </Flex>
                  </Box>
                </ChakraProvider>
              </DialogTitle>
              <DialogContent>
                {loading ? (
                  <PosProgress />
                ) : totalcount > 0 ? (
                  <PosDataGridTable
                    columns={productColumnNames}
                    rows={modifiedData}
                    totalcount={totalcount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      setSortModel(newSortModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.PROMO_PROD_EXPORT_FILENAME}
                  />
                ) : (
                  <PosNoDataFound title={Constants.NO_DATA_FOUND} />
                )}
              </DialogContent>
            </Dialog>
          </ThemeProvider> */}
          <Dialog
            header={<div style={{ padding: 0, margin: 0 }}> </div>}
            visible={visible}
            onHide={onHideDialog}
            style={{ width: isMobileDialog ? "80%" : "70%" }}
            closable={false}
            closeOnEscape={false}
          >
            <ChakraProvider theme={theme}>
              <Box
                  position="sticky"
                  top="0"
                  zIndex="1000"
                  backgroundColor="white"
                  pb={2}
                >
                  <Flex
                  minWidth="max-content"
                  gap="2"
                  mt={4}
                  mb={4}
                  alignItems="center"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Flex
                  alignSelf="flex-start"
                  fontSize="1.5rem"
                  fontWeight={600}
                  color="rgb(1, 0, 72)"
                  >
                  Add Products
                </Flex>
                <Spacer />
                <Flex flexDirection="column" alignItems="flex-end">
                <ButtonGroup alignSelf={"flex-end"}>
                  <PosFormButton
                    onClick={modalClose}
                    buttonText={"Cancel"}
                    CancelButton={true}
                    isDisabled={buttonDisable}
                  />
                  <PosFormButton
                    // isDisabled={checkedItems.length <= 0 ? true : false}
                    isDisabled={buttonDisable}
                    buttonsubmit={"Add Products"}
                    SubmitButton={true}
                    onClick={handleSelectedViewProduct}
                  />
                </ButtonGroup>
                {/* {selectProductFromDialogError && (checkedItems?.length <= 0 ||
                  (checkedItems?.length == 1 && checkedItems[0] == "")) && (
                  <Text mx={{ base: 0, md: 4 }} color={Colors.errorColor} alignSelf={"flex-end"}>
                    {selectProductFromDialogError.message}
                  </Text>
                )} */}
                </Flex>
                </Flex>
                <Flex
                  // minWidth="max-content"
                  gap="2"
                  mt={4}
                  mb={4}
                  // alignItems="center"
                  flexDirection={{ base: "column", sm: "column", md: "row" }}
                >
                  <PosSearch
                  value={searchQuery}
                  // onChange={(e) => {
                  //   let value =searchQuery.length > 0 ? 
                  //   e.target.value: e.target.value.trim();
                  //   setSearchQuery(value);
                  // }}
                  onChange={onChange}
                  onSearch={handleSearchList}
                  handleClear={handleClear} 
                  />
                  <PosDropDown
                    options={categories}
                    value={selectedCategories}
                    onChange={(e) => {
                      clickIfapplyCat(e.value);
                    }}
                    // onChange={(e) => setSelectedCategories(e.value)}
                    multiSelect={true}
                    optionLabel="label"
                    placeholder="Categories"
                    lableAvailable={true}
                    width="100%"
                    height="2.5rem"
                    className="w-full md:w-20rem"
                    maxSelectedLabels={2}
                  />
                </Flex>
                </Box>
                {selectProductFromDialogError && (checkedItems?.length <= 0 ||
                  (checkedItems?.length == 1 && checkedItems[0] == "")) && (
                  <Text mb={2} color={Colors.errorColor} alignSelf={"flex-end"}>
                    {selectProductFromDialogError.message}
                  </Text>
                )}
            </ChakraProvider>
            {loading ? (
                  <PosProgress />
                ) : totalcount > 0 ? (
                  <Box maxHeight={"32rem"} overflow={"auto"}>
                  <PosDataGridTable
                    columns={productColumnNames}
                    rows={modifiedData}
                    totalcount={totalcount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      sortRef.current = sortModel;
                      setSortModel(newSortModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.PROMO_PROD_EXPORT_FILENAME}
                  />
                  </Box>
                ) : (
                  <PosNoDataFound title={Constants.NO_DATA_FOUND} />
                )}
          </Dialog>
          {/* <Modal
            scrollBehavior="inside"
            size={"4xl"}
            onClose={onProductModalClose}
            isOpen={isProductModalOpen}
            isCentered
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader
                mt={"1.37rem"}
                fontSize={"1.5rem"}
                fontWeight={600}
                color={Colors.posTextInfo}
              >
                Add Products
                <Flex
                  minWidth="max-content"
                  gap="2"
                  mt={2}
                  mb={2}
                  alignItems="center"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <PosSearch
                    value={searchQuery}
                    onChange={(e) => {
                      let value =searchQuery.length > 0 ? 
                      e.target.value: e.target.value.trim();
                      setSearchQuery(value);
                    }}
                    onSearch={handleSearchList}
                    handleClear={handleClear} 
                  />
                  <ButtonGroup alignSelf={"flex-end"}>
                    <PosFormButton
                      onClick={modalClose}
                      buttonText={"Cancel"}
                      CancelButton={true}
                    />
                    <PosFormButton
                      isDisabled={checkedItems.length <= 0 ? true : false}
                      buttonsubmit={"Add Products"}
                      SubmitButton={true}
                      onClick={handleSelectedViewProduct}
                    />
                  </ButtonGroup>
                </Flex>
              </ModalHeader>
              <ModalBody>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 ? (
                <PosDataGridTable
                  columns={productColumnNames}
                  rows={modifiedData}
                  totalcount={totalcount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  paginationModel={paginationModel}
                  paginationMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    sortRef.current = sortModel;
                    setSortModel(newSortModel);
                  }}
                />
                ) : (
                <PosNoDataFound title={Constants.NO_DATA_FOUND} />
              )}
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal> */}

          {/* 2nd Promotion Type  */}
          <Stack spacing="2">
            <Flex
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "flex-start" }}
              flexDirection={{ base: "column", md: "row" }}
              ml={{ base: "0", md: "0" }}
            >
              {formik.values.type == Constants.BXPY && (
                <Flex
                  justifyContent={"flex-start"}
                  alignItems={"flex-start"}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Flex
                    mt={{ base: "0", md: "2rem" }}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    flexDirection={"column"}
                    gap={5}
                    width={"100%"}
                  >
                    {inputs.map((item, index) => (
                      <Flex key={index} gap={1}>
                        <VStack gap={1} width={"100%"} align="flex-start">
                          <PosInput
                            name="buy_qty_at"
                            id="buy_qty_at"
                            inputValue={item.buy_qty_at}
                            placeholder={"Enter Quantity"}
                            handleInputChange={(e) => {
                              handleChange(e, index);
                            }}
                            // inputValue={formik.values.qty}
                            handleBlur={(e) => {
                              handleBlur(e, index);
                            }}
                            posNumberInput={true}
                            onKeyDown={handleKeyDown}
                            inputType={"text"}
                            inputError={item?.buyError}
                          />
                          <Text color={Colors.errorColor}>
                            {undefined != item?.buyError &&
                            null != item?.buyError &&
                            "" != item?.buyError ? (
                              <span>{item?.buyError}</span>
                            ) : null}
                          </Text>
                        </VStack>
                        <VStack gap={1} width={"100%"} align="flex-start">
                          {/* <PosInput
                            id="get_price_at"
                            placeholder={"Enter Promotion Sale Price"}
                            inputValue={item.get_price_at}
                            handleInputChange={(e) => {
                              handleChange(e, index);
                            }}
                            handleBlur={(e) => {
                              handleBlur(e, index);
                            }}
                            posNumberInput={true}
                            inputType={"text"}
                            inputError={item?.priceError}
                          /> */}
                          <PosInput
                            id="get_price_at"
                            posDecimalNumberInput={true}
                            placeholder={"Enter Promotion Sale Price"}
                            inputValue={item.get_price_at}
                            handleInputChange={(e) => {
                              item.get_price_at = e.target.value;
                              handleChange(e, index);
                            }}
                            handleBlur={(e) => {
                              item.get_price_at = e.target.value;
                              handleBlur(e, index);
                            }}
                            inputError={item?.priceError}
                          />
                          <Text color={Colors.errorColor}>
                            {undefined != item?.priceError &&
                            null != item?.priceError &&
                            "" != item?.priceError ? (
                              <span>{item?.priceError}</span>
                            ) : null}
                          </Text>
                        </VStack>
                        <Flex
                          flexDirection={{
                            base: "column",
                            md: "row",
                          }}
                          width={"100%"}
                        >
                          {index === inputs.length - 1 && (
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              // ml={"1rem"}
                            >
                              <IconButton
                                onClick={() => handleAddInput()}
                                isRound={true}
                                isDisabled={
                                  item?.get_price_at == null ||
                                  "" == item?.get_price_at ||
                                  undefined == item?.get_price_at ||
                                  item?.buy_qty_at == null ||
                                  "" == item?.buy_qty_at ||
                                  undefined == item?.buy_qty_at ||
                                  (undefined != item?.priceError &&
                                    null != item?.priceError &&
                                    "" != item?.priceError) ||
                                  (undefined != item?.buyError &&
                                    null != item?.buyError &&
                                    "" != item?.buyError)
                                    ? true
                                    : false
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            </Box>
                          )}
                          {inputs.length > 1 && (
                            <Box
                              ml={2}
                              display={"flex"}
                              justifyContent={"center"}
                            >
                              <IconButton
                                bg={Colors.posCancleButtonRed}
                                onClick={() => handleDeleteInput(index)}
                                isRound={true}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          )}
                        </Flex>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Stack>
          <Box w={"100%"}>
            {viewSelectedProducts.length > 0 && (
              <Box
                borderRadius="0.63rem"
                mt={3}
                bg={Colors.posPageDataBackground}
                boxShadow={
                  "0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow
                }
              >
                <PosTable
                  columnNames={columnProductViewNames}
                  rawdata={modifiedViewData}
                  footerHide={true}
                  singleDeleteButton={true}
                  goSingleDelete={(id) => handleSingleDelete(id)}
                  handleCheckboxChange={handleCheckedItemsChange}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default WithRouter(PromotionCreate);