import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import { Mtheme } from "../../../theme";
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit,useLocation } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosIconButton,
  PosListHeader,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTab,
  PosTable,
  PosTostMessage,
  PosDropDown
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getAllDeactivePosMasterAdmin, getAllPosMasterAdmin } from "./MasterAdminPosService";

const MasterAdminPosStationList = (props) => {
  const { addToast } = PosTostMessage();
  const submit = useSubmit();
  const myContext = useContext(AppContext);
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [posData, setPosData] = useState([]);
  const [posDeactiveData, setPosDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  // const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [sortColumn, setSortColumn] = useState("id");
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const stateData = useLocation(); // get backraking data as param
  const [selectedLocation, setSelectedLocation] = useState((undefined !== stateData.state &&
    null !== stateData.state &&
    "" !== stateData.state)
    ? stateData.state.location
    : {} );
  const tabNames = ["Active", "Inactive"];
  const [loading, setLoading] = useState(true);
  const [topLoading, setTopLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const effectRun = useRef(true);
  const loaderRun = useRef(true);
  const actionRun = useRef(false);
  const [curruntTab, setCurruntTab] = useState(0);
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const columnNames = [
    {
      columnNames: "ID",
      sortName: "id",
    },
    {
      columnNames: "Name",
      sortName: "name",
    },
    {
      columnNames: "Location",
      sortName: "location_name",
    },
    {
      columnNames: "Actions",
      sortName: "",
    },
  ];
  const options = {
    buttonName: "Edit POS Station",
    buttonIcon: <EditIcon />,
    handleClick: (item) => handleEditPage(item),
    subButtons: [
      {
        name: "View Settings",
        icon: <VisibilityIcon style={{ color: "black" }} />,
        onClick: (item) => handleSettingsViewPage(item),
      },
      {
        name: "Duplicate POS Station",
        icon: <ContentCopyIcon style={{ color: "black" }} />,
        onClick: (item) => handleSettingsDuplicatePos(item),
      },
      {
        name: "Deactive",
        icon: <CloseIcon style={{ color: "red" }} />,
        onClick: (item) => handleDeactive(item),
      },
    ],
  };

  const handleDeactive = (item) => {
    myContext.handleLoading(true);
    actionRun.current = true;
    let data = {
      id: item.id,
      type: Constants.DEACTIVE,
    };
    submit(data, {
      method: Constants.DELETE,
      path: Constants.POS_LIST,
    });
  };
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_STATIONS_PATH);
  }

  const handlePageChange = (newPage) => {
    effectRun.current = true;
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    effectRun.current = true;
    setRowsPerPage(newRowsPerPage);
  };
  const handleSortColumn = (column, sort) => {
    setLoading(true);
    setSortColumn(column.sortName);
    effectRun.current = true;
    setSortType(sort);
  };
  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: rowsPerPage,
      page: page + 1,
      order: sortColumn,
      order_type: sortType,
      filter: searchQuery,
      location_id:
        selectedLocation != undefined &&
        selectedLocation != null &&
        selectedLocation != ""
          ? selectedLocation.id
          : "",
    };
    try {
      getAllPosMasterAdmin(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            myContext.handleLoading(false);
            setPosData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
          } else {
            myContext.handleLoading(false);
            setPosData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          if (!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          myContext.handleLoading(false);
          setPosData([]);
          setLoading(false);
          setTotalCount(0);
          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      myContext.handleLoading(false);
      setPosData([]);
      setTotalCount(0);
    }
  };
  const fetchDeActivePosData = async () => {
    setLoading(true);
    let data = {
      limit: rowsPerPage,
      page: page + 1,
      order: sortColumn,
      order_type: sortType,
      filter: searchQuery,
    };
    try {
      getAllDeactivePosMasterAdmin(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            myContext.handleLoading(false);
            setPosDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
          } else {
            myContext.handleLoading(false);
            setPosDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          if (!deactiveSearchHideRef.current && err?.response?.data?.data === 0) {
            deactiveSearchHideRef.current = true;
          }
          myContext.handleLoading(false);
          setPosDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
        });
    } catch (error) {
      myContext.handleLoading(false);
      setPosDeactiveData([]);
      setDetactiveTotaCount(0);
    }
  };

  useEffect(() => {
    // page change, rowperPage change,tab change ,serchdata call
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActivePosData();
      }
      // return () => {
      // effectRun.current = false;
      // };
    }
  }, [sortType, searchQuery, rowsPerPage, page, loading, sortColumn]);

  useEffect(() => {
    if (effectRun.current == true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setLocations(loaderResponse.data.data?.locations);
        }
      }
      // return () => {
        effectRun.current = false;
      // };
    }

    if (actionRun.current == true) {
      if (
        undefined != actionResponse &&
        null != actionResponse &&
        {} != actionResponse
      ) {
        if (
          undefined != actionResponse?.error &&
          null != actionResponse?.error &&
          actionResponse?.error
        ) {
          myContext.handleLoading(false);
          let actionData = actionResponse;
          error({ actionData });
        } else if (
          actionResponse.data[Constants.FLAGE] !== null &&
          actionResponse.data[Constants.FLAGE] === true
        ) {
          myContext.handleLoading(false);
          if (
            actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS
          ) {
            myContext.handleLoading(false);
            effectRun.current = true;
            setLoading(true);
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.POS_MODULE,
              alertDescription: Constants.POS_DEACTIVE_SUCCESS,
            });
          } else {
            myContext.handleLoading(false);
            effectRun.current = true;
            setLoading(true);
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.POS_MODULE,
              alertDescription: Constants.POS_REACTIVE_SUCCESS,
            });
          }
        }
      }
      actionRun.current = false;
    }
  }, [
    actionResponse,
    loaderResponse,
    sortType,
    searchQuery,
    rowsPerPage,
    page,
    loading,
    sortColumn,
    selectedLocation,
  ]);

  const handleEditPage = (item) => {
    myContext.handleLoading(true);
    let data = {
      location:selectedLocation,
      filter: searchQuery,
    };
    setTopLoading(true);
    props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +`${Constants.MASTER_ADMIN_POS_EDIT + item.id}`,{
      state: data,
    });
  };

  const handleSettingsDuplicatePos = (item) => {
    myContext.handleLoading(true);
    let data = {
      location:selectedLocation,
      filter: searchQuery,
    };
    setTopLoading(true);
    props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +`${Constants.MASTER_ADMIN_POS_SETTING_DUPLICATE+ item.id}`,{
      state: data,
    });
  };

  const handleSettingsViewPage = (item) => {
    myContext.handleLoading(true);
    let data = {
      location:selectedLocation,
      filter: searchQuery,
    };
    setTopLoading(true);
    props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + `${Constants.MASTER_ADMIN_POS_VIEW_SETTING + item.id}`,{
      state: data,
    });
  };

  const handleReactive = (id) => {
    actionRun.current = true;
    let data = {
      id: id,
      type: Constants.REACTIVE,
    };
    submit(data, {
      method: Constants.POST,
      path: Constants.POS_LIST,
    });
  };
  const handleSelectedLocation = (newValue) => {
    setLoading(true);
    effectRun.current = true;
    setSelectedLocation(newValue);
  };

  const handleTabChange = (index) => {
    setCurruntTab(index);
    setSelectedLocation({})
    setSearchQuery("");
    setPage(0);
    setRowsPerPage(100);
    effectRun.current = true;
    setLoading(true);
    if(index == 0){
      deactiveSearchHideRef.current = false;
    }else{
      searchInputHideRef.current = false;
    }
  };
  const handleSearchList = () => {
    props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_STATIONS_PATH);
    // search time get active and deactive list
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActivePosData();
    }
    // setLoading(true);
  };

  const modifiedData = posData.map((pos) => ({
    ...pos,
  }));

  const deactivePosData = posDeactiveData.map((pos) => ({
    ...pos,
  }));

  const locationOptionTemplate = (option) => {
    return (
      <Flex>
        {option.image_url ? (
          <Flex fontFamily={"'Lexend',sans-serif;"} fontWeight={"bold"}>
            <Box mr={2}>
              <img src={`${option.image_url}`} style={{ width: "2.8rem" }} />
            </Box>
            <Stack spacing={1}>
              <Text
                fontSize={"1rem"}
                fontStyle={"normal"}
                fontWeight={"500"}
                lineHeight={"normal"}
                color={Colors.posTextInfo}
              >
                {option.name}
              </Text>
              <Text
                fontSize={"1rem"}
                fontStyle={"normal"}
                fontWeight={"300"}
                lineHeight={"normal"}
                color={Colors.posTextInfo}
                whiteSpace={{ base: "normal", md: "nowrap" }}
                wordBreak={{ base: "break-word", md: "normal" }}
              >
                {option.address1}, {option.zipcode}, {option.country}
              </Text>
            </Stack>
          </Flex>
        ) : (
          <Stack fontFamily={"'Lexend',sans-serif;"} spacing={1}>
            <Text
              fontSize={"1rem"}
              fontStyle={"normal"}
              fontWeight={"500"}
              lineHeight={"normal"}
              color={Colors.posTextInfo}
            >
              {option.name}
            </Text>
            <Text
              fontSize={"1rem"}
              fontStyle={"normal"}
              fontWeight={"300"}
              lineHeight={"normal"}
              color={Colors.posTextInfo}
              whiteSpace={{ base: "normal", md: "nowrap" }}
              wordBreak={{ base: "break-word", md: "normal" }}
            >
              {option.address1}, {option.zipcode}, {option.country}
            </Text>
          </Stack>
        )}
      </Flex>
    );
  };
  const selectedLocationTemplate = (option, props) => {
    if (option) {
      return (
        <Flex>
          {option.image_url ? (
            <Flex fontFamily={"'Lexend',sans-serif;"} fontWeight={"bold"}>
              <Box mr={2}>
                <img src={`${option.image_url}`} style={{ width: "2.8rem" }} />
              </Box>
              <Stack spacing={1}>
                <Text
                  fontSize={"1rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  color={Colors.posTextInfo}
                >
                  {option.name}
                </Text>
                <Text
                  fontSize={"1rem"}
                  fontStyle={"normal"}
                  fontWeight={"300"}
                  lineHeight={"normal"}
                  color={Colors.posTextInfo}
                  whiteSpace={{ base: "normal", md: "nowrap" }}
                  wordBreak={{ base: "break-word", md: "normal" }}
                >
                  {option.address1}, {option.zipcode}, {option.country}
                </Text>
              </Stack>
            </Flex>
          ) : (
            <Stack spacing={1}>
              <Text
                fontSize={"1rem"}
                fontStyle={"normal"}
                fontWeight={"500"}
                lineHeight={"normal"}
                color={Colors.posTextInfo}
              >
                {option.name}
              </Text>
              <Text
                fontSize={"1rem"}
                fontStyle={"normal"}
                fontWeight={"300"}
                lineHeight={"normal"}
                color={Colors.posTextInfo}
                whiteSpace={{ base: "normal", md: "nowrap" }}
                wordBreak={{ base: "break-word", md: "normal" }}
              >
                {option.address1}, {option.zipcode}, {option.country}
              </Text>
            </Stack>
          )}
        </Flex>
      );
    }
    return <span>{props.placeholder}</span>;
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosListHeader
          header={"POS Station"}
          discription={
            "Manage and create your business POS Location on OctoPos"
          }
        />
        {/* <Spacer /> */}
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            onClick={() => {
              myContext.handleLoading(true);
              let data = {
                location:selectedLocation,
                filter: curruntTab == 1 ? "" : searchQuery,
              };
              props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_CREATE,{
                state: data,
              });
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow=" 0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <Box className="card justify-content-center">
              <Dropdown
                resetFilterOnHide={true}
                value={selectedLocation}
                onChange={(e) => handleSelectedLocation(e.value)}
                options={locations}
                optionLabel="name"
                placeholder="Select a Location"
                filter
                valueTemplate={selectedLocationTemplate}
                itemTemplate={locationOptionTemplate}
                className="w-full md:w-14rem"
              />
            </Box>
          </Flex>
          <Divider />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                }}
                onSearch={handleSearchList}
                handleClear={handleClear} // clear input value and get all data
              />
              <Spacer />
            </Flex>
          ) : null}
          {/* <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            gap={{ base: 2, md: 0 }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              // onChange={(e) => setSearchQuery(e.target.value)}
              onChange={(e) => {
                let value =searchQuery.length > 0 ? 
                e.target.value: e.target.value.trim();
                setSearchQuery(value);
              }}
              onSearch={fetchData}
              handleClear={handleClear} 
            />
            <Spacer />
          </Flex> */}
          <Divider />
          <TabPanels>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : totalcount > 0 ? (
                <PosTable
                  headerHide={true}
                  footerHide={true}
                  columnNames={columnNames}
                  rawdata={modifiedData}
                  totalcount={totalcount}
                  spliteButton={true}
                  spliteOptions={options}
                  DectivatedId={handleDeactive}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  handleSortColumn={handleSortColumn}
                  sortColumnName={sortColumn}
                  order={sortType}
                  goEdit={(id) => handleEditPage(id)}
                />
              ) : (
                <PosNoDataFound title={Constants.POS_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 ? (
                <PosTable
                  columnNames={columnNames}
                  rawdata={deactivePosData}
                  totalcount={deactiveTotalCount}
                  DectivatedId={handleDeactive}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  goEdit={(id) => handleEditPage(id)}
                  goReactive={(id) => handleReactive(id)}
                  handleSortColumn={handleSortColumn}
                  sortColumnName={sortColumn}
                />
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_POS_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(MasterAdminPosStationList);
