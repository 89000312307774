import { Text } from "@chakra-ui/react";
import { Calendar } from "primereact/calendar";
import React, { useState } from "react";
import Colors from "../../assets/colors/Colors";
import StopClickPropagation from "./StopClickPropagation";

const PosDatePicker = React.forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  // const cal = useRef(null);

  const handleVisibility = (e) => {
    setIsVisible(!isVisible);
  };
  if (props.showTopLable) {
    return (
      <div className="card flex-wrap gap-3 p-fluid">
        <div className="flex-auto">
          <Text
            fontSize={"0.94rem"}
            color={props.labelColor}
            fontWeight={undefined !== props.fontWeight ? props.fontWeight : 300}
          >
            {props.label}
          </Text>
          <StopClickPropagation>
            <Calendar
              id={props.id}
              ref={ref}
              onClick={() => {
                setIsVisible(true);
              }}
              style={{
                height: "2.50rem",
                borderRadius: "0.625rem",
                backgroundColor: Colors.primeCampCardBgColor,
              }}
              iconPos="left"
              inputStyle={{
                paddingLeft: "1rem",
                background: "#f6f8ff",
                color: Colors.primeposTextInfo,
                fontSize: "0.94rem",
                fontWeight: 300,
              }}
              dateFormat="dd M yy"
              placeholder={props.label}
              value={props.value}
              onFocus={(e) => {
                setIsVisible(true);
                props.onFocus(e);
              }}
              onChange={(e) => {
                handleVisibility();
                props.handleSelectData(e);
              }}
              selectOtherMonths={props.selectOtherMonths}
              minDate={props.minDate}
              maxDate={props.maxDate}
              yearRange={props.yearRange}
              showButtonBar={props.showButtonBar}
              readOnlyInput={props.readOnlyInput}
              monthNavigator={props.monthNavigator}
              yearNavigator={props.yearNavigator}
              hideOnDateTimeSelect={isVisible}
              todayButtonClassName="p-secondary-button"
              hourFormat="24"
              viewDate={props.viewDate}
              showTime={props.showTime}
              showIcon
              disabled={props.disabled}
            />
          </StopClickPropagation>
        </div>
      </div>
    );
  } else {
    if((props?.id == "birthFromDate" || props?.id == "birthToDate")){
      return (
        <StopClickPropagation>
          <Calendar
            id={props.id}
            ref={ref}
            onClick={() => {
              setIsVisible(true);
            }}
            style={{
              width: props.width,
              height: "2.50rem",
              borderRadius: "0.625rem",
              backgroundColor: Colors.primeCampCardBgColor,
            }}
            iconPos="left"
            inputStyle={{
              paddingLeft: "1rem",
              background: "#f6f8ff",
              color: Colors.primeposTextInfo,
              fontSize: "0.94rem",
              fontWeight: 300,
            }}
            dateFormat="dd M"
            view={props.view}
            placeholder={props.label}
            value={props.value}
            onFocus={(e) => {
              setIsVisible(true);
              props.onFocus(e);
            }}
            onChange={(e) => {
              handleVisibility();
              props.handleSelectData(e);
            }}
             // Custom header template to show only month and date and hide year and prev and next button
            headerTemplate={() => (
              <style>
                {`
                  .p-datepicker-title .p-datepicker-year {
                    display: none !important;
                  }
                  .p-datepicker-prev,
                  .p-datepicker-next {
                    display: none !important;
                  }
                  .p-datepicker-buttonbar button:first-child {
                    display: none !important;
                  }
                `}
              </style>
            )}
            selectOtherMonths={false} // Hide other months
            minDate={props.minDate}
            maxDate={props.maxDate}
            yearRange={null}
            showButtonBar={props.showButtonBar}
            readOnlyInput={props.readOnlyInput}
            monthNavigator={props.monthNavigator}
            yearNavigator={false} // Hide year navigator
            // hideOnRangeSelection={true}
            hideOnDateTimeSelect={isVisible}
            todayButtonClassName="p-secondary-button"
            hourFormat="24"
            viewDate={props.viewDate}
            showTime={props.showTime}
            showIcon
            disabled={props.disabled}
          />
        </StopClickPropagation>
      );
    }else{
      return (
        <StopClickPropagation>
          <Calendar
            id={props.id}
            ref={ref}
            onClick={() => {
              setIsVisible(true);
            }}
            style={{
              width: props.width,
              height: "2.50rem",
              borderRadius: "0.625rem",
              backgroundColor: Colors.primeCampCardBgColor,
            }}
            iconPos="left"
            inputStyle={{
              paddingLeft: "1rem",
              background: "#f6f8ff",
              color: Colors.primeposTextInfo,
              fontSize: "0.94rem",
              fontWeight: 300,
            }}
            dateFormat="dd M yy"
            placeholder={props.label}
            value={props.value}
            onFocus={(e) => {
              setIsVisible(true);
              props.onFocus(e);
            }}
            onChange={(e) => {
              handleVisibility();
              props.handleSelectData(e);
            }}
            selectOtherMonths={props.selectOtherMonths}
            minDate={props.minDate}
            maxDate={props.maxDate}
            yearRange={props.yearRange}
            showButtonBar={props.showButtonBar}
            readOnlyInput={props.readOnlyInput}
            monthNavigator={props.monthNavigator}
            yearNavigator={props.yearNavigator}
            hideOnDateTimeSelect={isVisible}
            todayButtonClassName="p-secondary-button"
            hourFormat="24"
            viewDate={props.viewDate}
            showTime={props.showTime}
            showIcon
            disabled={props.disabled}
          />
        </StopClickPropagation>
      );
    }
  }
});

export default PosDatePicker;
