import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useSubmit,
  useLocation,
  useParams,
} from "react-router-dom";
import Colors from "../../../../../assets/colors/Colors";
import * as Constants from "../../../../../constants/Constants";

import {
  AppContext,
  PosTab,
  PosSearch,
  PosLable,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
  PosSpliteButton,
  PosInput,
  PosTextArea,
} from "../../../../../components/index";
import {
  endOfTodaysDay,
  startOfTodaysDay,
  twofix,
} from "../../../../../helpers/utils/Utils";
import { WithRouter } from "../../../../../navigators/WithRouter";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

import {
  getPayOutList,
  getDectivePayOutList,
  getdatapos,
} from "./PayoutService";
import { PosErrorHook } from "../../../../../hooks";
import { GridToolbar } from "@mui/x-data-grid";

const PayoutEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const stateData = useLocation(); // get param data
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const { id } = useParams();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [posStation, setposStationData] = useState([]);
  const [selectedPosStation, setSelectedPosStation] = useState([]);
  const [users, setUserData] = useState([]);
  const [selectedUsers, setselectedUsers] = useState([]);
  const [selectedPosstationData, setSelectedPosstationDataData] = useState([]);
  const [Payout, setpayoutData] = useState([]);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [selectedUserCloseData, setSelectedUserCloseData] = useState([]);
  const [selectedUsersClose, setselectedUsersClose] = useState([]);

  useEffect(() => {
    if (loaderResponse?.response?.data?.data?.tillCheck === 1) {
      // addToast({
      //   alertStatus: Constants.TOAST_TYPE_WARNING,
      //   alertTitle: Constants.PAYIN_TITLE,
      //   alertDescription: Constants.PAY_IN_TILL_IS_OPENED,
      // });
      myContext.handleLoading(true);
      props.navigate(`${Constants.PAY_OUT_REPORT_ROUTE}`,{
        state: stateData.state,
      });
    }
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setposStationData(loaderResponse?.data?.data?.stations);
          setpayoutData(loaderResponse?.data?.data?.PayOut);

          const posStation = loaderResponse.data.data.stations;
          const PosLocationId = loaderResponse.data.data.PayOut.pos_station_id;

          const matchingPoslocations = posStation.find(
            (posLocation) => posLocation.id === PosLocationId
          );

          setSelectedPosstationDataData(matchingPoslocations);

          setUserData(loaderResponse?.data?.data?.users);
          const UserData = loaderResponse.data.data.users;
          const UserId = loaderResponse.data.data.PayOut.user_id;

          const matchingUsers = UserData.find((users) => users.id === UserId);

          setSelectedUserData(matchingUsers);
          setselectedUsersClose(loaderResponse?.data?.data?.users);
          const UserDataClose = loaderResponse.data.data.users;
          //   const UserCloseId = loaderResponse.data.data.till.closed_by;
          //   const matchingCloseUsers = UserDataClose.find(
          //     (usersClose) => usersClose.id === UserCloseId
          //   );
          //   setSelectedUserCloseData(matchingCloseUsers);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.PAY_OUT_REPORT_ROUTE, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.PAYOUT_TITLE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  const formik = useFormik({
    initialValues: {
      selectedPosStation:
        undefined !== loaderResponse?.data?.data?.PayOut?.station?.id &&
        null != loaderResponse?.data?.data?.PayOut?.station?.id
          ? loaderResponse?.data?.data?.PayOut?.station?.id
          : "",
      selectedUsers:
        undefined !== loaderResponse?.data?.data?.PayOut?.users?.name &&
        null != loaderResponse?.data?.data?.PayOut?.users?.name
          ? loaderResponse?.data?.data?.PayOut?.users?.name
          : "",
      amount:
        undefined !== loaderResponse?.data?.data?.PayOut?.amount &&
        null != loaderResponse?.data?.data?.PayOut?.amount
          ? loaderResponse?.data?.data?.PayOut?.amount
          : "",
      reason:
        undefined !== loaderResponse?.data?.data?.PayOut?.reason &&
        null != loaderResponse?.data?.data?.PayOut?.reason
          ? loaderResponse?.data?.data?.PayOut?.reason
          : "",

      id:
        undefined !== loaderResponse?.data?.data?.PayOut?.user_id &&
        null != loaderResponse?.data?.data?.PayOut?.user_id
          ? loaderResponse?.data?.data?.PayOut?.user_id
          : "",
    },
    validationSchema: Yup.object({
      selectedPosStation: Yup.string().required(
        Constants.CUSTOMER_NAME_REQUIRED
      ),
      selectedUsers: Yup.string().required(
        Constants.CUSTOMER_ADDRESS_NAME_REQUIRED
      ),
      // amount: Yup.string().required(
      //   Constants.AMOUNT_REQUIRED
      // ),
      amount: Yup.number()
        .typeError(Constants.PAYOUT_PLEASE_ENTER_A_VALID_AMOUNT)
        .max(999999.99, Constants.PAYOUT_AMOUNT_MAX_ERROR)
        .required(Constants.AMOUNT_REQUIRED)
        .min(0.01, Constants.PAYOUT_AMOUNT_MIN_ERROR),

      reason: Yup.string()
        .required(Constants.REASON_REQUIRED)
        .max(5000, Constants.PAY_IN_REASON_GREATER_VALUE),
    }),
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async () => {
      // myContext.handleLoading(true);
      // setButtonDisable(true);
      // actionRun.current = true;
      // submit(payload, {
      //   method: Constants.POST,
      //   path: Constants.TILL_REPORT_ROUTE,
      // });
    },
  });

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedPosstationDataData(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
    }
    //   formik.setFieldValue("selectedLoactions", e.value);
  };

  const handleUserSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedUserData(e.value);
      formik.setFieldValue("selectedUsers", e.value.name);
    }
  };

  const handleSubmitButton = () => {
    // Mark all fields as touched to trigger validation
    formik.setTouched(
      {
        selectedPosStation: true,
        selectedUsers: true,
        amount: true,
        reason: true,
      },
      true
    );

    if(formik.values.selectedPosStation?.length <= 0 ||
      formik.errors.selectedPosStation ||
      formik.errors.selectedUsers ||
      formik.errors.amount ||
      formik.errors.reason
    ) {
      return;
    }
    myContext.handleLoading(true);
    const payload = {
      user_id: selectedUserData.id,
      users: JSON.stringify({
        id: selectedUserData.id,
        name: selectedUserData.name,
      }),
      pos_station_id: parseInt(selectedPosstationData.id),
      station: JSON.stringify({
        id: selectedPosstationData.id,
        name: selectedPosstationData.name,
      }),
      amount: formik.values.amount,
      reason: formik.values.reason,
      id: parseInt(Payout.id),
    };

    // const payload = {
    //   closed_by_dropdown: JSON.stringify({
    //     id: selectedUsersClose.id,
    //     name: selectedUsersClose.name,
    //   }),
    //   pos_station_id: loaderResponse?.data?.data?.Payout?.pos_station_id,

    //   id: formik.values.id,

    //   opened_by: loaderResponse?.data?.data?.till?.id,
    //   amount: formik.values.amount,
    //   station: JSON.stringify({
    //     id: loaderResponse?.data?.data?.PayOut?.station?.id,
    //     name: loaderResponse?.data?.data?.PayOut?.station?.name,
    //   }),
    // };
    actionRun.current = true;
    setButtonDisable(true);
    submit(payload, {
      method: Constants.POST,
      path: Constants.PAY_OUT_REPORT_ROUTE,
    });
  };

  const handleUserCloseSelect = (e) => {
    setselectedUsersClose(e.value);
    formik.setFieldValue("selectedCloseUsers", e.value);
  };

  const handleKeyDown = (event) => {
    // if (
    //   event.key === "Enter" &&
    //   !(
    //     // formik.values.selectedPosStation.length <= 0 ||
    //     (formik.errors.selectedPosStation ||
    //     formik.errors.selectedUsers ||
    //     formik.errors.amount ||
    //     formik.errors.reason ||
    //     buttonDisable
    //       ? true
    //       : false)
    //   )
    // ) {
    //   formik.handleSubmit();
    // }
    if(event.key === "Enter" && !buttonDisable){
      handleSubmitButton();
    }
  };

  return (
    <Box
      //  minH="100vh"
      flex="1"
      pb="3.31rem"
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <Box>
          <PosBreadCrumb
            handleClick={(i) => {
              if (i === 0) {
                props.navigate(Constants.REPORT_PATH, {
                  state: stateData.state,
                });
              } else if (i === 1) {
                props.navigate(Constants.PAY_OUT_REPORT_ROUTE, {
                  state: stateData.state,
                });
              }
            }}
            breadCrumNames={["Report", "Payout", "Edit a Pay Out"]}
            breadCrumTitle={"Edit a Pay Out"}
          />
        </Box>
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              props.navigate(Constants.PAY_OUT_REPORT_ROUTE, {
                state: stateData.state,
              });
            }}
            isDisabled={buttonDisable}
          />
          <PosFormButton
            isDisabled={buttonDisable}
            // isDisabled={
              // formik.values.selectedPosStation.length <= 0 ||
              // formik.errors.selectedPosStation ||
              // formik.errors.selectedUsers ||
              // formik.errors.amount ||
              // formik.errors.reason ||
            //   buttonDisable
            //     ? true
            //     : false
            // }
            buttonsubmit={"Submit"}
            SubmitButton={true}
            onClick={handleSubmitButton}
          />
        </Stack>
      </Flex>
      <Box mt={{ base: "0", md: "2rem" }}>
        <Box
          //   mt={{ base: "0", md: "2rem" }}
          ml={{ base: 4, md: "3.44rem" }}
          mr={{ base: 4, md: "3.19rem" }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
          onKeyDown={handleKeyDown}
        >
          <Text
            fontSize="1.23rem"
            w="10.5rem"
            pt={"1.56rem"}
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontSize: "1.13rem",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
          >
            Edit Pay Out
          </Text>
          <Stack spacing={0}>
            <PosLable
              name={"POS Station"}
              requiredLabel={true}
              fontSize="0.94rem"
              fontStyle="normal"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <PosDropDown
              id="selectedPosStation"
              options={posStation}
              value={selectedPosstationData}
              onChange={handleOptionSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedPosStation");
                formik.handleBlur(e);
              }}
              multiSelect={false}
              //   disabled={true}
              optionLabel="name"
              placeholder="Select A Pos Station"
              className="w-full md:w-20rem"
              width={"100%"}
            />
            {/* <Text color={Colors.errorColor}>
              {formik.touched.selectedPosStation && errors.length > 0 ? (
                <span>{errors}</span>
              ) : null}
            </Text> */}
          </Stack>
          <Stack spacing={0}>
            <PosLable
              name={"Employee"}
              requiredLabel={true}
              fontStyle="normal"
              fontSize="0.94rem"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <PosDropDown
              id="selectedUsers"
              options={users}
              value={selectedUserData}
              onChange={handleUserSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedUsers");
                formik.handleBlur(e);
              }}
              // disabled={true}
              multiSelect={false}
              optionLabel="name"
              placeholder="Select A User"
              className="w-full md:w-20rem"
              width={"100%"}
            />
            {/* <Text color={Colors.errorColor}>
              {formik.touched.selectedUsers && errors.length > 0 ? (
                <span>{errors}</span>
              ) : null}
            </Text> */}
          </Stack>
          <Stack spacing={0}>
            <PosLable
              name={"Amount"}
              requiredLabel={true}
              fontStyle="normal"
              fontSize="0.94rem"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <PosInput
              id="amount"
              mt={"1rem"}
              placeholder={"Enter a Amount"}
              color={Colors.posCommon}
              // handleInputChange={formik.handleChange}
              inputValue={formik.values.amount}
              // handleBlur={formik.handleBlur}
              // onKeyDown={handleKeyDown}
              posDecimalNumberInput={true}
              //   disabled={true}
              // inputType={"number"}
              // handleBlur={(e) => {
              //   formik.handleBlur(e);
              //   formik.setFieldValue("amount", twofix(formik.values.amount) || "");
              // }}
              handleBlur={(e) => {
                formik.setFieldTouched("amount", true);
                formik.setFieldValue("amount", e.target.value);
              }}
              handleInputChange={(e) => {
                formik.setFieldTouched("amount", true);
                formik.setFieldValue("amount", e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              inputError={formik.touched.amount && formik.errors.amount}
            />
            <Text color={Colors.posCancleButtonRed}>
              {formik.touched.amount && formik.errors.amount ? (
                <span>{formik.errors.amount}</span>
              ) : null}
            </Text>
          </Stack>
          <Stack spacing={0}>
            <PosLable
              requiredLabel={true}
              name={"Reason"}
              fontStyle="normal"
              fontSize="0.94rem"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <PosTextArea
              id="reason"
              placeholder="Enter a Reason"
              onChange={formik.handleChange}
              value={formik.values.reason}
              onBlur={formik.handleBlur}
              onKeyDown={(e) => {
                if (e.key === " " && !formik.values.reason) {
                  e.preventDefault();
                }
              }}
              inputerror={formik.touched.reason && formik.errors.reason}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.reason && formik.errors.reason ? (
                <span>{formik.errors.reason}</span>
              ) : null}
            </Text>
          </Stack>
          {null != formik.values.reason &&
          "" != formik.values.reason &&
          5000 - formik.values.reason.length > 0 ? (
            <Text
              pt={"0.13rem"}
              color={Colors.textareaColor}
              fontSize={"0.94rem"}
              fontStyle={"normal"}
              fontWeight={300}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
            >
              {5000 - formik.values.reason.length} characters left for reason
            </Text>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(PayoutEdit);
