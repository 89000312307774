import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getAllPromotion(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PROMOTION_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROMOTION_LIST_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllLocationsForPromotion() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PROMTION_LOCATIONS_API,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROMOTION_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function getGlobleRoutePermission() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GLOBLE_ROUTE_CHECK_PERMISSION,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TAX_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProductsForSelectedLocation(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PROMOTION_PRODUCT_CREATE_LIST,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROMOTION_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getProductsForSelectedLocationEdit(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PROMO_LOCATION_PRO_EDIT,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROMOTION_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAssignedProductsEdit(id) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
    .get(
      Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.PROMOTION_ASSIGNED_PRODUCT_EDIT + "/" + id}`,
      {
        headers: {
          "content-type": Constants.CONTANT_TYPE,
          Authorization: "Bearer" + token,
          Permission: Constants.PROMOTION_EDIT_PERMISSION,
        },
      }
    )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllLocationOsSelectedProduct(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PROMOTION_PRODUCT_CREATE_LIST,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.TAX_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getValidUntilCreate(payload){
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PROMOTION_CREATE_ADD_PRODUCT_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROMOTION_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getValidUntilEdit(payload){
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PROMOTION_EDIT_ADD_PRODUCT_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROMOTION_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function promoCreate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const payload = JSON.stringify({
      name: Object.fromEntries(formData).name,
      promotionTypes:Object.fromEntries(formData).type,
      promotion_products:Object.fromEntries(formData).hasOwnProperty("promotion_products") && Object.fromEntries(formData).type == Constants.BX1 ? JSON.parse(
        Object.fromEntries(formData).promotion_products
      ) : [],
      valid_from:Object.fromEntries(formData).valid_from,
      valid_until:Object.fromEntries(formData).valid_until,
      locations:Object.fromEntries(formData).hasOwnProperty("locations") ? JSON.parse(
        Object.fromEntries(formData).locations
      ) : [],
      qty:Object.fromEntries(formData).qty,
      promo_tier:Object.fromEntries(formData).hasOwnProperty("promo_tier") && Object.fromEntries(formData).type == Constants.BXPY ? JSON.parse(
        Object.fromEntries(formData).promo_tier
      ) : [],
      promotion_sale_price:Object.fromEntries(formData).hasOwnProperty("promotion_sale_price") && Object.fromEntries(formData).type == Constants.SXPY ? JSON.parse(
        Object.fromEntries(formData).promotion_sale_price
      ) : [],
      exclude_discounts:Object.fromEntries(formData).hasOwnProperty("exclude_discounts") ? Object.fromEntries(formData).exclude_discounts : 0,
    });
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PROMOTION_CREATE_API_ROUTE,
          payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROMOTION_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function promotionUpdate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = JSON.stringify({
      name: Object.fromEntries(formData).name,
      promotionTypes:Object.fromEntries(formData).type,
      promotion_products:Object.fromEntries(formData).hasOwnProperty("promotion_products") && Object.fromEntries(formData).type == Constants.BX1 ? JSON.parse(
        Object.fromEntries(formData).promotion_products
      ) : [],
      valid_from:Object.fromEntries(formData).valid_from,
      valid_until:Object.fromEntries(formData).valid_until,
      locations:Object.fromEntries(formData).hasOwnProperty("locations") ? JSON.parse(
        Object.fromEntries(formData).locations
      ) : [],
      qty:Object.fromEntries(formData).qty,
      promo_tier:Object.fromEntries(formData).hasOwnProperty("promo_tier") && Object.fromEntries(formData).type == Constants.BXPY ? JSON.parse(
        Object.fromEntries(formData).promo_tier
      ) : [],
      promotion_sale_price:Object.fromEntries(formData).hasOwnProperty("promotion_sale_price") && Object.fromEntries(formData).type == Constants.SXPY ? JSON.parse(
        Object.fromEntries(formData).promotion_sale_price
      ) : [],
      exclude_discounts:Object.fromEntries(formData).hasOwnProperty("exclude_discounts") ? Object.fromEntries(formData).exclude_discounts : 0,
    });
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PROMOTION_UPDATE_API_ROUTE +
          `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROMOTION_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getSinglePromotion({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.PROMOTION_EDIT_API_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROMOTION_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}
export function promotionDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PROMOTION_DEACTIVATE_API +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.TAX_VIEW_API_PERMISSION,
              Authorization: "Bearer" + token,
              Permission: Constants.PROMOTION_DEACTIVE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.PROMOTION_REACTIVATE_API +
            "/" +
            id,
          {},
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.PROMOTION_REACTIVE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getAllDeactiveTax(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.DEACTIVATED_PROMOTION_LIST_PERMISSION,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.PROMOTION_DEACTIVE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
//TAX_EDIT: 'Tax-edit',  TAX_CREATE: 'tax-create',
