import axios from "axios";
import * as Constants from "../../constants/Constants";

export function searchCommandGet(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PUBLIC_SEARCH_COMMAND_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Scan barcode
 * @param {Object} payload - The payload object
 * @returns {Promise} - A promise that resolves to the response
 */
export function scanBarcode(payload) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.PUBLIC_SCAN_BARCODE_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
