import { CheckIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Divider,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosCountryStateDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosListHeader,
  PosTab,
  PosTostMessage,
  PosAlert,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import warnignIcon from "../../../assets/images/Ellipse3.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  getAllCountriesState,
  getProfileDetails,
  getStateListForSelectedCountry,
} from "./ProfileService";

const ProfileEditPassword = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();

  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [stateAvailable, setStatesAvailability] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [curruntTab, setCurruntTab] = useState(0);
  const [oldEmail, setoldEmail] = useState("");
  const tabNames = ["Personal Information", "Account Security"];
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showCurrentPassword, setshowCurrentPassword] = React.useState(false);
  const [showPassword, setPassword] = React.useState(false);
  const [showNewPassword, setNewPassword] = React.useState(false);
  const handleClick = () => setshowCurrentPassword(!showCurrentPassword);
  const handleClickPassword = () => setPassword(!showPassword);
  const handleClickNewPassword = () => setNewPassword(!showNewPassword);

  //     if (effectRun.current === true) {
  //       if (
  //         undefined != loaderResponse &&
  //         null != loaderResponse &&
  //         {} != loaderResponse
  //       )
  // console.log(loaderResponse);
  // {
  //         const arrayCountries = Object.keys(
  //           loaderResponse.data.data.countries
  //         ).map((key) => ({
  //           label: loaderResponse.data.data.countries[key],
  //           value: key,
  //         }));
  //         setCountry(arrayCountries);

  //         if (
  //           loaderResponse?.data?.data?.employee?.country &&
  //           null != loaderResponse?.data?.data?.employee?.country
  //         ) {
  //           setSelectedCountry(loaderResponse?.data?.data?.employee?.country);

  //         }

  //         if (
  //           loaderResponse?.data?.data?.employee?.state &&
  //           null != loaderResponse?.data?.data?.employee?.state &&
  //           "N.A." != loaderResponse?.data?.data?.employee?.state
  //         ) {
  //           setStatesAvailability(0);
  //           setSelectedState(loaderResponse?.data?.data?.employee?.state);
  //         }
  //         if (
  //           undefined != loaderResponse?.error &&
  //           null != loaderResponse?.error &&
  //           loaderResponse?.error
  //         ) {
  //           let actionData = loaderResponse;
  //           error({ actionData });
  //           myContext.handleLoading(false);
  //         } else if (
  //           null !== loaderResponse.data[Constants.FLAGE] &&
  //           true === loaderResponse.data[Constants.FLAGE]
  //         ) {
  //           myContext.handleLoading(false);
  //         }
  //       }
  //       return () => {
  //         effectRun.current = false;
  //       };
  //     }
  //     if (
  //       undefined != actionResponse &&
  //       null != actionResponse &&
  //       {} != actionResponse
  //     )
  // console.log(actionResponse);
  // {
  //       setButtonDisable(false);

  //       if (
  //         undefined != actionResponse?.error &&
  //         null != actionResponse?.error &&
  //         actionResponse?.error
  //       ) {
  //         let actionData = actionResponse;
  //         error({ actionData });
  //         myContext.handleLoading(false);
  //       } else if (
  //         actionResponse.data[Constants.FLAGE] !== null &&
  //         actionResponse.data[Constants.FLAGE] === true
  //       ) {
  //         console.log(actionResponse);
  //         const state = actionResponse?.data?.data?.state;
  //         // console.log(state);
  //         if (state === 'profile') {
  //           props.navigate(Constants.DASHBOARD_PATH);
  //         } else if (state === 'password') {
  //           props.navigate(Constants.LOGOUT_PAGE);
  //         } else {

  //           console.error('Unknown state:', state);
  //         }
  //         // props.navigate(Constants. LOGOUT_PAGE);
  //         addToast({
  //           alertStatus: Constants.TOAST_TYPE_SUCESS,
  //           alertTitle: Constants.PROFILE_MODULE,
  //           alertDescription: actionResponse.data.message,
  //         });
  //         myContext.handleLoading(false);
  //       }
  //     }
  //   }, [loaderResponse, actionResponse]);

  const formik = useFormik({
    initialValues: {
      id: loaderResponse?.data?.data?.employee?.id,
      name:
        undefined !== loaderResponse?.data?.data?.employee?.name &&
        null != loaderResponse?.data?.data?.employee?.name
          ? loaderResponse?.data?.data?.employee?.name
          : "",
      email:
        undefined !== loaderResponse?.data?.data?.employee?.email &&
        null != loaderResponse?.data?.data?.employee?.email
          ? loaderResponse?.data?.data?.employee?.email
          : "",
      crt_password: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(Constants.USER_NAME_REQUIRED)
        .max(191, Constants.USER_NAME_LENGTH_GREATER_THAN_191),

      email: Yup.string()
        .required(Constants.USER_EMAIL_REQUIRED)
        .email(Constants.USER_EMAIL_NOT_VALID)
        .max(191, Constants.CUSTOMER_EMAIL_LENGTH_GREATE_THEN_191),

      crt_password: Yup.string()
        .min(8, Constants.PASSWORD_MUST_BE_8_CHARACHTER)
        .required(Constants.PASSWORD_FIELD_REQUIRED),
      // .oneOf(
      //   [Yup.ref(Constants.PASSWORD)],
      //   Constants.YOU_ENTERED_THE_WRONG_PASSWORD
      // )
      password: Yup.string()
        // .min(8, Constants.PASSWORD_MUST_BE_8_CHARACHTER)
        // .matches(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
        //   Constants.VALID_PASSWORD
        // )
        .required(Constants.PASSWORD_FIELD_REQUIRED),
      // .oneOf(
      //   [Yup.ref(Constants.PASSWORD)],
      //   Constants.YOU_ENTERED_THE_WRONG_PASSWORD
      // )
      confirm_password: Yup.string()
        .min(8, Constants.PASSWORD_MUST_BE_8_CHARACHTER)
        .required(Constants.PASSWORD_FIELD_REQUIRED)
        .oneOf(
          [Yup.ref(Constants.PASSWORD)],
          Constants.YOU_ENTERED_THE_WRONG_PASSWORD
        )
        .oneOf([Yup.ref("password")], Constants.SAME_NEW_PASSWORD),
    }),

    onSubmit: (values) => {
      myContext.handleLoading(true);
      const employee = {
        id: formik.values.id,
        email: formik.values.email,
        name: formik.values.name,
        crt_password: formik.values.crt_password,
        password: formik.values.password,
        confirm_password: formik.values.confirm_password,
      };

      let payloadState = "password";

      myContext.handleLoading(true);

      const payload = {
        id: formik.values.id,
        employee: employee,
        employee: JSON.stringify(employee),
        states: payloadState,
      };
      // setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.PROFILE_DETAIL_API_ROUTE + Constants.USER_API_ID,
        payloadState: payloadState,
      });
    },
  });

  const checkString = (str) => {
    const chars = Array.from(str);
    const hasEightCharacter = str.length >= 8 ? true : false;
    const hasUppercase = chars.some((char) => /[A-Z]/.test(char));
    const hasLowercase = chars.some((char) => /[a-z]/.test(char));
    const hasNumeric = chars.some((char) => !isNaN(char) && char !== " ");
    const hasSpecial = chars.some((char) =>
      /[!@#$%^&*(),.?":{}|<>]/.test(char)
    );

    return {
      hasEightCharacter,
      hasUppercase,
      hasLowercase,
      hasNumeric,
      hasSpecial,
    };
  };
  const handleFormSubmission = () => {
    formik.setTouched(
      {
        crt_password: true,
        password: true,
        confirm_password: true,
      },
      true
    );
    if (formik.isValid) {
      formik.handleSubmit();
    }
  };
  return (
    <Box>
      <Stack>
        <PosLable
          requiredLabel={true}
          fontWeight={500}
          name={"Current Password"}
          // fontSize="0.94rem"
          // fontWeight={500}
          // lineHeight="normal"
          // letterSpacing="-0.02rem"
        />
        <InputGroup size="md">
          <Input
            id="crt_password"
            name="crt_password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            inputerror={
              formik.touched.crt_password && formik.errors.crt_password
            }
            value={formik.values.crt_password}
            type={showCurrentPassword ? "text" : "password"}
            disabled={
              loaderResponse?.data?.data?.employee?.role ==
              Constants.SUPER_ADMIN_USER_ROLE
            }
            placeholder="Enter Current Password"
          />
          <InputRightElement mt={0.9} width="4.5rem">
            {showCurrentPassword ? (
              <VisibilityOffIcon
                bg={Colors.posCancleButtonRed}
                // onClick={handleClick}
                onClick={() => handleClick()}
                // sx={{ color: Colors.posCancleButtonRed }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.31rem",
                  flexShrink: 0,
                  cursor: "pointer",
                }}
              />
            ) : (
              <VisibilityIcon
                bg={Colors.posCancleButtonRed}
                // onClick={handleClick}
                onClick={() => handleClick()}
                // sx={{ color: Colors.posCancleButtonRed }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.31rem",
                  flexShrink: 0,
                  cursor: "pointer",
                }}
              />
            )}
          </InputRightElement>
        </InputGroup>
        {/* <Text color={Colors.posCancleButtonRed}>
              {formik.touched.crt_password && formik.errors.crt_password ? (
                <span>{formik.errors.crt_password}</span>
              ) : null}
            </Text> */}

        {formik.touched.crt_password && formik.errors.crt_password ? (
          <PosAlert
            alertStatus={"error"}
            alertTitle={""}
            image={warnignIcon}
            bg={Colors.errorsign}
            color={Colors.errorColor}
            alertDescription={formik.errors.crt_password}
            width={"100%"}
          />
        ) : null}
      </Stack>
      <Stack>
        <PosLable requiredLabel={true} fontWeight={500} name={"New Password"} />

        <InputGroup size="md">
          <Input
            // mt={2}
            name="password"
            id="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            type={showPassword ? "text" : "password"}
            placeholder="Enter New Password"
            inputerror={formik.touched.password && formik.errors.password}
            disabled={
              loaderResponse?.data?.data?.employee?.role ==
              Constants.SUPER_ADMIN_USER_ROLE
            }
          />
          <InputRightElement mt={0.9} width="4.5rem">
            {showPassword ? (
              <VisibilityOffIcon
                bg={Colors.posCancleButtonRed}
                onClick={() => handleClickPassword()}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.31rem",
                  flexShrink: 0,
                  cursor: "pointer",
                }}
              />
            ) : (
              <VisibilityIcon
                bg={Colors.posCancleButtonRed}
                onClick={() => handleClickPassword()}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.31rem",
                  flexShrink: 0,
                  cursor: "pointer",
                }}
              />
            )}
          </InputRightElement>
          {/* <Text color={Colors.posCancleButtonRed}>
                      {formik.touched.password && formik.errors.password ? (
                        <span>{formik.errors.password}</span>
                      ) : null}
                    </Text> */}
        </InputGroup>
        {formik.touched.password && formik.errors.password ? (
          <PosAlert
            alertStatus={"error"}
            alertTitle={""}
            image={warnignIcon}
            bg={Colors.errorsign}
            color={Colors.errorColor}
            alertDescription={formik.errors.password}
            width={"100%"}
          />
        ) : null}
      </Stack>
      {/* <Box sx={{ paddingTop: "1.7rem", paddingBottom: "0.6rem" }}>
        <Divider />
      </Box>
      <Text
        fontWeight="500"
        fontSize="0.75rem"
        lineHeight="1.25rem"
        letterSpacing="-0.09px"
        color="#010048"
        mb={0.5}
      >
        Password must contain:
      </Text>
      <Box
        color="rgba(1, 0, 72, 0.2)"
        fontWeight="300"
        fontSize="0.75rem"
        lineHeight="1.25rem"
        letterSpacing="-0.09px"
        mt={"0.81rem"}
      >
        <Text
          color={
            !formik.touched.password
              ? "rgba(1, 0, 72, 0.2)"
              : checkString(formik.values.password.trim()).hasEightCharacter
              ? "#09A222"
              : Colors.errorColor
          }
          fontWeight="300"
          fontSize="0.75rem"
          lineHeight="1.25rem"
          letterSpacing="-0.09px"
        >
          <CheckIcon /> At least 8 characters
        </Text>
        <Text
          color={
            !formik.touched.password
              ? "rgba(1, 0, 72, 0.2)"
              : checkString(formik.values.password.trim()).hasUppercase
              ? "#09A222"
              : Colors.errorColor
          }
          fontWeight="300"
          fontSize="0.75rem"
          lineHeight="1.25rem"
          letterSpacing="-0.09px"
        >
          <CheckIcon /> One uppercase letter
        </Text>
        <Text
          color={
            !formik.touched.password
              ? "rgba(1, 0, 72, 0.2)"
              : checkString(formik.values.password.trim()).hasLowercase
              ? "#09A222"
              : Colors.errorColor
          }
          fontWeight="300"
          fontSize="0.75rem"
          lineHeight="1.25rem"
          letterSpacing="-0.09px"
        >
          <CheckIcon /> One lowercase letter
        </Text>
        <Text
          color={
            !formik.touched.password
              ? "rgba(1, 0, 72, 0.2)"
              : checkString(formik.values.password.trim()).hasNumeric
              ? "#09A222"
              : Colors.errorColor
          }
          fontWeight="300"
          fontSize="0.75rem"
          lineHeight="1.25rem"
          letterSpacing="-0.09px"
        >
          <CheckIcon /> One numeric digit
        </Text>
        <Text
          color={
            !formik.touched.password
              ? "rgba(1, 0, 72, 0.2)"
              : checkString(formik.values.password.trim()).hasSpecial
              ? "#09A222"
              : Colors.errorColor
          }
          fontWeight="300"
          fontSize="0.75rem"
          lineHeight="1.25rem"
          letterSpacing="-0.09px"
        >
          <CheckIcon /> One special character (e.g., @, #, $)
        </Text>
      </Box> */}
      <Stack>
        <PosLable
          // name={"Confirm Password"}
          // requiredLabel={true}
          // fontStyle="normal"
          // fontSize="0.94rem"
          // fontWeight={500}
          // lineHeight="normal"
          // letterSpacing="-0.02rem"
          requiredLabel={true}
          fontWeight={500}
          name={"Confirm Password"}
        />
        <InputGroup size="md">
          <Input
            // mt={2}
            name="confirm_password"
            id="confirm_password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirm_password}
            type={showNewPassword ? "text" : "password"}
            placeholder="Enter Confirm Password"
            inputerror={
              formik.touched.confirm_password && formik.errors.confirm_password
            }
            disabled={
              loaderResponse?.data?.data?.employee?.role ==
              Constants.SUPER_ADMIN_USER_ROLE
            }
          />
          <InputRightElement mt={0.9} width="4.5rem">
            {showNewPassword ? (
              <VisibilityOffIcon
                bg={Colors.posCancleButtonRed}
                onClick={() => handleClickNewPassword()}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.31rem",
                  flexShrink: 0,
                  cursor: "pointer",
                }}
              />
            ) : (
              <VisibilityIcon
                bg={Colors.posCancleButtonRed}
                onClick={() => handleClickNewPassword()}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.31rem",
                  flexShrink: 0,
                  cursor: "pointer",
                }}
              />
            )}
          </InputRightElement>

          {/* <Text color={Colors.posCancleButtonRed}>
                      {formik.touched.confirm_password && formik.errors.confirm_password ? (
                        <span>{formik.errors.confirm_password}</span>
                      ) : null}
                    </Text> */}
        </InputGroup>
        {formik.touched.confirm_password && formik.errors.confirm_password ? (
          <PosAlert
            alertStatus={"error"}
            alertTitle={""}
            image={warnignIcon}
            bg={Colors.errorsign}
            color={Colors.errorColor}
            alertDescription={formik.errors.confirm_password}
            width={"100%"}
          />
        ) : null}
      </Stack>
      <Spacer />
      {loaderResponse?.data?.data?.employee?.role !=
        Constants.SUPER_ADMIN_USER_ROLE && (
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-end"}
          justifyContent="flex-end"
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          // pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              // isDisabled={
              //   formik.values.crt_password.length <= 0 ||
              //     formik.errors.crt_password ||
              //     formik.errors.password ||
              //     formik.errors.confirm_password ||
              //     buttonDisable
              //     ? true
              //     : false
              // }
              isDisabled={buttonDisable}
              buttonsubmit={"Update Password"}
              // SubmitButton={true}
              // onClick={formik.handleSubmit}
              onClick={handleFormSubmission}
            />
          </Stack>
        </Flex>
      )}
    </Box>
  );
};

export default WithRouter(ProfileEditPassword);
