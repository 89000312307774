import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  Stack,
  StackDivider,
  Text
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import 'tippy.js/dist/tippy.css'; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDrawer,
  PosFormButton,
  PosLable,
  PosSwitchButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  createAccountSettings,
  getAllLocationsAdditionalSettingData,
} from "./LocationService";

const LocationOtherSettings = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const [state, setState] = useState({ formData: {} });
  const [setSettings, setLocationAdditionalSettings] = useState([]);
  const { id } = useParams();
  const [viewFlag, setViewFlag] = useState(false);

  useEffect(() => {
    if (props.isOtherOpen) {
      myContext.handleLoading(true);
      fetchData(id);
    }
    return () => {};
  }, [props.isOtherOpen]);

  const fetchData = async (id) => {
    try {
      getAllLocationsAdditionalSettingData(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {

            response.data?.data?.locationSettings.map((dt) => {
                if (dt.value == "yes") {
                  dt.value = true;
                }
                else{
                  dt.value = false;
                }
            });

            setLocationAdditionalSettings(
              response.data?.data?.locationSettings
            );
            myContext.handleLoading(false);
            setViewFlag(true);
          } else {
            setLocationAdditionalSettings([]);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLocationAdditionalSettings([]);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (err) {
      setLocationAdditionalSettings([]);
      myContext.handleLoading(false);
    }
  };

  const findAndReturnValue = (array, criteria, valueToReturn) => {
    const foundObject = array.find((item) => item.key == criteria);
    if (foundObject) {
      return foundObject[valueToReturn];
    } else {
      return null;
    }
  };

  const formik = useFormik({
    initialValues: {
      calculate_quantity_from_type_2_barcode: findAndReturnValue(setSettings, "calculate_quantity_from_type_2_barcode", "value"),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      setState({ ...state, formData: values });
      myContext.handleLoading(true);
      let payload = {
        ...values,
        other_settings: true,
        location_id: id,
      };
      setButtonDisable(true);
      myContext.handleLoading(true);
      createAccountSettings(payload)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    },
  });
  const handleClose = () => {
    formik.resetForm();
    setViewFlag(false);
    if (props.onOtherClose) {
      props.onOtherClose();
    }
  };
  return (
    <PosDrawer
      visible={viewFlag}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      <Box padding={2}>
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
        >
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box pb={{ base: 5, md: 0 }}>
              <PosBreadCrumb
                handleClick={(i) => {
                  props.navigate(Constants.ESTABLISHMENT_LOCATION_PATH);
                }}
                breadCrumNames={["Locations", "Other"]}
                breadCrumTitle={"Other"}
              />
            </Box>
            <ButtonGroup gap="2" alignSelf={"flex-end"}>
              <PosFormButton
                isDisabled={
                  buttonDisable
                    ? true
                    : false
                }
                buttonsubmit={"Save Changes"}
                SubmitButton={true}
                onClick={formik.handleSubmit}
              />
            </ButtonGroup>
          </Flex>
        </Box>
        <Divider borderColor="#E6E6E6" mt={5} />
        <Stack divider={<StackDivider />} spacing="2">
          <Box>
            <Flex
              justifyContent={{ base: "center", md: "space-between" }}
              alignItems={{ base: "flex-start" }}
              flexDirection={{ base: "column", md: "row" }}
            >
              {/* <Box
                w={{ base: "100%", md: "35%" }}
                ml={{ base: "0", md: "1.9rem" }}
                mt={10}
              >
                <Heading
                  as="h3"
                  size="xs"
                  fontWeight="500"
                  fontSize="1.125rem"
                  lineHeight="1.40625rem"
                  letterSpacing="-0.016875rem"
                  color="#010048"
                >
                  Operations
                </Heading>
                <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                  Configure Type 2 barcode settings for scale integration
                </Text>
              </Box> */}
              <Box ml={{ base: "0", md: "1.9rem" }} mr={"0.9rem"} w={{ base: "100%", md: "70%" }}>
              <Flex flexDirection={"row"} alignItems={{base:"flex-end",md:"flex-start"}}>
              <Flex w={{base:"60rem",md:"40%"}}>
                <PosLable
                  label={true}
                  fontWeight={500}
                  name={"Calculate Quantity From Type 2 Barcode"}
                  mt="0.31rem"
                />
                </Flex>
                  <PosSwitchButton
                    id="calculate_quantity_from_type_2_barcode"
                    switchValue={formik.values.calculate_quantity_from_type_2_barcode || false}
                    onChange={formik.handleChange}
                    mt={"2rem"}
                    ml={"1.5rem"}
                  />
                </Flex>
                <Text
                  mt={"0.30rem"}
                  h={"1.44rem"}
                  w={{base:"15rem",md:"38%"}}
                  color={Colors.taxCreateExemptEbt}
                  style={{
                    flexShrink: "0",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                    letterSpacing: "-0.18px",
                  }}
                >
                  Enable this option if you need to calculate quantities from pre-priced Type 2 barcodes. Be sure the per-pound price on your Deli Scale Printer matches the price in Octopos; otherwise, the calculated quantities may be incorrect.
                </Text>
              </Box>
            </Flex>
          </Box>
        </Stack>
      </Box>
    </PosDrawer>
  );
};
export default WithRouter(LocationOtherSettings);
