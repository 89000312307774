/**
 * @author: Modified for React JS
 * This module captures keyboard input events specifically from barcode scanners
 * Uses timing and input pattern detection to differentiate scanner from keyboard
 */
import React, { useEffect, useRef } from "react";

const BarcodeScanner = ({ onScan }) => {
  const barcodeBuffer = useRef("");
  const lastKeyTime = useRef(0);
  const keypressCount = useRef(0);
  const keyTimings = useRef([]); // Add this to track timing of each character
  const scanTimer = useRef(null);

  const SCAN_TIMEOUT = 30; // Time window for complete scan (ms)
  const MIN_CHARS = 3; // Minimum characters for a valid barcode
  const MAX_INTER_CHAR_DELAY = 50; // Maximum delay between characters (ms)
  const MIN_CHARS_PER_SEC = 15; // Increased minimum speed
  const MAX_TIMING_VARIANCE = 20; // Maximum allowed variance in timing between characters (ms)

  const processBarcode = () => {
    const barcode = barcodeBuffer.current;
    const timings = keyTimings.current;
    
    // Calculate intervals between keypresses
    const intervals = [];
    for (let i = 1; i < timings.length; i++) {
      intervals.push(timings[i] - timings[i - 1]);
    }

    // Calculate variance in timing
    const avgInterval = intervals.reduce((a, b) => a + b, 0) / intervals.length;
    const hasConsistentTiming = intervals.every(interval => 
      Math.abs(interval - avgInterval) <= MAX_TIMING_VARIANCE
    );

    const scanDuration = timings[timings.length - 1] - timings[0];
    const charsPerSec = ((keypressCount.current - 1) / scanDuration) * 1000;

    // Only process if the input matches barcode scanner characteristics
    if (barcode.length >= MIN_CHARS && 
        charsPerSec >= MIN_CHARS_PER_SEC && 
        hasConsistentTiming) {
      onScan(barcode);
    }

    // Reset after processing
    barcodeBuffer.current = "";
    keypressCount.current = 0;
    keyTimings.current = [];
  };

  const handleKeyDown = (event) => {
    // Check if the active element is an input, textarea, or any editable element
    const activeElement = document.activeElement;
    const isInputFocused = activeElement.tagName === 'INPUT' || 
                          activeElement.tagName === 'TEXTAREA' ||
                          activeElement.isContentEditable;

    // If an input is focused, don't process barcode scanning
    if (isInputFocused) {
      return;
    }

    // Ignore repeated keydown events from held keys
    if (event.repeat) {
      return;
    }

    const currentTime = Date.now();
    const timeSinceLastKey = currentTime - lastKeyTime.current;

    // Ignore modifier keys
    if (event.ctrlKey || event.altKey || event.metaKey) {
      return;
    }

    // Reject if typing is too slow (likely human typing)
    if (keypressCount.current > 0 && timeSinceLastKey > MAX_INTER_CHAR_DELAY) {
      barcodeBuffer.current = "";
      keypressCount.current = 0;
      keyTimings.current = [];
      return;
    }

    // Check if this is the start of a new scan
    if (currentTime - lastKeyTime.current > 500) {
      barcodeBuffer.current = "";
      keypressCount.current = 0;
      keyTimings.current = [];
    }

    // Update timing references
    lastKeyTime.current = currentTime;
    keypressCount.current++;
    keyTimings.current.push(currentTime);

    // Handle Enter key (typical barcode scanner termination)
    if (event.key === "Enter") {
      if (keypressCount.current >= MIN_CHARS && 
          currentTime - lastKeyTime.current <= SCAN_TIMEOUT) {
        processBarcode();
      }
      return;
    }

    // Add character to buffer if it's a single printable character
    if (event.key.length === 1) {
      barcodeBuffer.current += event.key;

      // Clear existing timer
      if (scanTimer.current) {
        clearTimeout(scanTimer.current);
      }

      // Set new timer for scan completion
      scanTimer.current = setTimeout(() => {
        if (keypressCount.current >= MIN_CHARS) {
          processBarcode();
        }
        barcodeBuffer.current = "";
        keypressCount.current = 0;
      }, SCAN_TIMEOUT);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      if (scanTimer.current) {
        clearTimeout(scanTimer.current);
      }
    };
  }, []);

  return null;
};

export default BarcodeScanner;
