import axios from "axios";
import * as Constants from "../../../../../constants/Constants";

export function getAllLocationsAndAllCustomersForCustomerAgedReceivable({
  request,
  params,
}) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GET_CUSTOMER_AND_LOCATION_API_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission:
              Constants.GET_CUSTOMER_AND_LOCATION_API_PERMISSION_FOR_CUSTOMER_AGED_RECEIVABLE_REPORT,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getCustomerAgedReceivableAndExportReport({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).getreport === "true") {
      const payload = JSON.stringify({
        zone: Object.fromEntries(formData).zone,
        query: JSON.parse(Object.fromEntries(formData).query),
        customers: JSON.parse(Object.fromEntries(formData).customers),
      });
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.CUSTOMER_AGED_RECEIVABLE_REPORT_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission:
                Constants.CUSTOMER_AGED_RECEIVABLE_REPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else {
      const payload = JSON.stringify({
        zone: Object.fromEntries(formData).zone,
        query: JSON.parse(Object.fromEntries(formData).query),
        customers: JSON.parse(Object.fromEntries(formData).customers),
      });
      axios
        .post(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.CUSTOMER_AGED_RECEIVABLE_REPORT_EXPORT_API_ROUTE,
          payload,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission:
                Constants.CUSTOMER_AGED_RECEIVABLE_EXPORT_API_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getCustomerAgedReceivableAndExportReportPdf(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CUSTOMER_AGED_RECEIVABLE_REPORT_PDF_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission:
              Constants.CUSTOMER_AGED_RECEIVABLE_REPORT_PDF_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sendEmailStatementToCustomer(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CUSTOMER_AGED_RECEIVABLE_REPORT_EMAIL_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission:
              Constants.CUSTOMER_AGED_RECEIVABLE_REPORT_PDF_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}