import {
  Input,
  InputGroup,
  InputLeftElement,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import React from "react";

// const handleChangeText = text => {
//   // if (props.keyboardType === 'number-pad') {
//     const numericText = text.replace(/[^0-9]/g, '');
//     if (numericText === '') {
//       props.onChangeText('');
//     } else {
//       if (props.allowDecimals) {
//         const formattedValue = (parseFloat(numericText) / 100).toFixed(2);
//         if (formattedValue === '0.00') {
//           props.onChangeText('');
//         } else {
//           props.onChangeText(isNaN(formattedValue) ? '' : formattedValue);
//         }
//       } else {
//         props.onChangeText(numericText);
//       }
//     }
//   // } else {
//   //   props.onChangeText(text);
//   // }
// };

const PosInput = (props) => {
  // make a methode to handle the input value
  const handleDecimalInputChange = (e, handleInputChange) => {
    // Remove non-numeric characters
    const numericText = e.target.value.replace(/[^0-9]/g, "");

    // Handle empty input
    if (numericText === "") {
      if (handleInputChange) {
        handleInputChange({
          ...e,
          target: {
            ...e.target,
            value: "",
            displayValue: "",
            isValidDecimal: false,
          },
        });
      }
      return;
    }

    // Format decimal value
    const formattedValue = (parseFloat(numericText) / 100).toFixed(2);

    // Handle zero value
    if (formattedValue === "0.00") {
      if (handleInputChange) {
        handleInputChange({
          ...e,
          target: {
            ...e.target,
            value: "",
            displayValue: "",
            isValidDecimal: false,
          },
        });
      }
      return;
    }

    // Pass formatted value to parent
    if (handleInputChange) {
      handleInputChange({
        ...e,
        target: {
          ...e.target,
          value: formattedValue,
          displayValue: formattedValue,
          isValidDecimal: true,
        },
      });
    }
  };
  if (props.posInput) {
    return (
      <Input
        id={props.id}
        width={props.width}
        placeholder={props.placeholder}
        onChange={props.handleInputChange}
        value={props.inputValue}
        onBlur={props.handleBlur}
        inputerror={props.inputError}
        // borderColor={Colors.posInputBorder}
        onKeyDown={props.onKeyDown}
        // background={Colors.posInputBackground}
        background={props.background}
        isDisabled={props.disabled}
        variant={props.darkPlaceholder ? "posDarkPlaceholder" : "posBasicInput"}
        isInvalid={props.inputError}
      />
    );
  } else if (props.posNumberInput) {
    return (
      // <NumberInput variant={"posBasicInput"} precision={props.precision}>
      //   <NumberInputField
      //     value={props.inputValue}
      //     isInvalid={props.inputError}
      //     isDisabled={props.disabled}
      //     id={props.id}
      //     width={props.width}
      //     background={props.background}
      //     placeholder={props.placeholder}
      //     onChange={props.handleInputChange}
      //     onKeyDown={props.onKeyDown}
      //     onBlur={props.handleBlur}
      //     inputerror={props.inputError}
      //   />
      // </NumberInput>
      <NumberInput
        variant={props.darkPlaceholder ? "posDarkPlaceholder" : "posBasicInput"}
        precision={props.precision}
        value={props.inputValue}
        isInvalid={props.inputError}
        isDisabled={props.disabled}
      >
        <NumberInputField
          id={props.id}
          width={props.width}
          background={props.background}
          placeholder={props.placeholder}
          onChange={props.handleInputChange}
          onKeyDown={props.onKeyDown}
          onBlur={props.handleBlur}
          inputerror={props.inputError}
        />
      </NumberInput>
    );
  } else if (props.posInputGroup) {
    return (
      <InputGroup>
        <InputLeftElement>{props.children}</InputLeftElement>
        <Input
          id={props.id}
          variant={
            props.darkPlaceholder ? "posDarkPlaceholder" : "posBasicInput"
          }
          width={props.width}
          placeholder={props.placeholder}
          onChange={props.handleInputChange}
          value={props.inputValue}
          onBlur={props.handleBlur}
          onKeyDown={props.onKeyDown}
          isInvalid={props.inputError}
          // borderColor={Colors.posInputBorder}
          background={props.background}
          isDisabled={props.disabled}
        />
      </InputGroup>
    );
  } else if (props.posDecimalNumberInput) {
    return (
      <NumberInput
        variant={props.darkPlaceholder ? "posDarkPlaceholder" : "posBasicInput"}
        value={props.inputValue}
        isInvalid={props.inputError}
        isDisabled={props.disabled}
      >
        <NumberInputField
          id={props.id}
          width={props.width}
          background={props.background}
          placeholder={props.placeholder}
          // onChange={(e) => {
          //   const numericText = e.target.value.replace(/[^0-9]/g, "");

          //   if (numericText === "") {
          //     if (props.handleInputChange) {
          //       props.handleInputChange({
          //         ...e,
          //         target: {
          //           ...e.target,
          //           value: "",
          //           displayValue: "",
          //           isValidDecimal: false,
          //         },
          //       });
          //     }
          //     return;
          //   }
          //   const formattedValue = (parseFloat(numericText) / 100).toFixed(2);
          //   if (formattedValue === "0.00") {
          //     if (props.handleInputChange) {
          //       props.handleInputChange({
          //         ...e,
          //         target: {
          //           ...e.target,
          //           value: "",
          //           displayValue: "",
          //           isValidDecimal: false,
          //         },
          //       });
          //     }
          //     return;
          //   }
          //   let displayValue = formattedValue;
          //   if (props.handleInputChange) {
          //     props.handleInputChange({
          //       ...e,
          //       target: {
          //         ...e.target,
          //         value: formattedValue,
          //         displayValue: displayValue,
          //         isValidDecimal: true,
          //       },
          //     });
          //   }
          // }}
          onChange={(e) => handleDecimalInputChange(e, props.handleInputChange)}
          onKeyDown={(e) => {
            if (e.key === "Backspace") {
              const value = e.target.value;
              if (!value || value === "0.00" || value === "00.00") {
                e.preventDefault();
                if (props.handleInputChange) {
                  props.handleInputChange({
                    ...e,
                    target: {
                      ...e.target,
                      value: "",
                      displayValue: "",
                      isValidDecimal: false,
                    },
                  });
                }
              }
            }
            if (props.onKeyDown) {
              props.onKeyDown(e);
            }
          }}
          onBlur={props.handleBlur}
          inputerror={props.inputError}
        />
      </NumberInput>
    );
  } else {
    return null;
  }
};

export default PosInput;
