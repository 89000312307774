import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  Button,
  Divider,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
  PosListHeader,
  PosLable
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { SimpleReportLayout } from "../../../../../layouts/index";

const TaxReport = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [totalSalesAndRefundTaxData, setTotalSalesAndRefundTaxData] = useState(
    []
  );
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [applyClicked, setApplyClicked] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const pageRef = useRef(false);
  const actionColumn = isMobile ? { width: 180 } : { flex: 1 };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const columnNames = [
    { field: "id", headerName: "Tax Id", sortable: true, width: 120 },
    { field: "name", headerName: "Tax Name", sortable: true, ...actionColumn },
    {
      field: "payment_collected",
      headerName: "Tax Eligible Amount($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "tax_collected",
      headerName: "Tax Collected($)",
      sortable: true,
      ...actionColumn,
    },
  ];

  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedLoactions: "",
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      // selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0]);
            formik.setFieldValue(
              "selectedLoactions",
              loaderResponse?.data?.data?.location[0].name
            );
          }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (pageRef.current) {
      getTaxesReportData();
    }
  }, [paginationModel, sortModel]);
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Taxes Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.TAXES_REPORT_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.tax_data);
          setTotalCount(actionResponse.data.data.totalItems);
          let data = [
            {
              total_sales: actionResponse.data.data.total_sales,
              total_tax: actionResponse.data.data.total_tax,
              total_refunded_amount:
                actionResponse.data.data.total_refunded_amount,
              total_refunded_tax: actionResponse.data.data.total_refunded_tax,
              total_refunded_tip: actionResponse.data.data.total_refunded_tip,
              net_payment_collected:
                actionResponse.data.data.net_payment_collected,
              net_tax_collected: actionResponse.data.data.net_tax_collected,
              total_payment_amount:
                actionResponse.data.data.total_payment_amount,
              total_tip_amount: actionResponse.data.data.total_tip_amount,
              total_payment_collected:
                actionResponse.data.data.total_payment_collected,
              total_unpaid_amount: actionResponse.data.data.total_unpaid_amount,
              total_refunded_amount_without_order:
                actionResponse.data.data.total_refunded_amount_without_order,
              total_refunded_tax_without_order:
                actionResponse.data.data.total_refunded_tax_without_order,
              total_tax_collected: actionResponse.data.data.total_tax_collected.toFixed(
                2
              ),
            },
          ];
          setTotalSalesAndRefundTaxData(data);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {
    if (
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          const formattedLocation = {
            label: selectedLocation.name,
            value: selectedLocation,
          };
          myContext.handleLoading(true);
          let data = {
            getreport: false,
            //   location: JSON.stringify(formik.values.selectedLoactions),
            location: JSON.stringify(formattedLocation),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            type: "xls",
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.TAXES_REPORT_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getTaxesReportData = () => {
    formik.setTouched({
      selectedLoactions: true,
      fromDate: true,
      toDate: true,
    });
    if(formik.values.selectedLoactions?.length === 0 ||
      formik.values.fromDate === "" ||
      formik.values.toDate === "" ||
      formik.errors.selectedLoactions ||
      formik.errors.fromDate ||
      formik.errors.toDate){
      return;
    }
    if (
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions
    ) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          setButtonDisable(true);
          setLoading(true);
          setShowTable(false);
          setApplyClicked(true);
          const formattedLocation = {
            label: selectedLocation.name,
            value: selectedLocation,
          };

          let data = {
            getreport: true,
            location: JSON.stringify(formattedLocation),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            query: JSON.stringify({
              limit: paginationModel.pageSize,
              page: paginationModel.page + 1,
              order:
                sortModel.length > 0
                  ? sortModel[0].field
                  : sortRef.current[0].field,
              order_type:
                sortModel.length > 0
                  ? sortModel[0].sort
                  : sortRef.current[0].sort,
            }),
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.TAXES_REPORT_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
      //   formik.setFieldValue("selectedLoactions", e.value);
    }
  };
  const handleResetAllData = () => {
    pageRef.current = false;
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSelectedLocation([]);
    setTableData([]);
    setShowTable(true);
    setApplyClicked(false);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        // let s moment(e.value).set({ hour: 23, minute: 59 }).toDate();
        // formik.setFieldValue("fromDate", start_date);
        // formik.setFieldValue("toDate", end_date);
        // setFromDateView(start_date);
        // setToDateView(end_date);
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const modifiedData = tableData.map((data) => ({
    id: data.id,
    name: data.name,
    payment_collected: data.payment_collected.toFixed(2),
    tax_collected: data.tax_collected.toFixed(2),
  }));
  const renderReportLayout = () => {
    return (
      <>
        <Box className="card justify-content-center">
          <PosDropDown
            id="selectedLoactions"
            options={locations}
            value={selectedLocation}
            resetFilterOnHide={true}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            // multiSelect={true}
            optionLabel="name"
            placeholder="Select Location*"
            width={
              isExtraLarge
                ? "20rem"
                : isLarge
                ? "17rem"
                : isMeduim1
                ? "13.32rem"
                : "100%"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
            // maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
            formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box>
        <Stack
          className="card justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          mt={isMeduim ? 4 : 0}
        >
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              readOnlyInput={true}
              value={formik.values.fromDate}
              label={"From Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              readOnlyInput={true}
              value={formik.values.toDate}
              label={"To Date*"}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : dateTimeValue
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Tax Report"]}
          breadCrumTitle={"Tax Report"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              // pass param data in tax list page
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isMeduim ? 4 : 0}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              isDisabled={applyClicked ? false : true}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                // formik.values.selectedLoactions?.length === 0 ||
                // formik.values.fromDate === "" ||
                // formik.values.toDate === "" ||
                // formik.errors.selectedLoactions ||
                // formik.errors.fromDate ||
                // formik.errors.toDate ||
                buttonDisable
              }
              onClick={getTaxesReportData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : tableData.length > 0 ? (
            <Flex direction={"column"} gap={4}>
              <Box
                // mt={{ base: 4, md: 4 }}
                //   ml={{ base: "5%", lg: "25%" }}
                //   mr={{ base: "5%", lg: "25%" }}
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
                p="2"
                // bg="white"
                // p={2}
                boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
              >
                <PosListHeader
                  header={"Sales & Refund Details"}
                ></PosListHeader>
                <Divider mt={8}></Divider>
                <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text ml={2.5} color={Colors.posTextInfo} fontWeight={300}>
                    Payment Amount Collected
                  </Text>
                  <PosLable
                    ml={2.5}
                    fontWeight={300}
                    name={"A"}
                    mutedLabel={true}
                  />
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={300}>
                    ${totalSalesAndRefundTaxData[0].total_payment_amount}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text ml={2.5} color={Colors.posTextInfo} fontWeight={300}>
                    Tip Amount Collected 
                  </Text>
                  <PosLable
                    ml={2.5}
                    name={"B"}
                    fontWeight={300}
                    mutedLabel={true}
                  />
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={300}>
                    ${totalSalesAndRefundTaxData[0].total_tip_amount}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text ml={2.5} color={Colors.posTextInfo} fontWeight={500}>
                    Total Payment Collected(Includes Tax)
                  </Text>
                  <PosLable
                    ml={2.5}
                    name={"C = A+B"}
                    mutedLabel={true}
                  />
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={500}>
                    ${totalSalesAndRefundTaxData[0].total_payment_collected}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text ml={2.5} color={Colors.posTextInfo} fontWeight={300}>
                    Payment Refunded With Order
                  </Text>
                  <PosLable
                    ml={2.5}
                    name={"D"}
                    fontWeight={300}
                    mutedLabel={true}
                  />
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={300}>
                    ${totalSalesAndRefundTaxData[0].total_refunded_amount}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text ml={2.5} color={Colors.posTextInfo} fontWeight={300}>
                    Payment Refunded Without Order
                  </Text>
                  <PosLable
                    ml={2.5}
                    fontWeight={300}
                    name={"E"}
                    mutedLabel={true}
                  />
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={300}>
                    $
                    {
                      totalSalesAndRefundTaxData[0]
                        .total_refunded_amount_without_order
                    }
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text ml={2.5} color={Colors.posTextInfo} fontWeight={500}>
                    Net Payment Collected
                  </Text>
                  <PosLable
                    ml={2.5}
                    name={"F = C-D-E"}
                    mutedLabel={true}
                  />
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={500}>
                    {Number(
                      totalSalesAndRefundTaxData[0].net_payment_collected
                    ) < 0
                      ? `-$${Math.abs(
                          totalSalesAndRefundTaxData[0].net_payment_collected
                        ).toFixed(2)}`
                      : `$${Number(
                          totalSalesAndRefundTaxData[0].net_payment_collected
                        ).toFixed(2)}`}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text ml={2.5} color={Colors.posTextInfo} fontWeight={300}>
                    Unpaid Amount(Credit Account)
                  </Text>
                  <PosLable
                    ml={2.5}
                    fontWeight={300}
                    name={"G"}
                    mutedLabel={true}
                  />
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={300}>
                    ${totalSalesAndRefundTaxData[0].total_unpaid_amount}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex mt={2}></Flex>
                <Divider mt={4}></Divider>
              {/* uNDERSTANDING ROW  */}
                <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text ml={2.5} color={Colors.posTextInfo} fontWeight={300}>
                    Tax Amount Collected
                  </Text>
                  <PosLable
                    ml={2.5}
                    fontWeight={300}
                    name={"I"}
                    mutedLabel={true}
                  />
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={300}>
                    ${totalSalesAndRefundTaxData[0].total_tax}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>

                <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text ml={2.5} color={Colors.posTextInfo} fontWeight={300}>
                    Tax Refunded With Order
                  </Text>
                  <PosLable
                    ml={2.5}
                    name={"J"}
                    fontWeight={300}
                    mutedLabel={true}
                  />
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={300}>
                    ${totalSalesAndRefundTaxData[0].total_refunded_tax}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                {/* <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text
                    ml={2.5}
                    color={Colors.posTextInfo}
                    fontWeight={300}
                  >
                    Total Tip Refunded With Order
                  </Text>
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={300}>
                    ${totalSalesAndRefundTaxData[0].total_refunded_tip}
                  </Text>
                </Flex>
                <Divider mt={2}></Divider> */}

                <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text ml={2.5} color={Colors.posTextInfo} fontWeight={300}>
                    Tax Refunded Without Order
                  </Text>
                  <PosLable
                    ml={2.5}
                    name={"K"}
                    fontWeight={300}
                    mutedLabel={true}
                  />
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={300}>
                    $
                    {
                      totalSalesAndRefundTaxData[0]
                        .total_refunded_tax_without_order
                    }
                  </Text>
                </Flex>
                <Divider mt={2}></Divider>
                <Flex
                  mt={2}
                  // p={2.5}
                  // borderRadius={"0.63rem"}
                  // border={"1px solid rgba(183, 183, 183, 0.12)"}
                  // bg={Colors.posviewbgcolor}
                >
                  <Text ml={2.5} color={Colors.posTextInfo} fontWeight={500}>
                    Net Tax Collected 
                  </Text>
                  <PosLable
                    ml={2.5}
                    name={"L"}
                    mutedLabel={true}
                  />
                  <Spacer />
                  <Text color={Colors.posTextInfo} fontWeight={500}>
                    {Number(totalSalesAndRefundTaxData[0].net_tax_collected) < 0
                      ? `-$${Math.abs(
                          totalSalesAndRefundTaxData[0].net_tax_collected
                        ).toFixed(2)}`
                      : `$${Number(
                          totalSalesAndRefundTaxData[0].net_tax_collected
                        ).toFixed(2)}`}
                  </Text>
                </Flex>
                {/* //MATCH_SALES_REPORT - M=H-J-K */}
              </Box>
              <Box
                // mt={{ base: 4, md: 4 }}
                //   ml={{ base: "5%", lg: "25%" }}
                //   mr={{ base: "5%", lg: "25%" }}
                mb={{ base: 4, md: 4 }}
                borderRadius="0.63rem"
                bg={Colors.posPageDataBackground}
                // boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
                p="2"
                // bg="white"
                // p={2}
                boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
              >
                <PosListHeader header={"Tax Details"}></PosListHeader>
                <Box mt={8}>
                  <PosDataGridTable
                    columns={columnNames}
                    rows={modifiedData}
                    totalcount={totalcount}
                    // noAction={true}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPageModel) => {
                      pageRef.current = true;
                      setPaginationModel(newPageModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      pageRef.current = true;
                      sortRef.current = sortModel;
                      setSortModel(newSortModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.TAX_REPORT_CSV}
                  />
                </Box>
              </Box>
            </Flex>
          ) : (
            <PosNoDataFound title={"There are no Taxes."} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(TaxReport);
