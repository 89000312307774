import {
  Alert,
  AlertDescription,
  Box,
  ChakraProvider,
  Divider,
  Flex,
  Image,
  Img,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
  useRadioGroup
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Radio from "@mui/material/Radio";
import { ThemeProvider } from "@mui/material/styles";
import { GridToolbar } from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import warnignIcon from "../../../assets/images/Ellipse3.svg";
import coins from "../../../assets/images/game-icons.svg";
import home from "../../../assets/images/store.svg";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosFormButton,
  PosInput,
  PosLable,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { Mtheme, theme } from "../../../theme/index";
import { getAllRewardData } from "./RewardService";
const CardRadio = ({ children, isChecked, style, ...props }) => {
  return (
    <Box as="label" flex="1">
      <input type="radio" {...props} style={{ display: "none" }} />
      <Box
        cursor="pointer"
        borderWidth="0.19rem"
        borderRadius="md"
        boxShadow="md"
        color={isChecked ? "white" : "black"}
        borderColor={
          isChecked ? Colors.primeTaxInfo : "rgba(109, 145, 254, 0.08)"
        }
        // borderColor={isChecked ? "messenger.300" : "gray.300"}
        _hover={{
          bg: isChecked ? "messenger" : "gray.100",
        }}
        _checked={{
          bg: "teal.600",
          color: "white",
          borderColor: "#5881FE",
        }}
        height="100%"
        px={{ base: 2, md: 5 }}
        py={3}
        {...style}
      >
        {children}
      </Box>
    </Box>
  );
};
const RewardCreate = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [rewardData, setRewardData] = useState([]);
  const [selectedItemId, setSelectedItemId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortType, setSortType] = useState("desc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortColumn, setSortColumn] = useState("id");
  const [loading, setLoading] = useState(true);
  const [rewardCardData, setRewardCardSetData] = useState([]);
  const [selectedRewardType, setSelectedRewardType] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemDetails, setSelectedItemDetails] = useState(null);
  const [selectedCouponError, setSelectedCouponError] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const handleModelOpen = (item) => {
    onOpen();
    effectRun.current = false;
    fetchData();
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setRewardCardSetData(loaderResponse.data.data.data);
          // fetchData();
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.REWARDS_LIST_PATH);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.REWARD_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);
  const modalSize = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "2lg",
    lg: "4xl",
    xl: "6xl",
  });
  const modalSizes = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "2lg",
    lg: "md",
    xl: "lg",
  });
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionExtraColumn = isMobile ? { width: 284 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const columnNames = [
    {
      field: "selection",
      headerName: "",
      sortable: false,
      flex: 1,
      width: 90,
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton
          aria-label="view"
          onClick={() => handleRadioItemsChange(params)}
          sx={{ color: "#5881FE" }}
        >
          <Radio />
        </IconButton>
      ),
    },
    { field: "id", headerName: "Coupon Id", sortable: true, ...actionColumn },
    {
      field: "title",
      headerName: "Coupon Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "coupon_type",
      headerName: "Coupon Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "discount_type",
      headerName: "Discount Type",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "discount_value",
      headerName: "Discount Value",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "min_amount",
      headerName: "Minimum Amount",
      sortable: true,
      ...actionColumn,
    },
  ];
  const columnNamesProduct = [
    {
      field: "product_id",
      headerName: "Id",
      disableColumnMenu: true,
      sortable: false,
      width: 90,
    },
    {
      field: "name",
      headerName: "Name",
      disableColumnMenu: true,
      sortable: false,
      ...actionExtraColumn,
    },
    {
      field: "barcode",
      headerName: "Barcode",
      disableColumnMenu: true,
      sortable: false,
      ...actionExtraColumn,
    },
  ];
  const columnProductViewNames = [
    {
      columnNames: "Coupon Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Coupon Name",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Coupon Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "	Discount Type",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Discount Value",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Minimum Amount",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Product Details",
      sortName: "",
      width: "",
      renderHeader: () => <RemoveRedEyeIcon />,
      renderCell: () => (
        <IconButton onClick={handleProductDetailsClick}>
          <RemoveRedEyeIcon />
        </IconButton>
      ),
    },
    {
      columnNames: "Delete",
      sortName: "",
      width: "",
    },
  ];

  const handleRewardTypeChange = (value) => {
    setSelectedRewardType(value);
    // if (!formik || !formik.values) {
    //   return;
    // }
    if (
      selectedRewardType === "Points Earned on Payments Amounts" ||
      selectedRewardType === "Points Earned on Product Point"
    ) {
      formik.values.minimum_order_amount = "";
    } else {
      // if (!loaderResponse || !loaderResponse.data || !loaderResponse.data.data) {
      //   return;
      // }
      formik.values.minimum_order_amount =
        loaderResponse.data.data.minimum_order_amount || "";
    }
    formik.setFieldValue("reward_point_calculation_type_id", value);
  };

  const handleSingleDelete = (id) => {
    setSelectedItemId(null);
    setSelectedCouponError({
      message: Constants.REWARD_COUPON_REQUIRED,
    });
    setSelectedItemData([]);
  };

  const [ProductModels, setProductModels] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState([]);
  const handleRadioItemsChange = (params) => {
    const selectedRowData = params.row;
    onClose();
    setSelectedItemId(selectedRowData);
    const products = selectedRowData.products;

    const productMap = new Map();
    products.forEach((product) => {
      if (productMap.has(product.barcode)) {
        const existingProduct = productMap.get(product.barcode);
        existingProduct.quantity += product.quantity;
      } else {
        productMap.set(product.barcode, { ...product });
      }
    });
    const mergedProducts = Array.from(productMap.values());
    setSelectedItemData(mergedProducts);
    setProductModels(selectedRowData);
    setSelectedCouponError(null);
  };

  const handleProductDetailsClick = (item) => {
    setIsModalOpen(true);
    setSelectedItemDetails(item);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItemDetails(null);
  };

  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    products: false,
    id: false,
  });

  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      page: paginationModel.page + 1,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      filter: searchQuery,
      coupon: "[]",
    };
    try {
      getAllRewardData(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setRewardData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            setRewardData([]);
            setTotalCount(0);
            setLoading(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setRewardData([]);
          setTotalCount(0);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setRewardData([]);
      setTotalCount(0);
      setLoading(false);
      setShowTable(false);
      myContext.handleLoading(false);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      formik.handleChange(e);
    }
  };

  const handleSearchList = () => {
    paginationModel.page = 0;
    fetchData();
  };
  const handleClear = () => {
    // clear input value and get all data
    setSearchQuery("");
    effectRun.current = true;
  };
  useEffect(() => {
    if (effectRun.current === true) {
      fetchData();
      effectRun.current = false;
    }
  }, [searchQuery, paginationModel, sortModel, loading]);

  // useEffect(() => {
  //   fetchData();
  // }, [paginationModel, sortModel]);

  const mapTypeToBackendValue = (type) => {
    switch (type) {
      case "Points Earned on Product Points":
        return 1;
      case "Points Earned on Payment Amounts":
        return 2;
      case "Points Earned on Visits":
        return 3;
      default:
        return 1;
    }
  };
  const onCloseOpenModal = () => {
    setSearchQuery("");
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    onClose();
  };
  const formik = useFormik({
    initialValues: {
      name: "",
      reward_point_calculation_type_id: "",
      visit_type_reward_exist: 1,
      required_reward_points: "",
      minimum_order_amount:
        undefined !== loaderResponse?.data?.data?.minimum_order_amount &&
        null != loaderResponse?.data?.data?.minimum_order_amount
          ? loaderResponse?.data?.data?.minimum_order_amount
          : "",
      coupon: selectedItemData,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.REWARD_NAME_REQUIRED)
        .max(255, Constants.REWARD_NAME_LENGTH_GREATER_THAN_255),
      reward_point_calculation_type_id: Yup.string().required(
        Constants.REWARD_POINT_CALCULATION_TYPE
      ),
      required_reward_points: Yup.number()
        .required(Constants.REWARD_REQUIRED_REWARD_POINT)
        .min(0, Constants.REWARD_VALID_REQUIRED_REWARD_POINTS)
        .max(2147483647, Constants.REWARD_REQUIRED_REWARD_POINTS_MAX_VALUE)
        .when("reward_point_calculation_type_id", {
          is: "Points Earned on Visits",
          then: (schema) =>
            schema.min(1, Constants.REWARD_REQUIRED_REWARD_POINT_VISIT),
        }),
      minimum_order_amount: Yup.number()
        .typeError("The minimum order amount must be a integer")
        .min(0.01, Constants.REWARD_VALID_AMOUNT_ORDER)
        .max(999999.99, Constants.REWARD_ORDER_AMOUNT_MAX_VALUE)
        .when("reward_point_calculation_type_id", {
          is: "Points Earned on Visits",
          then: (schema) =>
            schema
              .required(Constants.REWARD_MINIMUM_ORDER_AMOUNT)
              .min(0.01, Constants.REWARD_ORDER_AMOUNT_MIN_VALUE),
        }),
    }),
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      const couponIdArray =
        typeof selectedItemId === "object" ? [selectedItemId] : [];
      const couponId = JSON.stringify(couponIdArray);
      let minimumOrderAmount = "";
      if (selectedRewardType === "Points Earned on Visits") {
        minimumOrderAmount = twofix(formik.values.minimum_order_amount);
      } else {
        minimumOrderAmount = "";
      }
      const payload = {
        name: formik.values.name,
        reward_point_calculation_type_id: mapTypeToBackendValue(
          selectedRewardType
        ),
        visit_type_reward_exist: parseInt(
          formik.values.visit_type_reward_exist
        ),
        required_reward_points: parseInt(formik.values.required_reward_points),
        minimum_order_amount: minimumOrderAmount,
        coupon_id: couponId,
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.REWARD_CREATE_API_ROUTE,
      });
    },
  });

  const handleKeyDown = (event) => {
    // if (
    //   event.key === "Enter" &&
    //   !(formik.values.name?.length <= 0 ||
    //   !selectedItemId ||
    //   selectedItemId?.id === 0 ||
    //   // (selectedRewardType === "Points Earned on Visits" && !formik.values.minimum_order_amount) ||
    //   formik.values.reward_point_calculation_type_id === "" ||
    //   formik.values.required_reward_points === "" ||
    //   formik.errors.reward_point_calculation_type_id ||
    //   formik.errors.required_reward_points ||
    //   formik.errors.name ||
    //   buttonDisable
    //     ? true
    //     : false)
    // ) {
    //   formik.handleSubmit();
    // }
    if (event.key === "Enter" && !buttonDisable) {
      handleSubmitButton();
    }
  };

  const handleSubmitButton = () => {
    formik.setTouched(
      {
        name: true,
        reward_point_calculation_type_id: true,
        required_reward_points: true,
        minimum_order_amount: true,
      },
      true
    );

    // Run validation manually
    if (selectedItemData?.length <= 0 &&
      (null == selectedItemId?.id || selectedItemId?.id == 0)) {
      setSelectedCouponError({
        message: Constants.REWARD_COUPON_REQUIRED,
      });
      return;
    }
    formik.handleSubmit();
  };

  const rewardTypeOptions = [
    {
      title: "Points Earned on Product Points",
      body:
        "If selected, customers will earn points that are assigned to each product. You can assign points to a product when you create or edit it.",
      type: "Points Earned on Product Points",
      icon: <ShoppingCartIcon sx={{ fill: Colors.taxInfo }} />,
    },
    {
      title: "Points Earned on Payment Amounts",
      body: "If selected, customers will earn one point for each dollar spent.",
      type: "Points Earned on Payment Amounts",
      icon: (
        <Img src={coins} alt="Shopping Cart" style={{ width: "1.56rem" }} />
      ),
    },
    {
      title: "Points Earned on Visits",
      body:
        "If selected, customers will earn one point for each visit to the business. You can set the minimum spend per visit.",
      type: "Points Earned on Visits",
      icon: <Img src={home} alt="Home" style={{ width: "1.25rem" }} />,
    },
  ];

  const {
    getRootProps: getRewardTypeRootProps,
    getRadioProps: getRewardTypeRadioProps,
  } = useRadioGroup({
    name: "reward_points_calculation_type_id",
    defaultValue: "",
    onChange: handleRewardTypeChange,
  });

  const modifiedDatas = selectedItemData.map((data) => ({
    id: data.id || "N.A.",
    product_id: data.id || "N.A.",
    name: data.name || "N.A.",
    barcode: data.barcode || "N.A.",
  }));

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const modifiedViewData = selectedItemId
    ? [selectedItemId].map((item) => ({
        id: item.id,
        title: item.title,
        coupon_type: item.coupon_type,
        discount_type: item.discount_type,
        discount_value: item.discount_value,
        min_amount: item.min_amount || "N.A.",
        productDetailsIcon:
          item.products && item.products.length > 0 ? (
            <IconButton
              style={{
                backgroundColor: isHovered ? "#5881fe1a" : "#F4F4F4",
                color: isHovered ? "#010048" : "#010048",
                transition: "background-color 0.3s, color 0.3s",
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleProductDetailsClick(item)}
            >
              <RemoveRedEyeIcon style={{ fontSize: "1.25rem" }} />
            </IconButton>
          ) : (
            "N.A."
          ),
      }))
    : [];
  const modifiedData = rewardData.map((reward) => ({
    id: reward.id,
    title: reward.title,
    coupon_type: reward.coupon_type === "prod" ? "Product" : "Order",
    discount_type: reward.discount_type === "amou" ? "Amount" : "Percentage",
    discount_value: reward.discount_value,
    min_amount: reward.min_amount || "N.A.",
    products: reward.products,
  }));

  const sortRef = useRef(sortModel);
  const rewardTypeGroup = getRewardTypeRootProps();
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box
        flex="1"
        pb="3.31rem"
        w={isMobile ? "100vw" : "calc(100vw - 263.2px)"}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <Box>
            <PosBreadCrumb
              handleClick={(i) => {
                props.navigate(Constants.REWARD_PATH, {
                  state: stateData.state,
                });
              }}
              breadCrumNames={["Reward ", "Create a Reward"]}
              breadCrumTitle={"Create a Reward"}
            />
          </Box>
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              buttonText={"Cancel"}
              CancelButton={true}
              onClick={() => {
                // pass param data in modifier set list page
                props.navigate(Constants.REWARD_PATH, {
                  state: stateData.state,
                });
              }}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              isDisabled={buttonDisable}
              buttonsubmit={"Submit"}
              SubmitButton={true}
              // isDisabled={
              //   formik.values.name?.length === 0 ||
              //   !selectedItemId ||
              //   selectedItemId?.id === 0 ||
              //   (selectedRewardType == "Points Earned on Visits" &&
              //     (formik.values.minimum_order_amount == null ||
              //       "" == formik.values.minimum_order_amount ||
              //       formik.errors.minimum_order_amount)) ||
              //   formik.values.reward_point_calculation_type_id === "" ||
              //   formik.values.required_reward_points === "" ||
              //   formik.errors.name ||
              //   formik.errors.reward_point_calculation_type_id ||
              //   formik.errors.required_reward_points ||
              //   buttonDisable
              //     ? true
              //     : false
              // }
              onClick={handleSubmitButton}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 2, md: "2rem" }}
          ml={{ base: 4, md: "3.44rem" }}
          mr={{ base: 4, md: "2.75rem" }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
          // pt={{ base: "8", md: "3.22rem" }}
          // pl={{ base: "8", md: "1.75rem" }}
          // pr={{ base: "8", md: "1.81rem" }}
          // pb={{ base: "8", md: "2.38rem" }}
        >
          <Text
            fontSize="1.23rem"
            w="10.5rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontSize: "1.13rem",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
          >
            Basic Details
          </Text>
          <Box>
            <Stack spacing={0}>
              <PosLable
                name={"Reward Name"}
                requiredLabel={true}
                fontSize="0.94rem"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                letterSpacing="-0.02rem"
              />
              <PosInput
                id="name"
                mt={"1rem"}
                placeholder={"Enter a Reward name"}
                color={Colors.posCommon}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.name}
                handleBlur={(e) => {
                  formik.setFieldValue("name", formik.values.name.trim());
                  formik.handleBlur(e);
                }}
                onKeyDown={handleKeyDown}
                posInputGroup={true}
                inputType={"text"}
                inputError={formik.touched.name && formik.errors.name}
              >
                <DriveFileRenameOutlineIcon
                  sx={{
                    height: "1.21rem",
                    width: "1.21rem",
                    background: Colors.posInputGroupBackground,
                    borderRadius: "0.31rem",
                    color: Colors.posNavbarLink,
                  }}
                />
              </PosInput>
              <Text color={Colors.posCancleButtonRed}>
                {formik.touched.name && formik.errors.name ? (
                  <span>{formik.errors.name}</span>
                ) : null}
              </Text>
            </Stack>
          </Box>
          <Flex flexDirection={"row"}>
            <PosLable
              name={"Reward Point Calculation Type"}
              requiredLabel={true}
              fontSize="0.94rem"
              fontStyle="normal"
              fontWeight={500}
              lineHeight="normal"
              letterSpacing="-0.02rem"
            />
            <Tippy
              content="Choose from multiple types of reward point calculations, Either on the Total amount of the order or on the number of visits, or by specific product. Points on products bought are the points mentioned while creating the product."
              interactive={true}
              maxWidth={390}
              placement="top"
              animation="fade"
              inertia={true}
              moveTransition="transform 0.2s ease-out"
              theme="tomato"
            >
              <Image src={toolTip} alt="Tooltip" mt={"2rem"} ml={"0.3rem"} />
            </Tippy>
          </Flex>
          {/* <Text
            color={"#010048b3"}
            fontSize="0.94rem"
            fontStyle="normal"
            fontWeight={300}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          >
            Choose from the multiple types of reward point calculations
          </Text> */}
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start", md: "stretch" }}
            flexDirection={{ base: "column", md: "row" }}
            mt={4}
            // mb={4}
            gap={4}
            {...rewardTypeGroup}
          >
            {rewardTypeOptions.map((option, index) => {
              const radio = getRewardTypeRadioProps({ value: option.type });
              return (
                <CardRadio
                  key={index}
                  isChecked={selectedRewardType === option.type}
                  {...radio}
                >
                  <Flex flexDirection="row" alignItems="center" gap={2}>
                    {option.icon}
                    <Text
                      fontWeight="bold"
                      color={Colors.posTextInfo}
                      textAlign="start"
                    >
                      {option.title}
                    </Text>
                  </Flex>
                  <Text
                    mt={"0.63rem"}
                    color={Colors.couponDropDownLabelColor}
                    style={{
                      fontSize: "0.94rem",
                      fontStyle: "normal",
                      fontWeight: 300,
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                    }}
                  >
                    {option.body}
                  </Text>
                </CardRadio>
              );
            })}
          </Flex>
          {formik.touched.reward_point_calculation_type_id &&
            formik.errors.reward_point_calculation_type_id && (
              <Text color={Colors.posCancleButtonRed} mt={1}>
                {formik.errors.reward_point_calculation_type_id}
              </Text>
            )}
          <Divider mt={"2rem"} />
          <PosLable
            name={"Required Reward Points"}
            requiredLabel={true}
            fontSize="0.94rem"
            fontStyle="normal"
            fontWeight={500}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          />
          <Text
            color={"#010048b3"}
            fontSize="0.94rem"
            fontStyle="normal"
            fontWeight={300}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          >
            Set points customers will have to earn to receive this reward
          </Text>
          <Stack spacing={0} mt="0.87rem">
            <PosInput
              id={"required_reward_points"}
              placeholder={"Enter a Required Reward Points"}
              handleInputChange={handleChange}
              inputValue={formik.values.required_reward_points}
              handleBlur={formik.handleBlur}
              onKeyDown={handleKeyDown}
              inputType={"number"}
              posNumberInput={true}
              inputError={
                formik.touched.required_reward_points &&
                formik.errors.required_reward_points
              }
            />
            <Text color={Colors.posCancleButtonRed}>
              {formik.touched.required_reward_points &&
              formik.errors.required_reward_points ? (
                <span>{formik.errors.required_reward_points}</span>
              ) : null}
            </Text>
          </Stack>
          {selectedRewardType === "Points Earned on Visits" && (
            <>
            <Stack>
              <PosLable
                name={"Minimum Order Amount"}
                requiredLabel={true}
                fontSize="0.94rem"
                fontStyle="normal"
                fontWeight={500}
                lineHeight="normal"
                letterSpacing="-0.02rem"
              />
              <Alert
                // width={{ base: "0", md: "1009px" }}
                h={{ base: "7rem", md: 16 }}
                bg={Colors.errorsign} //props
                status={"error"}
                borderRadius={"0.63rem"}
              >
                <Image boxSize={4} src={warnignIcon} alt="Octopos" />
                <AlertDescription
                  fontSize={"1rem"}
                  fontWeight={300}
                  color={Colors.errorColor}
                  pl={3}
                >
                  Changing the minimum order amount will be applicable to all
                  other previously created "Points Earned on Visits" type
                  rewards.
                </AlertDescription>
              </Alert>
              <PosInput
                id="minimum_order_amount"
                mt={"1rem"}
                placeholder={"Enter a Minimum Order Amount"}
                color={Colors.posCommon}
                // handleInputChange={formik.handleChange}
                inputValue={formik.values.minimum_order_amount}
                // handleBlur={formik.handleBlur}
                // handleBlur={(e) => {
                //   formik.handleBlur(e);
                //   formik.setFieldValue(
                //     "minimum_order_amount",
                //     twofix(formik.values.minimum_order_amount)
                //   );
                // }}
                handleBlur={(e) => {
                  formik.setFieldTouched('minimum_order_amount', true);
                  formik.setFieldValue('minimum_order_amount', e.target.value);
                }}
                handleInputChange={(e) => {
                  formik.setFieldTouched('minimum_order_amount', true);
                  formik.setFieldValue('minimum_order_amount', e.target.value);
                }}
                onKeyDown={handleKeyDown}
                posDecimalNumberInput={true}
                // inputType={"text"}
                inputError={
                  formik.touched.minimum_order_amount &&
                  formik.errors.minimum_order_amount
                }
              ></PosInput>
            </Stack>
            <Text color={Colors.posCancleButtonRed}>
              {formik.touched.minimum_order_amount &&
              formik.errors.minimum_order_amount ? (
                <span>{formik.errors.minimum_order_amount}</span>
              ) : null}
            </Text>
          </>
          )}
        </Box>
        <Box
          mt={{ base: 2, md: "0.63rem" }}
          ml={{ base: 4, md: "3.44rem" }}
          mr={{ base: 4, md: "2.75rem" }}
          borderRadius="0.63rem"
          // w={'calc(100vw - 32px)'}
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="2.5"
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "satrt" }}
            pt={{ base: "1.5rem", md: "2.5rem" }}
            pb={{ base: "1.5rem", md: "2rem" }}
            pl={{ base: "1rem", md: "1rem" }}
            pr={{ base: "0.5rem", md: "1.56rem" }}
            gap={2}
          >
            <Box>
              <Flex flexDirection={"row"}>
                <Text
                  fontSize="1.13rem"
                  color={Colors.posTextInfo}
                  style={{
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                    letterSpacing: "-0.01rem",
                  }}
                >
                  Add Coupon to Reward
                </Text>
                <Tippy
                  content="Add a coupon from the coupons created. Make sure that the coupon made is Targeted. This coupon is what will be redeemed against the Points when it reaches that amount."
                  interactive={true}
                  maxWidth={390}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  <Image
                    src={toolTip}
                    alt="Tooltip"
                    ml={{ base: "0.3rem", md: "0.3rem" }}
                  />
                </Tippy>
              </Flex>
              {/* <Text
                color={"#010048"}
                fontSize="0.94rem"
                fontStyle="normal"
                fontWeight={300}
                lineHeight="normal"
                letterSpacing="-0.02rem"
              >
                Create targeted coupons and redeem them against accumulated
                points
              </Text> */}
            </Box>
            <Spacer />
            <Flex flexDirection="column" alignItems="flex-end" mt={"2rem"}>
              <PosFormButton
                buttonsubmit={"Add Coupon"}
                width={"9.25rem"}
                onClick={handleModelOpen}
                isDisabled={
                  (modifiedViewData.length > 0 &&
                    !formik.values.apply_on_all_prods) ||
                  buttonDisable
                    ? true
                    : false
                }
              />
              {selectedCouponError && selectedItemData?.length == 0 && (
                <Text color={Colors.errorColor} alignSelf={"flex-end"}>
                  {selectedCouponError.message}
                </Text>
              )}
            </Flex>
          </Flex>
          <>
            <ThemeProvider theme={Mtheme}>
              <Dialog
                open={isOpen}
                // onClose={() => setModal(false)}
                fullWidth={true}
                maxWidth={modalSizes}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle
                  id="alert-dialog-title"
                  sx={{
                    fontSize: "1.5rem",
                    fontWeight: 600,
                    color: Colors.posTextInfo,
                  }}
                >
                  <Text
                    pt={{ md: "2.31rem" }}
                    pr={{ base: "2rem", md: "10rem" }}
                    style={{ flex: 1 }}
                  >
                    Add Coupon
                  </Text>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      // onClose();
                      onCloseOpenModal();
                    }}
                    sx={{
                      position: "absolute",
                      right: 14,
                      top: 14,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>

                  <ChakraProvider theme={theme}>
                    <Box mt={2}>
                      <PosSearch
                        // mb={3.5}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onSearch={handleSearchList}
                        handleClear={handleClear}
                      />
                    </Box>
                  </ChakraProvider>
                </DialogTitle>
                <DialogContent>
                  {showTable ? null : loading ? (
                    <PosProgress />
                  ) : modifiedData.length > 0 ? (
                    <Box
                      sx={{
                        overflowX: "auto",
                        maxHeight: "38.91rem",
                        maxWidth: "70.75rem",
                        "& .Coupon Name": {
                          backgroundColor: "#38128A",
                        },
                      }}
                    >
                      <PosDataGridTable
                        columns={columnNames}
                        rows={modifiedData}
                        totalcount={totalcount}
                        // noAction={true}
                        columnVisibilityModel={olColumnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                          setOlColumnVisibilityModel(newModel)
                        }
                        paginationModel={paginationModel}
                        paginationMode="server"
                        sortingMode="server"
                        radioTable={true}
                        onPaginationModelChange={(newPaginationModel) => {
                          effectRun.current = true;
                          setPaginationModel(newPaginationModel);
                        }}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => {
                          sortRef.current = sortModel;
                          setSortModel(newSortModel);
                        }}
                        slots={{
                          toolbar: GridToolbar,
                        }}
                        fileName={Constants.REWARD_CSV}
                      />
                    </Box>
                  ) : (
                    <PosNoDataFound title={Constants.NO_DATA_FOUND} />
                  )}
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            </ThemeProvider>
          </>
          <>
            {isModalOpen && selectedItemDetails && (
              <ThemeProvider theme={Mtheme}>
                <Dialog
                  open={isModalOpen}
                  // onClose={() => setModal(false)}
                  fullWidth={true}
                  maxWidth={modalSizes}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      color: Colors.posTextInfo,
                    }}
                  >
                    <Text
                      pt={"2.31rem"}
                      pr={{ base: "2rem", md: "10rem" }}
                      style={{ flex: 1 }}
                    >
                      Product Details
                    </Text>
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        // onClose();
                        handleCloseModal();
                      }}
                      sx={{
                        position: "absolute",
                        right: 14,
                        top: 14,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <Box mb={{ base: "0.88rem", md: "0.88rem" }}></Box>
                    {showTable ? null : loading ? (
                      <PosProgress />
                    ) : modifiedDatas.length > 0 ? (
                      <Box
                        style={{
                          overflowX: "auto",
                          maxHeight: "38.91rem",
                          maxWidth: "70.75rem",
                        }}
                      >
                        <PosDataGridTable
                          columns={columnNamesProduct}
                          rows={modifiedDatas}
                          totalcount={totalcount}
                          // noAction={true}
                          hideFooter={true}
                          columnVisibilityModel={olColumnVisibilityModel}
                          onColumnVisibilityModelChange={(newModel) =>
                            setOlColumnVisibilityModel(newModel)
                          }
                          slots={{
                            toolbar: GridToolbar,
                          }}
                          paginationModel={paginationModel}
                          paginationMode="server"
                          onPaginationModelChange={(newPaginationModel) => {
                            effectRun.current = true;
                            setPaginationModel(newPaginationModel);
                          }}
                          sortModel={sortModel}
                          onSortModelChange={(newSortModel) => {
                            sortRef.current = sortModel;
                            setSortModel(newSortModel);
                          }}
                          fileName={Constants.REWARD_PRODUCT_CSV}
                        />
                      </Box>
                    ) : (
                      <PosNoDataFound title={Constants.NO_DATA_FOUND} />
                    )}
                  </DialogContent>
                  <DialogActions></DialogActions>
                </Dialog>
              </ThemeProvider>
            )}
          </>
          {!formik.values.apply_on_all_prods && modifiedViewData.length > 0 && (
            <Box
              borderRadius="0.63rem"
              mt={3}
              width={"100%"}
              bg={Colors.posPageDataBackground}
              boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
            >
              <PosTable
                columnNames={columnProductViewNames}
                rawdata={modifiedViewData}
                totalcount={totalcount}
                // noAction={true}
                footerHide={true}
                singleDeleteButton={true}
                goSingleDelete={(id) => handleSingleDelete(id)}
                sortColumnName={sortColumn}
                page={page}
                rowsPerPage={rowsPerPage}
                order={sortType}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default WithRouter(RewardCreate);
