import { Box, Flex, Select, Spacer, Stack, Text } from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useParams,
  useSubmit,
  useLocation
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosLable,
  PosTostMessage,
  PosDropDown
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

const MasterAdminPosStationEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { id } = useParams();
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const stateData = useLocation(); // get param data
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [allTouchedField, setAllTouchedField] = useState(false);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          setLocations(loaderResponse.data.data?.location);
          setSelectedLocation(loaderResponse.data?.data?.station?.location);
        }
      }
      // return () => {
        effectRun.current = false;
      // };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_STATIONS_PATH,{
          state: paramData,
        });
        myContext.handleLoading(false);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.POS_MODULE,
          alertDescription: actionResponse.data.message,
        });
      } else {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value);
    formik.setFieldValue("location_id",value);
  };

  const formik = useFormik({
    initialValues: {
      name:
        undefined != loaderResponse?.data?.data?.station?.name &&
        null != loaderResponse?.data?.data?.station?.name
          ? loaderResponse?.data?.data?.station?.name
          : "",
      location_id:
        loaderResponse?.data?.data?.station?.location &&
        null != loaderResponse?.data?.data?.station?.location
          ? loaderResponse?.data?.data?.station?.location
          : "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(Constants.POS_NAME_REQUIRED)
        .max(191, Constants.POS_NAME_LESS_OR_EQUAL_191_CHARACTER),
      // location_id: Yup.array()
      //   .min(1,Constants.POS_LOCATION_REQUIRED),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      const payload = {
        ...values,
        location_id: selectedLocation.id,
      };
      setButtonDisable(true);
      actionRun.current = true;
      setAllTouchedField(true);
      submit(payload, {
        method: Constants.POST,
        path: Constants.POS_CREATE_API_ROUTE,
      });
    },
  });
  const handleKeyDown = (event) => {
    // if (
    //   event.key === "Enter" &&
    //   !(
    //     formik.values.name.length <= 0 ||
    //     formik.errors.name ||
    //     selectedLocation == null ||
    //     selectedLocation == "" ||
    //     buttonDisable
    //   )
    // ) {
    //   formik.handleSubmit();
    // }
    if (event.key === "Enter") {
      if (!formik.isValid) {
        if (!allTouchedField) {
          formik.handleSubmit();
          setAllTouchedField(true);
        }
      } else {
        formik.handleSubmit();
      }
    }
  };
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" onKeyDown={handleKeyDown}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_STATIONS_PATH,{
                state: stateData.state,
              });
            }}
            breadCrumNames={["POS Stations", "Edit POS Station"]}
            breadCrumTitle={"Edit POS Station"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate("/" + process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE + Constants.MASTER_ADMIN_POS_STATIONS_PATH,{
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              isDisabled={buttonDisable}
              // isDisabled={
              //   formik.values.name.length <= 0 ||
              //   formik.errors.name ||
              //   selectedLocation == null ||
              //   selectedLocation == "" ||
              //   buttonDisable
              //     ? true
              //     : false
              // }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              // onClick={formik.handleSubmit}
              onClick={() => {
                if (!formik.isValid) {
                  if (!allTouchedField) {
                    formik.handleSubmit();
                    setAllTouchedField(true);
                  }
                } else {
                  formik.handleSubmit();
                }
              }}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
        >
          <Text
            fontSize="1.5rem"
            color={Colors.taxShow}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Basic Details
          </Text>
          <Stack spacing={0} mt="0.31rem">
            <PosLable fontWeight={"500"} requiredLabel={true} name={"POS Name"} mt="0.31rem" />
            <PosInput
              posInputGroup={true}
              id={"name"}
              name={`name`}
              inputType={"text"}
              placeholder={"Enter POS Station Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.name}
              handleBlur={formik.handleBlur}
              inputError={formik.touched.name && formik.errors.name}
            >
              <DriveFileRenameOutlineIcon style={{ fill: "#5881FE" }} />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.name && formik.errors.name ? (
                <span>{formik.errors.name}</span>
              ) : null}
            </Text>
          </Stack>
          <Stack spacing={0}>
            <Box className="card justify-content-center">
              <PosLable
                name={"Location"}
                requiredLabel={true}
                fontWeight={"500"}
                label={true}
              ></PosLable>
              <PosDropDown
                id="location_id" 
                options={locations}
                value={selectedLocation}
                onChange={(e) => {
                  handleSelectedLocation(e.target.value);
                }}                
                  onBlur={(e) => {
                  formik.setFieldTouched("location_id", true);
                  formik.handleBlur(e);
                }}
                //   multiSelect={true}
                optionLabel="name"
                placeholder="Select Location"
                width={"100%"}
                //   height={"2.5rem"}
                className="w-full "
                maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.location_id && formik.errors.location_id ? (
                  <span>{formik.errors.location_id}</span>
                ) : null}
              </Text>
              {/* {locations.length > 0 ? (
                <Select
                  value={selectedLocation}
                  color="#010048"
                  id="location_id"
                  onChange={(e) => {
                    handleSelectedLocation(e.target.value);
                  }}
                >
                  <option selected disabled value="">
                    --Select a Location--
                  </option>
                  {locations.map((data) => (
                    <option value={data.id}>{data.name}</option>
                  ))}
                </Select>
              ) : (
                <Select id="state">
                  <option selected hidden disabled value={"N.A."}>
                    {"N.A."}
                  </option>
                </Select>
              )} */}
            </Box>
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
};

export default WithRouter(MasterAdminPosStationEdit);
