import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import { SimpleReportLayout } from "../../../../.././layouts/index";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDropDown,
  PosFormButton,
  PosNoDataFound,
  PosProgress,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
  twofix,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { downloadPDF } from "./CustomerUnusedCreditService";
const CustomerUnusedCredit = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [creditCustomerBalanceData, setCreditCustomerBalanceData] = useState(
    []
  );
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [applyClicked, setApplyClicked] = useState(false);
  const stateData = useLocation();
  const [totalDailySales, seltTotalDailySales] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "balance",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);
  const [isLarge] = useMediaQuery("(min-width: 906px)");
  const [isSmall] = useMediaQuery("(min-width: 571px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 768px)");
  const paginationRef = useRef(paginationModel);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    id: false,
  });
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 1.3 };
  const columnNames = [
    { field: "id", headerName: "Sr No.", sortable: true, width: 120 },
    {
      field: "customer_name",
      headerName: "Business Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "customer_email",
      headerName: "Email",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "customer_phone",
      headerName: "Phone",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "balance",
      headerName: "Amount($)",
      sortable: true,
      ...actionColumn,
    },
  ];

  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedCustomers: [],
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedCustomers: Yup.array().min(
        1,
        Constants.CUSTOMER_UNUSED_SELECT_CUSTOMER
      ),
    }),
    onSubmit: async (values) => {},
  });

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLoading(true);
          setCustomers(loaderResponse?.data?.data?.customer);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    // getCustomerUnusedCreditData();
  }, []);
  useEffect(() => {
    if (paginationRef.current !== paginationModel) {
      getCustomerUnusedCreditData();
      paginationRef.current = paginationModel;
    } else if (sortRef.current !== sortModel) {
      getCustomerUnusedCreditData();
      sortModel.current = sortModel;
    }
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Customer Unused Credit Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, "CustomerUnusedCredit.xlsx");
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.unUsedCredit);
          setCreditCustomerBalanceData(
            actionResponse.data.data.allCustomerLatestCreditBalance
          );
          let data = [
            "Total($)",
            twofix(actionResponse.data.data.totalCreditBalance),
          ];
          seltTotalDailySales(data);
          setTotalCount(actionResponse.data.data.totalItems);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);

  const handleExportReport = () => {
    formik.setTouched(
      {
        selectedCustomers: true,
      },
      true
    );
    if(formik.values.selectedCustomers?.length === 0 ||
      formik.errors.selectedCustomers){
        return;
    }
    if (
      formik.values.selectedCustomers.length > 0 &&
      null != formik.values.selectedCustomers &&
      "" != formik.values.selectedCustomers
    ) {
    myContext.handleLoading(true);
      const allCustomersData =
        formik.values.selectedCustomers.length > 0
          ? JSON.stringify(formik.values.selectedCustomers)
          : JSON.stringify(customers);
      let data = {
        getreport: false,
        type: "xls",
        customer: allCustomersData,
        //   zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      if (formik.values.fromDate && formik.values.toDate) {
        data.dateFrom = reportDateFormate(formik.values.fromDate);
        data.dateTo = reportDateFormate(formik.values.toDate);
      }
      submit(data, {
        method: Constants.POST,
        path: Constants.TOTAL_DAILY_SALE,
      });
    // } else {
    //   clearTableData(Constants.CUSTOMER_IS_REQUIRED);
    }
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getCustomerUnusedCreditData = () => {
    formik.setTouched(
      {
        selectedCustomers: true,
      },
      true
    );
    if(formik.values.selectedCustomers?.length === 0 ||
      formik.errors.selectedCustomers){
        return;
    }
    if (formik.values.selectedCustomers.length > 0) {
      setButtonDisable(true);
      setLoading(true);
      setShowTable(false);
      setApplyClicked(true);
      const allCustomersData =
        formik.values.selectedCustomers.length > 0
          ? JSON.stringify(formik.values.selectedCustomers)
          : JSON.stringify(customers);
      let data = {
        getreport: true,
        customer: allCustomersData,
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.CUSTOMER_UNUSED_CREDIT_ROUTE,
      });
    }
  };

  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedCustomers", e.value);
  };

  const handleResetAllData = () => {
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
    setApplyClicked(false);
  };
  let counter = 1;
  const modifiedData = tableData.map((data) => ({
    id: counter++,
    customer_name: data.customer_name || "N.A",
    customer_email: data.customer_email || "N.A",
    customer_phone: data.customer_phone || "N.A",
    balance: data.balance,
  }));

  const handlePdfReport = () => {
    formik.setTouched(
      {
        selectedCustomers: true,
      },
      true
    );
    if(formik.values.selectedCustomers?.length === 0 ||
      formik.errors.selectedCustomers){
        return;
    }
    if (
      formik.values.selectedCustomers.length > 0 &&
      null != formik.values.selectedCustomers &&
      "" != formik.values.selectedCustomers
    ) {
    myContext.handleLoading(true);
      const allCustomersData =
        formik.values.selectedCustomers.length > 0
          ? JSON.stringify(formik.values.selectedCustomers)
          : JSON.stringify(customers);
      let data = {
        // getreport: false,
        // type: "xls",
        customer: formik.values.selectedCustomers,
        // transactionTypes: formik.values.selectedPaymentType,
        // zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        query: {
          limit: paginationModel.pageSize,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        },
      };
      try {
        downloadPDF(data)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              var url = Constants.REACT_APP_API_URL + response.data.data;
              // var url = `${Constants.REACT_APP_API_URL}${response.data.data}`;
              window.open(url, "_blank");
              setLoading(false);
              myContext.handleLoading(false);
            } else {
              setLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setLoading(false);
        myContext.handleLoading(false);
      }
    // } else {
    //   clearTableData(Constants.CUSTOMER_IS_REQUIRED);
    }
  };
  const renderReportLayout = () => {
    return (
      <>
        <Box className="card justify-content-center">
          <PosDropDown
            options={customers}
            value={formik.values.selectedCustomers}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedCustomers");
              formik.handleBlur(e);
            }}
            resetFilterOnHide={true}
            multiSelect={true}
            optionLabel="name"
            placeholder="Select Customers*"
            width={
              isExtraSmall
                ? "100%"
                : isLarge || (isSmall && isMeduim)
                ? "20rem"
                : "13.25rem"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
            maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedCustomers &&
            formik.errors.selectedCustomers ? (
              <span>{formik.errors.selectedCustomers}</span>
            ) : null}
          </Text>
        </Box>
      </>
    );
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Customer Unused Credit"]}
          breadCrumTitle={"Customer Unused Credit"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Export
              </MenuButton>
              <MenuList>
                <MenuItem onClick={handleExportReport}>Export Excel</MenuItem>
                <MenuItem onClick={handlePdfReport}>Export PDF</MenuItem>
              </MenuList>
            </Menu>
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isExtraSmall ? "column" : "row"}
          justifyContent={"space-between"}
        >
          <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>

          <Stack
            direction={"row"}
            justifyContent={isExtraSmall ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isExtraSmall ? 4 : 0}
            pt={isExtraSmall ? 0 : 4}
            pr={4}
          >
            <PosFormButton
              isDisabled={applyClicked ? false : true}
              onClick={handleResetAllData}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={buttonDisable}
              // isDisabled={
                // formik.values.selectedCustomers?.length === 0 ||
                // formik.errors.selectedCustomers ||
              //   buttonDisable
              //     ? true
              //     : false
              // }
              onClick={getCustomerUnusedCreditData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                // noAction={true}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={setPaginationModel}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.CUSTOMER_UNUSED_CREDIT_REPORT_CSV}
              />
              <Table>
                <TableHead sx={{ backgroundColor: Colors.modifierTable }}>
                  <TableRow>
                    {totalDailySales.map((column, index) => {
                      return (
                        <TableCell
                          key={index}
                          sx={{
                            fontWeight: 500,
                            fontSize: "1rem",
                            lineHeight: "1.18rem",
                            letterSpacing: "-1.5%",
                            color: Colors.posTextInfo,
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          {column}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              </Table>
            </Flex>
          ) : (
            <PosNoDataFound title={Constants.CUSTOMER_UNUSED_CREDIT_NO_DATA} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(CustomerUnusedCredit);
