import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Wrap,
  WrapItem,
  useMediaQuery,
} from "@chakra-ui/react";
import { SimpleReportLayout } from "../../../../../layouts/index";
import CloseIcon from "@mui/icons-material/Close";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useSubmit,
  useLocation,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSpliteButton,
  PosTab,
  PosTostMessage,
  PosIconButton,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  startOfTodaysDay,
  commonDateFormate,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";

import { getDectivePayInList, getPayInList } from "./PayInService";
const PayInList = (props) => {
  const { addToast } = PosTostMessage();
  const stateData = useLocation(); //get backraking data as param
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [payInDeactiveData, setPayInDeactiveData] = useState([]);
  const [deactiveTotalCount, setDetactiveTotaCount] = useState(0);
  const [buttonDisables, setButtonDisables] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isDeactiveMobile] = useMediaQuery("(max-width: 1361px)");
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [selectedLoactions, setSelectedLoactions] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? JSON.parse(stateData.state.location)
      : {}
  );
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [locations, setLocations] = useState([]);
  const [toDateFocus, setToDateFocus] = useState(false);

  const [errors, setErrors] = useState("");
  const [curruntTab, setCurruntTab] = useState(0);
  const tabNames = ["Active", "Inactive"];
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [buttonDisable, setButtonDisable] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);

  const [paginationModel, setPaginationModel] = useState({
    page:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });

  const [sortModel, setSortModel] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "created_at",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  const [paginationModelDeactive, setPaginationModelDeactive] = useState({
    page:
      stateData.state && null !== stateData.state && "" !== stateData.state
        ? stateData.state.page
        : 0,
    pageSize:
      undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
        ? stateData.state.limit
        : 25,
  });

  const [sortModelDeactive, setSortModelDeactive] = useState([
    {
      field:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order
          : "amount",
      sort:
        undefined !== stateData.state &&
        null !== stateData.state &&
        "" !== stateData.state
          ? stateData.state.order_type
          : "desc",
    },
  ]);

  // backtracking
  const sortRefDeactive = useRef(sortModelDeactive);
  const paginationRef = useRef(paginationModel);
  const searchInputHideRef = useRef(false);
  const deactiveSearchHideRef = useRef(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    id: false,
  });

  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
    }
  };
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 1.5 };
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "pos_station_id",
      headerName: "Pos Station",
      sortable: true,
      ...actionColumn,
    },
    { field: "user_id", headerName: "User", sortable: true, ...actionColumn },
    {
      field: "amount",
      headerName: "Amount($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "created_at",
      headerName: "Pay In Date",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "location_id",
      headerName: "Location",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "reason",
      headerName: "Reason",
      sortable: true,
      ...actionColumnFlexDouble,
      renderCell: (params) => (
        <Box style={{ wordWrap: "break-word" }}>{params.value}</Box>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      resizable: false,
      disableExport: true,
      width: 110,
      renderCell: (params) => {
        const rowData = params.row;
        const handleEditPage = (item) => {
          let data = {
            location: JSON.stringify(selectedLoactions),
            dateFrom: formik.values.fromDate,
            dateTo: formik.values.toDate,
            limit: paginationModel.pageSize,
            page: paginationModel.page,
            filter: searchQuery,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
          };
          myContext.handleLoading(true);
          if (
            (data.location && data.dateFrom && data.dateTo) ||
            data.filter ||
            data.limit
          ) {
            // If all are present, navigate to the stateis page
            props.navigate(`${Constants.PAY_IN_EDIT_ROUTE + item.id}`, {
              state: data,
            });
          } else {
            // If any are missing, navigate to the create page
            props.navigate(`${Constants.PAY_IN_EDIT_ROUTE + item.id}`);
          }
        };

        const handleDeactive = (item) => {
          setButtonDisables(true);
          if (modifiedData.length == 1 && paginationModel.page != 0) {
            paginationModel.page = paginationModel.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: item.id,
            type: Constants.DEACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.PAY_IN_REPORT_ROUTE,
          });
        };

        const options = {
          buttonName: "Edit",
          buttonIcon: "",
          handleClick: (item) => handleEditPage(item),
          subButtons: [
            {
              name: "Deactive",
              disabledButton: buttonDisables,
              icon: (
                <CloseIcon style={{ color: Colors.posCancleButtonMuiRed }} />
              ),
              onClick: (item) => handleDeactive(item),
            },
          ],
        };
        return (
          <PosSpliteButton
            spliteOptions={options}
            item={rowData}
            id={rowData.id}
          />
        );
      },
    },
  ];

  const columnNamesDeactive = [
    { field: "id", headerName: "Id", sortable: false, width: 90 },
    {
      field: "pos_station_id",
      headerName: "Pos Station",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    { field: "user_id", headerName: "User", sortable: true, ...actionColumn },
    {
      field: "amount",
      headerName: "Amount($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "reason",
      headerName: "Reason",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "reactive",
      headerName: "Reactive",
      disableExport: true,
      resizable: false,
      filterable: false,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const rowData = params.row;
        const handleDeactive = () => {
          setButtonDisables(true);
          if (
            deactivePayInData.length == 1 &&
            paginationModelDeactive.page != 0
          ) {
            paginationModelDeactive.page = paginationModelDeactive.page - 1;
          }
          myContext.handleLoading(true);
          let data = {
            id: rowData.id,
            type: Constants.REACTIVE,
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.PAY_IN_REPORT_ROUTE,
          });
        };
        return (
          <Text
            onClick={handleDeactive}
            cursor={buttonDisables ? "not-allowed" : "pointer"}
            color={buttonDisables ? Colors.posNavbarLink : Colors.posNavbarLink}
            fontSize="1rem"
            letterSpacing="-1.5%"
            textDecoration={buttonDisables ? "none" : "underline"}
            fontWeight={600}
            style={{ pointerEvents: buttonDisables ? "none" : "auto" }} // Ensures that click is disabled
          >
            Activate
          </Text>
        );
      },
    },
  ];

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLoactions(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
    }
  };

  const [loading, setLoading] = useState(true);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (undefined !== stateData.state &&
            null !== stateData.state &&
            "" !== stateData.state) {
            const locationString = stateData.state.location;
            const locationObject = JSON.parse(locationString);
            formik.setFieldValue("selectedLoactions", locationObject.name);
          }
          if(undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1){
              setSelectedLoactions(loaderResponse?.data?.data?.location[0])
              formik.setFieldValue("selectedLoactions", loaderResponse?.data?.data?.location[0].name);
              setOlColumnVisibilityModel(prev => ({...prev, location_id: false }));
            }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  const apply = () => {
    formik.setTouched(
      {
        selectedLoactions: true,
        fromDate: true,
        toDate: true,
      },
      true
    );
    if(selectedLoactions?.length === 0 ||
      formik.values.fromDate === "" ||
      formik.values.toDate === "" ||
      formik.errors.selectedLoactions ||
      formik.errors.fromDate ||
      formik.errors.toDate){
      return;
    }
    effectRun.current = true;
    setLoading(true);
    setApplyClicked(true);
    // fetchData();
  };

  const [payInData, setPayInData] = useState([]);
  const fetchData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModel.pageSize,
      order:
        sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
      order_type:
        sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
      page: paginationModel.page + 1,
      filter: searchQuery,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    // if (selectedLoactions) {
    //   data.location = {
    //     label: formik.values.selectedLoactions.name,
    //     value: {
    //       id: formik.values.selectedLoactions.id,
    //       name: formik.values.selectedLoactions.name,
    //     },

    //   };
    //   setApplyClicked(true);
    // } else {
    //   setApplyClicked(false);

    //   data.location = "";
    // }

    // if (formik.values.fromDate) {
    //   data.dateFrom = moment(formik.values.fromDate)
    //     .format("MM/DD/YYYY")
    //     .toString();
    //   } else {
    //     data.dateFrom = ""; // Set to empty string if fromDate is not selected
    //     setApplyClicked(false);

    //   }

    // if (formik.values.toDate) {
    //   data.dateTo = moment(formik.values.toDate)
    //     .format("MM/DD/YYYY")
    //     .toString();
    //   } else {
    //     data.dateTo = ""; // Set to empty string if toDate is not selected
    //     setApplyClicked(false);

    //   }
    if (Object.keys(selectedLoactions).length > 0 && formik.values.fromDate && formik.values.toDate) {
      data.location = {
        label: selectedLoactions.name,
        value: {
          id: selectedLoactions.id,
          name: selectedLoactions.name,
        },
      };
      data.dateFrom = moment(formik.values.fromDate)
        .format("MM/DD/YYYY")
        .toString();
      data.dateTo = moment(formik.values.toDate)
        .format("MM/DD/YYYY")
        .toString();
      setApplyClicked(true);
    } else {
      setApplyClicked(false);
      data.location = "";
      data.dateFrom = "";
      data.dateTo = ""; 
    }
    setShowTable(false);
    try {
      getPayInList(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setPayInData(response.data.data.data);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setPayInData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if ( applyClicked === true){
            searchInputHideRef.current = false;
          }else if(!searchInputHideRef.current && err?.response?.data?.data === 0) {
            searchInputHideRef.current = true;
          }
          setPayInData([]);
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setPayInData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const fetchDeActivePayInData = async () => {
    setLoading(true);
    let data = {
      limit: paginationModelDeactive.pageSize,
      page: paginationModelDeactive.page + 1,
      order:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].field
          : sortRefDeactive.current[0].field,
      order_type:
        sortModelDeactive.length > 0
          ? sortModelDeactive[0].sort
          : sortRefDeactive.current[0].sort,
      filter: searchQuery,
    };
    try {
      getDectivePayInList(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setPayInDeactiveData(response.data.data.data);
            setDetactiveTotaCount(response.data.data.totalItems);
            setLoading(false);
            myContext.handleLoading(false);
          } else {
            setPayInDeactiveData([]);
            setDetactiveTotaCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          if (
            !deactiveSearchHideRef.current &&
            err?.response?.data?.data === 0
          ) {
            deactiveSearchHideRef.current = true;
          }
          setPayInDeactiveData([]);
          setDetactiveTotaCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setPayInDeactiveData([]);
      setDetactiveTotaCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const handleSearchList = () => {
    // search time get active and deactive list
    paginationModel.page = 0;
    props.navigate(Constants.PAY_IN_REPORT_ROUTE);
    if (curruntTab == 0) {
      fetchData();
    } else {
      fetchDeActivePayInData();
    }
  };
  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    props.navigate(Constants.PAY_IN_REPORT_ROUTE);
  };
  const formik = useFormik({
    initialValues: {
      selectedLoactions:
        undefined !== stateData?.state?.location
          ? stateData?.state?.location?.name
          : "",
      fromDate:
        undefined !== stateData?.state?.dateFrom
          ? stateData?.state?.dateFrom
          : "",
      toDate:
        undefined !== stateData?.state?.dateTo ? stateData?.state?.dateTo : "",
    },
    validationSchema: Yup.object({
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {},
  });

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
        setButtonDisables(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (actionResponse.data.message.trim() === Constants.DEACTIVE_SUCCESS) {
          setLoading(true);
          if (modifiedData.length === 1 && paginationModel?.page !== 0) {
            effectRun.current = true;
            setPaginationModel((prevPaginationMod) => {
              return {
                page: prevPaginationMod?.page - 1,
                pageSize: prevPaginationMod?.pageSize,
              };
            });
          } else {
            fetchData();
          }
          setTableData(actionResponse.data.data.data);
          setTotalCount(actionResponse.data.data.totalItems);
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.PAYIN_TITLE,
            alertDescription: Constants.PAY_IN_DEACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisables(false);
        } else {
          setLoading(true);
          if (deactivePayInData.length === 1 && deactivePayInData?.page !== 0) {
            effectRun.current = true;
            setPaginationModel((prevPaginationMod) => {
              return {
                page: prevPaginationMod?.page - 1,
                pageSize: prevPaginationMod?.pageSize,
              };
            });
          } else {
            fetchDeActivePayInData();
          }
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.PAYIN_TITLE,
            alertDescription: Constants.PAY_IN_REACTIVE_SUCCESS,
          });
          myContext.handleLoading(false);
          setButtonDisables(false);
        }
      }
    }
  }, [actionResponse]);

  const handleTabChange = (index) => {
    setCurruntTab(index);
    if (index == 0) {
      deactiveSearchHideRef.current = false;
      setSearchQuery("");
      // clearDropDown();
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      setSortModel([
        {
          field: "created_at",
          sort: "desc",
        },
      ]);
      effectRun.current = true;
      setLoading(true);
      if(undefined !== loaderResponse?.data?.data?.location &&
        null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length > 1){
        setSelectedLoactions([]);
        formik.resetForm({
          values: {
            selectedLoactions: "",
            fromDate: "", 
            toDate: "", 
          },
        });
      }
      formik.resetForm({
        values: {
          selectedLoactions: "",
          fromDate: "", 
          toDate: "", 
        },
      });
      window.history.replaceState({}, '');
    } else {
      searchInputHideRef.current = false;
      setSearchQuery("");
      setPaginationModelDeactive({
        page: 0,
        pageSize: 25,
      });
      setSortModelDeactive([
        {
          field: "amount",
          sort: "desc",
        },
      ]);
      // setSelectedLoactions([]);
      effectRun.current = true;
      setLoading(true);
      window.history.replaceState({}, '');
    }
  };

  useEffect(() => {
    // page change, rowperPage change,tab change ,serchdata call
    if (effectRun.current === true) {
      if (curruntTab === 0) {
        fetchData();
      } else {
        fetchDeActivePayInData();
      }
      effectRun.current = false;
    }
  }, [
    paginationModel,
    sortModel,
    loading,
    searchQuery,
    paginationModelDeactive,
    sortModelDeactive,
    curruntTab,
  ]);

  // useEffect(() => {
  //   if (curruntTab === 0) {
  //     fetchData();
  //   } else {
  //     fetchDeActivePayInData();
  //   }
  // }, [paginationModel, sortModel]);

  const clearDropDown = () => {
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    effectRun.current = true;
    // formik.resetForm();
    setLoading(true);
    formik.resetForm({
      values: {
        selectedLoactions: "",
        fromDate: "",
        toDate: "",
      },
    });
    window.history.replaceState({}, '');
    setSelectedLoactions({});
    setApplyClicked(false);
  };

  function reportDateFormates(dateString) {
    if (!dateString) {
      return "";
    }
    const date = moment(dateString);
    const formattedDate = date.format("MM/DD/YYYY HH:mm:ss");
    return formattedDate;
  }
  const modifiedData = payInData.map((data) => ({
    id: data.id,
    user_id: data.users.name,
    pos_station_id: data.station.name,
    amount: data.amount,
    created_at: commonDateFormate(data.created_at),
    location_id: data.station.location.name,
    reason: data.reason,
  }));

  const deactivePayInData = payInDeactiveData.map((data) => ({
    id: data.id,
    user_id: data.users.name,
    pos_station_id: data.station.name,
    amount: data.amount,
    reason: data.reason,
  }));
  const renderReportLayout = () => {
    return (
      <>
        <Box className="card justify-content-center">
          <PosDropDown
          id="selectedLoactions"
            options={locations}
            value={selectedLoactions}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLoactions");
              formik.handleBlur(e);
            }}
            resetFilterOnHide={true}
            multiSelect={false}
            optionLabel="name"
            placeholder="Select Location*"
            width={
              isExtraLarge
                ? "20rem"
                : isLarge
                ? "17rem"
                : isMeduim1
                ? "13.32rem"
                : "100%"
            }
            height={"2.5rem"}
            className="w-full md:w-20rem"
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLoactions &&
            formik.errors.selectedLoactions ? (
              <span>{formik.errors.selectedLoactions}</span>
            ) : null}
          </Text>
        </Box>
        <Stack
          className="card justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          mt={isMeduim ? 4 : 0}
        >
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              value={formik.values.fromDate}
              label={"From Date*"}
              readOnlyInput={true}
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={false}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={"100% !important"}>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              value={formik.values.toDate}
              label={"To Date*"}
              readOnlyInput={true}
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : dateTimeValue
              }
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={false}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Pay In"]}
          breadCrumTitle={"Pay In"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosIconButton
            name="Create"
            display={"flex"}
            width={"8.5rem"}
            height={"2.81rem"}
            padding={"0.63rem"}
            justify-content={"center"}
            align-items={"center"}
            gap={"0.31rem"}
            flex-shrink={"0"}
            onClick={() => {
              myContext.handleLoading(true);
              // let selectedLocationName = formik.values.selectedLoactions?.name || "";
              let data = {
                location: JSON.stringify(selectedLoactions),
                dateFrom: formik.values.fromDate,
                dateTo: formik.values.toDate,
                limit: paginationModel.pageSize,
                page: paginationModel.page,
                filter: curruntTab == 1 ? "" : searchQuery,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
              };
              if (
                (data.location && data.dateFrom && data.dateTo) ||
                data.filter ||
                data.limit
              ) {
                // If all are present, navigate to the stateis page
                props.navigate(Constants.PAY_IN_CREATE_ROUTE, { state: data });
              } else {
                // If any are missing, navigate to the create page
                props.navigate(Constants.PAY_IN_CREATE_ROUTE);
              }
            }}
          />
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Tabs onChange={(index) => handleTabChange(index)}>
          <PosTab tabNames={tabNames} />
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
              <Spacer />
            </Flex>
          ) : curruntTab === 1 && !deactiveSearchHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mt={6}
              mb={3}
              ml={4}
              mr={4}
            >
              <PosSearch
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
              <Spacer />
            </Flex>
          ) : null}
          {curruntTab == 0 && !searchInputHideRef.current ? (
            <>
              <Flex
                direction={isMeduim ? "column" : "row"}
                justifyContent={"space-between"}
              >
                <SimpleReportLayout>{renderReportLayout()}</SimpleReportLayout>
                <Stack
                  direction={"row"}
                  justifyContent={isMeduim ? "flex-start" : "flex-end"}
                  alignItems={"flex-start"}
                  pl={isMeduim ? 4 : 0}
                  pt={isMeduim ? 0 : 4}
                >
                  <PosFormButton
                    isDisabled={applyClicked ? false : true}
                    onClick={() => {
                      clearDropDown();
                    }}
                    buttonText={"Reset"}
                    ghostButton={true}
                  />
                  <PosFormButton
                    isDisabled={buttonDisable}
                    // isDisabled={
                    //   selectedLoactions?.length === 0 ||
                    //   formik.values.fromDate === "" ||
                    //   formik.values.toDate === "" ||
                    //   formik.errors.selectedLoactions ||
                    //   formik.errors.fromDate ||
                    //   formik.errors.toDate ||
                    //   buttonDisable
                    //     ? true
                    //     : false
                    // }
                    buttonsubmit={"Apply"}
                    SubmitButton={true}
                    onClick={() => {
                      apply();
                    }}
                  />
                </Stack>
              </Flex>
            </>
          ) : null}
          <TabPanels>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : totalcount > 0 && curruntTab === 0 ? (
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNames}
                    rows={modifiedData}
                    totalcount={totalcount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModel}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModel) => {
                      effectRun.current = true;
                      setPaginationModel(newPaginationModel);
                    }}
                    sortModel={sortModel}
                    onSortModelChange={(newSortModel) => {
                      effectRun.current = true;
                      sortRef.current = sortModel;
                      setSortModel(newSortModel);
                    }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.ACTIVE_PAY_IN_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.PAY_IN_NOT_FOUND} />
              )}
            </TabPanel>
            <TabPanel>
              {showTable ? null : loading ? (
                <PosProgress />
              ) : deactiveTotalCount > 0 && curruntTab === 1 ? (
                <Flex direction={"column"} gap={4}>
                  <PosDataGridTable
                    columns={columnNamesDeactive}
                    rows={deactivePayInData}
                    totalcount={deactiveTotalCount}
                    columnVisibilityModel={olColumnVisibilityModel}
                    onColumnVisibilityModelChange={(newModel) =>
                      setOlColumnVisibilityModel(newModel)
                    }
                    paginationModel={paginationModelDeactive}
                    paginationMode="server"
                    sortingMode="server"
                    onPaginationModelChange={(newPaginationModelDeactive) => {
                      effectRun.current = true;
                      setPaginationModelDeactive(newPaginationModelDeactive);
                    }}
                    sortModel={sortModelDeactive}
                    onSortModelChange={(newSortModelDeactive) => {
                      effectRun.current = true;
                      sortRef.current = sortModelDeactive;
                      setSortModelDeactive(newSortModelDeactive);
                    }}
                    // onSortModelChange={(newSortModel) => {
                    //   effectRun.current = true
                    //   sortRef.current = sortModel;
                    //   setSortModel(newSortModel);
                    // }}
                    slots={{
                      toolbar: GridToolbar,
                    }}
                    fileName={Constants.DEACTIVE_PAY_IN_EXPORT_FILENAME}
                  />
                </Flex>
              ) : (
                <PosNoDataFound title={Constants.DEACTIVE_PAY_IN_NOT_FOUND} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
export default WithRouter(PayInList);
