import { Box, Divider, Skeleton, Stack } from "@chakra-ui/react";
import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import * as Constants from "../../constants/Constants";
import { searchCommandGet } from "./SearchCommandService";
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./ui/command";

export const SearchCommand = ({ navigate }) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [expandedProducts, setExpandedProducts] = useState(new Set());
  const [isMac, setIsMac] = useState(
    navigator.platform.toUpperCase().indexOf("MAC") >= 0
  );
  const [products, setProducts] = useState([]);
  const [rewardCards, setRewardCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const processing = useRef(false);
  // useEffect(() => {
  //   setIsMac(navigator.platform.toUpperCase().indexOf("MAC") >= 0);
  // }, []);

  useEffect(() => {
    const down = (e) => {
      if ((e.key === "k" || e.key === "K") && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        processing.current = true;
        // setLoading(true);
        // Clear previous search results and search input
        setSearch("");
        setExpandedProducts(new Set());
        setProducts([]);
        setRewardCards([]);
        setOpen((open) => !open);

        let payload = {
          filter: "",
          limit: Constants.SEARCH_COMMAND_DATA_FETCH_LIMIT,
        };
        searchCommandGet(payload)
          .then((response) => {
            if (response.data && response.data.data) {
              setProducts(response.data.data.products);
              setRewardCards(response.data.data.rewardCards);
              // setLoading(false);
              processing.current = false;
            }
          })
          .catch((error) => {
            setLoading(false);
            setSearch("");
            setExpandedProducts(new Set());
            setProducts([]);
            setRewardCards([]);
            processing.current = false;
          });
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  const toggleProduct = (barcode) => {
    setExpandedProducts((prev) => {
      const next = new Set(prev);
      if (next.has(barcode)) {
        next.delete(barcode);
      } else {
        next.add(barcode);
      }
      return next;
    });
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(true);
      processing.current = true;
      if (search) {
        const payload = {
          filter: search,
          limit: Constants.SEARCH_COMMAND_DATA_FETCH_LIMIT,
        };
        searchCommandGet(payload)
          .then((response) => {
            if (response.data && response.data.data) {
              setProducts(response.data.data.products);
              setRewardCards(response.data.data.rewardCards);
            }
            setLoading(false);
            processing.current = false;
          })
          .catch((error) => {
            setLoading(false);
            setSearch("");
            setExpandedProducts(new Set());
            setProducts([]);
            setRewardCards([]);
            processing.current = false;
          });
      } else {
        // search is empty
        if (open) {
          let payload = {
            filter: "",
            limit: Constants.SEARCH_COMMAND_DATA_FETCH_LIMIT,
          };
          searchCommandGet(payload)
            .then((response) => {
              if (response.data && response.data.data) {
                setProducts(response.data.data.products);
                setRewardCards(response.data.data.rewardCards);
              }
              setLoading(false);
              processing.current = false;
            })
            .catch((error) => {
              setLoading(false);
              setSearch("");
              setExpandedProducts(new Set());
              setProducts([]);
              setRewardCards([]);
              processing.current = false;
            });
        } else {
          setLoading(false);
          processing.current = false;
        }
      }
    }, 250);

    // Cleanup timeout on each search change
    return () => clearTimeout(timeoutId);
  }, [search]);

  const handleDialogClose = (isOpen) => {
    if (!isOpen) {
      // First reset the search and loading states
      setSearch("");
      setLoading(false);
      // Use a small delay to ensure the UI updates smoothly
      setTimeout(() => {
        setExpandedProducts(new Set());
        setProducts([]);
        setRewardCards([]);
      }, 100);
    }
    setOpen(isOpen);
  };

  const handleSelectProduct = (product) => {
    setOpen(false);
    if (product?.id) {
      // Assuming these are the props needed for the ProductEditDrawer
      if (typeof navigate.openProductEditDrawer === "function") {
        // Navigate to product list and open drawer
        navigate.openProductEditDrawer({
          id: product.id,
          editFlag: true,
          onEditOpen: () => {
            // console.log('onEditOpen');
          },
        });
        setSearch("");
      }
    } else {
      // navigate(Constants.PRODUCT_LIST_PATH);
    }
  };

  const handleSelectRewardCard = (card) => {
    setOpen(false);
    if (card?.id) {
      if (window.location.hash.includes(Constants.REWARD_CARDS_EDIT)) {
        window.location.hash =
          Constants.REWARD_CARDS_EDIT_WITH_ID + `${card?.id}`;
        window.location.reload();
      } else {
        window.location.hash =
          Constants.REWARD_CARDS_EDIT_WITH_ID + `${card?.id}`;
      }
    } else {
      window.location.hash = Constants.REWARD_CARDS_EDIT;
    }
  };

  return (
    <div>
      <CommandDialog
        open={open}
        onOpenChange={handleDialogClose}
        className="z-50"
      >
        <Command
          className="rounded-lg border-2 border-gray-200 shadow-lg bg-white"
          shouldFilter={false}
        >
          <div className="border-b border-gray-200">
            <CommandInput
              placeholder="Search products and reward cards..."
              value={search}
              onValueChange={setSearch}
              className="border-none focus:ring-0"
            />
          </div>
          {loading ? (
            <CommandList>
              {/* <CommandEmpty>
                <Spinner />
              </CommandEmpty> */}
              <CommandGroup className="min-h-[500px] overflow-y-auto">
                <CommandItem>
                  <Box marginTop="15px">
                    <Stack>
                      <Skeleton
                        height="14px"
                        width="450px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                      <Skeleton
                        height="20px"
                        width="400px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                      <Skeleton
                        height="12px"
                        width="430px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                    </Stack>
                  </Box>
                </CommandItem>
                <Divider />
                <CommandItem>
                  <Box>
                    <Stack>
                      <Skeleton
                        height="14px"
                        width="450px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                      <Skeleton
                        height="20px"
                        width="400px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                      <Skeleton
                        height="12px"
                        width="430px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                    </Stack>
                  </Box>
                </CommandItem>
                <Divider />
                <CommandItem>
                  <Box>
                    <Stack>
                      <Skeleton
                        height="14px"
                        width="450px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                      <Skeleton
                        height="20px"
                        width="400px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                      <Skeleton
                        height="12px"
                        width="430px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                    </Stack>
                  </Box>
                </CommandItem>
                <Divider />
                <CommandItem>
                  <Box>
                    <Stack>
                      <Skeleton
                        height="14px"
                        width="450px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                      <Skeleton
                        height="20px"
                        width="400px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                      <Skeleton
                        height="12px"
                        width="430px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                    </Stack>
                  </Box>
                </CommandItem>
                <Divider />
                <CommandItem>
                  <Box>
                    <Stack>
                      <Skeleton
                        height="14px"
                        width="450px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                      <Skeleton
                        height="20px"
                        width="400px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                      <Skeleton
                        height="12px"
                        width="430px"
                        startColor="#e5e7df99"
                        endColor="#c0c2bb99"
                      />
                    </Stack>
                  </Box>
                </CommandItem>
              </CommandGroup>
            </CommandList>
          ) : (
            <CommandList>
              {processing.current ? (
                //   <CommandEmpty>
                //     <Spinner />
                // </CommandEmpty>
                <CommandGroup className="min-h-[500px] overflow-y-auto">
                  <CommandItem>
                    <Box marginTop="15px">
                      <Stack>
                        <Skeleton
                          height="14px"
                          width="450px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                        <Skeleton
                          height="20px"
                          width="400px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                        <Skeleton
                          height="12px"
                          width="430px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                      </Stack>
                    </Box>
                  </CommandItem>
                  <Divider />
                  <CommandItem>
                    <Box>
                      <Stack>
                        <Skeleton
                          height="14px"
                          width="450px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                        <Skeleton
                          height="20px"
                          width="400px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                        <Skeleton
                          height="12px"
                          width="430px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                      </Stack>
                    </Box>
                  </CommandItem>
                  <Divider />
                  <CommandItem>
                    <Box>
                      <Stack>
                        <Skeleton
                          height="14px"
                          width="450px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                        <Skeleton
                          height="20px"
                          width="400px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                        <Skeleton
                          height="12px"
                          width="430px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                      </Stack>
                    </Box>
                  </CommandItem>
                  <Divider />
                  <CommandItem>
                    <Box>
                      <Stack>
                        <Skeleton
                          height="14px"
                          width="450px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                        <Skeleton
                          height="20px"
                          width="400px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                        <Skeleton
                          height="12px"
                          width="430px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                      </Stack>
                    </Box>
                  </CommandItem>
                  <Divider />
                  <CommandItem>
                    <Box>
                      <Stack>
                        <Skeleton
                          height="14px"
                          width="450px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                        <Skeleton
                          height="20px"
                          width="400px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                        <Skeleton
                          height="12px"
                          width="430px"
                          startColor="#e5e7df99"
                          endColor="#c0c2bb99"
                        />
                      </Stack>
                    </Box>
                  </CommandItem>
                </CommandGroup>
              ) : (
                <CommandEmpty className="h-32 flex items-center justify-center">
                  No results found.
                </CommandEmpty>
              )}
              {products?.length > 0 && (
                <CommandGroup heading="Products">
                  {products?.map((product, index) => (
                    <div key={index} className="px-2">
                      <CommandItem
                        key={index}
                        className="flex items-center justify-between cursor-pointer hover:bg-gray-50"
                        onSelect={() =>
                          product?.locations?.length > 1
                            ? toggleProduct(product?.barcode)
                            : handleSelectProduct(product?.locations[0])
                        }
                      >
                        <div>
                          <div className="font-medium break-all">
                            {product?.name}
                          </div>
                          {product?.locations?.length <= 1 ? (
                            <div className="text-sm text-gray-500 break-all">
                              {product?.locations[0]?.locationName}, Barcode:{" "}
                              {product?.barcode}
                            </div>
                          ) : (
                            <div className="text-sm text-gray-500 break-all">
                              Barcode: {product?.barcode}
                            </div>
                          )}
                        </div>
                        {product?.locations?.length > 1 ? (
                          expandedProducts.has(product?.barcode) ? (
                            <ChevronUp className="h-4 w-4" />
                          ) : (
                            <ChevronDown className="h-4 w-4" />
                          )
                        ) : (
                          <span className="font-medium">
                            ${product?.locations[0]?.price?.toFixed(2)}
                          </span>
                        )}
                      </CommandItem>
                      {product?.locations?.length > 1 &&
                        expandedProducts.has(product?.barcode) && (
                          <div className="ml-4 pl-4 border-l border-gray-200">
                            {product?.locations?.map(
                              (location, locationIndex) => (
                                <CommandItem
                                  onSelect={() => handleSelectProduct(location)}
                                  key={`${product?.barcode}-${location?.id}-${locationIndex}`}
                                  value={`${product?.barcode}-${location?.id}-${locationIndex}`}
                                  className="flex items-center justify-between py-2"
                                >
                                  <span>{location?.locationName}</span>
                                  <span className="font-medium">
                                    ${location?.price?.toFixed(2)}
                                  </span>
                                </CommandItem>
                              )
                            )}
                          </div>
                        )}
                    </div>
                  ))}
                </CommandGroup>
              )}
              {rewardCards?.length > 0 && (
                <CommandGroup heading="Reward Cards">
                  {rewardCards?.map((card) => (
                    <CommandItem
                      key={card.id}
                      className="flex flex-col items-start"
                      onSelect={() => handleSelectRewardCard(card)}
                    >
                      <div className="font-medium break-all">
                        {card.first_name || card.last_name
                          ? `${card.first_name || ""} ${card.last_name ||
                              ""}`.trim()
                          : "N/A"}
                      </div>
                      <div className="text-sm text-gray-500 break-all">
                        {card.phone || "N/A"} • {card.email || "N/A"}
                      </div>
                    </CommandItem>
                  ))}
                </CommandGroup>
              )}
            </CommandList>
          )}
        </Command>
      </CommandDialog>
    </div>
  );
};

export default SearchCommand;
