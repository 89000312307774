import {
  Box,
  Flex,
  Divider,
  Spacer,
  Stack,
  Text,
  Image,
  Select,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useParams,
  useSubmit,
  useLocation,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosCountryStateDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosTostMessage,
  PosDropDown,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { getStateListForSelectedCountry } from "./VendorService";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";

const VendorEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const myContext = useContext(AppContext);
  const { id } = useParams();
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  const [countries, setCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [stateAvailable, setStatesAvailability] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const stateData = useLocation();
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [countryError, setCountryError] = useState("");
  const [stateError, setStateError] = useState("");
  const [allTouchedField, setAllTouchedField] = useState(false);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          const arrayCountries = Object.keys(
            loaderResponse?.data?.data?.countries
          )?.map((key) => ({
            label: loaderResponse?.data?.data?.countries[key],
            value: key,
          }));
          setCountry(arrayCountries);

          if (
            loaderResponse?.data?.data?.vendor?.country &&
            null != loaderResponse?.data?.data?.vendor?.country
          ) {
            setSelectedCountry(loaderResponse?.data?.data?.vendor?.country);
            handleSelectedCountry(loaderResponse?.data?.data?.vendor?.country);
          }

          if (
            loaderResponse?.data?.data?.vendor?.state &&
            null != loaderResponse?.data?.data?.vendor?.state &&
            "N.A." != loaderResponse?.data?.data?.vendor?.state
          ) {
            setStatesAvailability(0);
            setSelectedState(loaderResponse?.data?.data?.vendor?.state);
          }
        }
      }
      effectRun.current = false;
    }
    // if (actionRun.current === true) {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.VENDOR_LIST, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.VENDOR_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
      // else {
      //   let actionData = actionResponse;
      //   myContext.handleLoading(false);
      //   error({ actionData });
      // }
    }
    // return () => {
    //   actionRun.current = false;
    // };
    // }
  }, [loaderResponse, actionResponse]);

  const handleSelectedState = (value) => {
    setSelectedState(value);
    if (value == "N.A.") {
      setStatesAvailability(1);
    } else {
      setStatesAvailability(0);
    }
  };

  const handleSelectedCountry = (value) => {
    try {
      setSelectedCountry(value);
      setStates("");
      setSelectedState("");
      setStateError("");
      // formik.setFieldValue("selectedState", null);
      myContext.handleLoading(true);
      getStateListForSelectedCountry(value)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            const statesData = response.data.data.states;
            const arrayStates = Object.keys(statesData).map((key) => ({
              label: statesData[key],
              value: key,
            }));
            setStates(arrayStates);
            const customerState =
              loaderResponse?.data?.data?.vendor?.state;
            if (
              customerState &&
              customerState !== "N.A" &&
              customerState !== null &&
              statesData[customerState]
            ) {
              setSelectedState(customerState);
              // formik.setFieldValue("selectedState", customerState);
              setStatesAvailability(0);
            } else {
              // setSelectedState("N.A");
              // formik.setFieldValue("selectedState", "N.A");
              setStatesAvailability(1);
            }
          } else {
            setStates([]);
            setSelectedState("N.A");
            // formik.setFieldValue("selectedState", "N.A");
            setStatesAvailability(1);
          }
          myContext.handleLoading(false);
        })
        .catch((err) => {
          setStates([]);
          setSelectedState("N.A");
          // formik.setFieldValue("selectedState", "N.A");
          setStatesAvailability(1);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setStates([]);
      setSelectedState("N.A");
      // formik.setFieldValue("selectedState", "N.A");
      setStatesAvailability(1);
      let actionData = error;
      error({ actionData });
      myContext.handleLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name:
        undefined != loaderResponse?.data?.data?.vendor?.name &&
        null != loaderResponse?.data?.data?.vendor?.name
          ? loaderResponse?.data?.data?.vendor?.name
          : "",
      email:
        undefined != loaderResponse?.data?.data?.vendor?.email &&
        null != loaderResponse?.data?.data?.vendor?.email
          ? loaderResponse?.data?.data?.vendor?.email
          : "",
      address:
        undefined != loaderResponse?.data?.data?.vendor?.address &&
        null != loaderResponse?.data?.data?.vendor?.address
          ? loaderResponse?.data?.data?.vendor?.address
          : "",
      contact_person:
        undefined !== loaderResponse?.data?.data?.vendor?.contact_person &&
        null !== loaderResponse?.data?.data?.vendor?.contact_person
          ? loaderResponse?.data?.data?.vendor?.contact_person
          : "",
      // country:
      //   undefined !== loaderResponse?.data?.data?.vendor?.country &&
      //   null != loaderResponse?.data?.data?.vendor?.country
      //     ? loaderResponse?.data?.data?.vendor?.country
      //     : "",
      // state:
      //   undefined !== loaderResponse?.data?.data?.vendor?.state &&
      //   null != loaderResponse?.data?.data?.vendor?.state
      //     ? loaderResponse?.data?.data?.vendor?.state
      //     : "",
      selectedState: undefined !== loaderResponse?.data?.data?.vendor?.state &&
        null != loaderResponse?.data?.data?.vendor?.state
          ? loaderResponse?.data?.data?.vendor?.state
          : "",
      selectedCountry: undefined !== loaderResponse?.data?.data?.vendor?.country &&
        null != loaderResponse?.data?.data?.vendor?.country
          ? loaderResponse?.data?.data?.vendor?.country
          : "",
      city:
        undefined !== loaderResponse?.data?.data?.vendor?.city &&
        null !== loaderResponse?.data?.data?.vendor?.city
          ? loaderResponse?.data?.data?.vendor?.city
          : "",
      phone:
        undefined !== loaderResponse?.data?.data?.vendor?.phone &&
        null !== loaderResponse?.data?.data?.vendor?.phone
          ? loaderResponse?.data?.data?.vendor?.phone
          : "",
    },
    validationSchema: Yup.object({
      email: Yup.string().trim()
        .required(Constants.VENDOR_EMAIL_REQUIRED)
        .email(Constants.VENDOR_EMAIL_NOT_VALID)
        .matches(Constants.EMAIL_REGEX, Constants.VENDOR_EMAIL_NOT_VALID)
        .max(191, Constants.VENDOR_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
      name: Yup.string()
        .trim()
        .required(Constants.VENDOR_NAME_REQUIRED)
        .max(191, Constants.VENDOR_NAME_LESS_THEN_OR_EQUAL_255_CHARACTER),
      address: Yup.string()
        .trim()
        .max(5000, Constants.VENDOR_ADDRESS_LESS_THEN_OR_EQUAL_5000_CHARACTER),
      contact_person: Yup.string()
        .trim()
        .max(191, Constants.VENDOR_CONTACT_LESS_THEN_OR_EQUAL_255_CHARACTER),
      city: Yup.string()
        .trim()
        .max(191, Constants.VENDOR_CITY_LESS_THEN_OR_EQUAL_191_CHARACTER),
      phone: Yup.number()
        .integer(Constants.VENDOR_PHONE_NOT_VALID)
        .typeError(Constants.VENDOR_PHONE_NOT_VALID)
        .min(10000, Constants.VENDOR_PHONE_MUST_BE_5_DIGITS)
        .max(99999999999999, Constants.VENDOR_PHONE_LESS_THAN_15_DIGITS),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      myContext.handleLoading(true);
      const payload = {
        ...values,
        country: selectedCountry,
        state: selectedState,
        stateNotAvailable: stateAvailable,
      };
      setButtonDisable(true);
      let path = Constants.VENDOR_LIST + id;
      actionRun.current = true;
      setAllTouchedField(false);
      submit(payload, {
        method: Constants.PUT,
        path: path,
      });
    },
  });
  const handleKeyDown = (event) => {
    // if (
    //   event.key === "Enter" &&
    //   !(
    //     formik.values.name.length <= 0 ||
    //     formik.errors.name ||
    //     formik.errors.email ||
    //     formik.errors.address ||
    //     formik.errors.contact_person ||
    //     formik.errors.city ||
    //     formik.errors.phone ||
    //     buttonDisable
    //   )
    // ) {
    //   formik.handleSubmit();
    // }
    if (event.key === "Enter") {
      if (!formik.isValid) {
        if (!allTouchedField) {
          formik.handleSubmit();
          setAllTouchedField(true);
        }
      } else {
        formik.handleSubmit();
      }
    }
  };
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1">
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={1}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.VENDOR_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Vendors", "Edit Vendor"]}
            breadCrumTitle={"Edit Vendor"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(Constants.VENDOR_LIST, {
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              isDisabled={buttonDisable}
              // isDisabled={
              //   formik.values.name.length <= 0 ||
              //   formik.errors.name ||
              //   formik.errors.email ||
              //   formik.errors.address ||
              //   formik.errors.contact_person ||
              //   (states.length > 0 && selectedState === "N.A") || 
              //   formik.errors.city ||
              //   formik.errors.phone ||
              //   buttonDisable
              //     ? true
              //     : false
              // }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={() => {
                // submit time all validation fired and error massage display.
                if (!formik.isValid) {
                  if (!allTouchedField) {
                    formik.handleSubmit();
                    setAllTouchedField(true);
                  }
                } else {
                  formik.handleSubmit();
                }
              }}
            />
          </Stack>
        </Flex>
        <Box
          onKeyDown={handleKeyDown}
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
        >
          <Text
            fontSize="1.125rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Vendor Details
          </Text>
          <Stack spacing={0} mt="0.31rem">
            <PosLable fontWeight={"500"} requiredLabel={true} name={"Name"} />
            <PosInput
              id="name"
              placeholder={"Enter vendor name"}
              // handleInputChange={formik.handleChange}
              handleInputChange={(e) => {
                const value = e.target.value.replace(/,/g, '');
                formik.setFieldValue("name", value);
                if (e.target.value.includes(',')) {
                  addToast({
                    alertStatus: Constants.TOAST_TYPE_WARNING,
                    alertTitle: Constants.VENDOR_MODULE,
                    alertDescription: Constants.COMMA_NOT_ALLOWED,
                  });
                }
              }}
              inputValue={formik.values.name}
              handleBlur={formik.handleBlur}
              posInput={true}
              inputType={"text"}
              inputError={formik.touched.name && formik.errors.name}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.name && formik.errors.name ? (
                <span>{formik.errors.name}</span>
              ) : null}
            </Text>
          </Stack>
          <Stack spacing={0} mt="0.31rem">
            <PosLable
              fontWeight={"500"}
              requiredLabel={true}
              name={"Email Address"}
              // mt="0.31rem"
            />
            <PosInput
              id="email"
              placeholder={"Enter Email Address"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.email}
              handleBlur={formik.handleBlur}
              posInput={true}
              inputType={"text"}
              inputError={formik.touched.email && formik.errors.email}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.email && formik.errors.email ? (
                <span>{formik.errors.email}</span>
              ) : null}
            </Text>
          </Stack>
          <Stack spacing={0} mt="0.31rem">
            <PosLable
              fontWeight={"500"}
              name={"Address"}
              label={true}
            ></PosLable>
            <PosInput
              posInput={true}
              placeholder="Enter Address"
              id="address"
              inputValue={formik.values.address}
              handleBlur={formik.handleBlur}
              handleInputChange={formik.handleChange}
              inputError={formik.touched.address && formik.errors.address}
            ></PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.address && formik.errors.address ? (
                <span>{formik.errors.address}</span>
              ) : null}
            </Text>
          </Stack>
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
            mt="1.15rem"
            spacing={0}
          >
            <Box w={{ base: "100%", md: "45%" }}>
              <PosLable
                label={true}
                name={"Country"}
                fontWeight={500}
              ></PosLable>
              {/* <PosCountryStateDropDown
                options={countries}
                value={selectedCountry}
                countriesSelect={true}
                onChange={(e) => {
                  handleSelectedCountry(e.target.value);
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedCountry", true);
                }}
                optionLabel="label"
                placeholder="--Select Country--"
                width={"100%"}
                maxWidth={"42rem"}
                height={"2.5rem"}
                resetFilterOnHide={true}
                className="w-full md:w-20rem"
                lableAvailable={true}
                disabled={false}
              /> */}
              <PosCountryStateDropDown
              options={countries}
              value={selectedCountry}
              countriesSelect={true}
              onChange={(e) => {
                if(undefined != e.target.value && null != e.target.value && '' != e.target.value){
                  handleSelectedCountry(e.target.value);
                }
                // formik.setFieldValue("selectedCountry", e.target.value);
              }}
              onBlur={(e) => {
                // formik.setFieldTouched("selectedCountry", true);
                // if (!e.target.value) {
                //   setCountryError(Constants.CUSTOMER_PLEASE_SELECT_A_COUNTRY);
                // } else {
                //   setCountryError("");
                // }
              }}
              optionLabel="label"
              placeholder="--Select Country--"
              width={"100%"}
              maxWidth={"42rem"}
              height={"2.5rem"}
              resetFilterOnHide={true}
              className="w-full md:w-20rem"
              lableAvailable={true}
              disabled={false}
            />
            {/* <Text color={Colors.errorColor}>
              {formik.touched.selectedCountry && countryError.length > 0 ? (
                <span>{countryError}</span>
              ) : null}
            </Text> */}
              <PosLable
                fontWeight={"500"}
                name={"City"}
                label={true}
              ></PosLable>
              <PosInput
                id="city"
                posInput={true}
                placeholder={"Enter City"}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.city}
                handleBlur={formik.handleBlur}
                inputType={"text"}
                inputError={formik.touched.city && formik.errors.city}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.city && formik.errors.city ? (
                  <span>{formik.errors.city}</span>
                ) : null}
              </Text>
              <Text
                color="rgba(1, 0, 72, 0.2)"
                fontWeight="300"
                fontSize="0.75rem"
                lineHeight="1.25rem"
                letterSpacing="-0.09px"
              ></Text>
            </Box>
            {/* <Box w={{ base: "100%", md: "50%" }}>
              <PosLable name={"State"} label={true} fontWeight={500}></PosLable>
              {states.length === 0 ? (
                <Select
                  id="state"
                  value={selectedState}
                  sx={{
                    color: "gray",
                    fontSize: "0.94rem !important",
                    fontWeight: 300,
                    letterSpacing: "-0.01rem !important",
                    backgroundColor: "#5881fe0d",
                    borderRadius: "0.625rem",
                    height: "2.5rem",
                    border: "1px solid #b7b7b71f",
                    "&[disabled]": {
                      backgroundColor: "#5881fe0d !important",
                      opacity: 1,
                    },
                  }}
                  onChange={(e) => {
                    handleSelectedState(e.target.value);
                    formik.handleChange(e);
                  }}
                  isDisabled
                  placeholder={states.length === 0 ? "N.A" : "--Select State--"}
                >
                  <option value="N.A">N.A</option>
                </Select>
              ) : (
                <PosCountryStateDropDown
                  options={states}
                  value={selectedState}
                  onChange={(e) => {
                    handleSelectedState(e.target.value);
                    formik.handleChange(e);
                  }}
                  onBlur={(e) => {
                    formik.setFieldTouched("selectedState", true);
                  }}
                  optionLabel="label"
                  placeholder="--Select State--"
                  width={"100%"}
                  maxWidth={"42rem"}
                  height={"2.5rem"}
                  resetFilterOnHide={true}
                  className="w-full md:w-20rem"
                  lableAvailable={true}
                  disabled={false}
                />
              )}
              <PosLable
                fontWeight={"500"}
                name={"Phone Number"}
                label={true}
              ></PosLable>
              <PosInput
                id="phone"
                posNumberInput={true}
                // posInput={true}
                inputType={"number"}
                placeholder={"Enter Phone Number"}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.phone}
                handleBlur={formik.handleBlur}
                inputError={formik.touched.phone && formik.errors.phone}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.phone && formik.errors.phone ? (
                  <span>{formik.errors.phone}</span>
                ) : null}
              </Text>
            </Box> */}
          <Box w={{ base: "100%", md: "50%" }}>
            <PosLable name={"State"} label={true} fontWeight={500}></PosLable>
            {states.length === 0 ? (
              <Select
                id="state"
                value={selectedState  || "N.A"}
                sx={{
                  color: "gray",
                  fontSize: "0.94rem !important",
                  fontWeight: 300,
                  letterSpacing: "-0.01rem !important",
                  backgroundColor: "#5881fe0d",
                  borderRadius: "0.625rem",
                  height: "2.5rem",
                  border: "1px solid #b7b7b71f",
                  "&[disabled]": {
                    backgroundColor: "#5881fe0d !important",
                    opacity: 1,
                  },
                }}
                // onChange={(e) => {
                //   handleSelectedState(e.target.value);
                //   formik.setFieldValue("selectedState", e.target.value);
                // }}
                isDisabled
                placeholder={states.length > 0 ? "N.A" : "--Select State--"}
              >
                <option value="N.A">N.A</option>
              </Select>
            ) : (
              <PosCountryStateDropDown
                options={states}
                value={selectedState}
                onChange={(e) => {
                  if(undefined != e.target.value && null != e.target.value && '' != e.target.value){
                    handleSelectedState(e.target.value);
                  }
                  // formik.setFieldValue("selectedState", e.target.value);
                }}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedState", true);
                }}
                optionLabel="label"
                placeholder="--Select State--"
                width={"100%"}
                maxWidth={"42rem"}
                height={"2.5rem"}
                resetFilterOnHide={true}
                className="w-full md:w-20rem"
                lableAvailable={true}
                disabled={false}
              />
            )}
            {/* {states.length > 0 && (
              <Text color={Colors.errorColor}>
                {formik.touched.selectedState && stateError.length > 0 ? (
                  <span>{stateError}</span>
                ) : null}
              </Text>
            )} */}
              <PosLable
                fontWeight={"500"}
                name={"Phone Number"}
                label={true}
              ></PosLable>
              <PosInput
                id="phone"
                posNumberInput={true}
                // posInput={true}
                inputType={"number"}
                placeholder={"Enter Phone Number"}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.phone}
                handleBlur={formik.handleBlur}
                inputError={formik.touched.phone && formik.errors.phone}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.phone && formik.errors.phone ? (
                  <span>{formik.errors.phone}</span>
                ) : null}
              </Text>
            </Box>
          </Flex>
          <Divider mt={"2rem"} borderColor={Colors.listShadow} />
          <Stack spacing={0}>
            <Flex flexDirection={"row"}>
              <PosLable
                fontWeight={"500"}
                name={"Contact Person"}
                label={true}
              />
              <Tippy
                content={"Person who represents the Vendor's Company."}
                interactive={true}
                maxWidth={300}
                placement="top"
                animation="fade"
                inertia={true}
                moveTransition="transform 0.2s ease-out"
                theme="tomato"
              >
                <Image src={toolTip} alt="Tooltip" mt="2rem" ml="0.3rem" />
              </Tippy>
            </Flex>
            {/* <Text
              color={Colors.taxCreateExemptEbt}
              style={{
                flexShrink: "0",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "300",
                lineHeight: "normal",
                letterSpacing: "-0.18px",
              }}
            >
              This is vendor representative
            </Text> */}
            <PosInput
              id="contact_person"
              posInput={true}
              placeholder={"Enter Contact Person"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.contact_person}
              handleBlur={formik.handleBlur}
              inputType={"text"}
              inputError={
                formik.touched.contact_person && formik.errors.contact_person
              }
            />
            <Text color={Colors.errorColor}>
              {formik.touched.contact_person && formik.errors.contact_person ? (
                <span>{formik.errors.contact_person}</span>
              ) : null}
            </Text>
          </Stack>
        </Box>
      </Box>
    </Flex>
  );
};

export default WithRouter(VendorEdit);
